import React from 'react'

import {Typography, Avatar} from '@mui/material'
import {Box, Stack} from '@mui/system'

import {LeaseUploadForParcelButton} from 'src/components/copilot/LeaseUploadForParcelButton'
import {ParcelCard} from 'src/components/copilot/ParcelCard'
import {Parcel} from 'src/types/copilot'
import {CustomerLeaseStatus} from 'src/types/copilot/customerLeaseStatus'

interface Props {
  parcel: Parcel
  onLeaseUpload: (leaseStatus: CustomerLeaseStatus) => void
}

export const ClaimedAsLeasePage = ({parcel, onLeaseUpload}: Props) => {
  return (
    <Box px={2} maxWidth={{xs: '100%', md: '375px'}} width="100%">
      <Stack alignItems="center" mt={6}>
        <Typography variant="h4">Defend your space</Typography>
      </Stack>
      <Box mt={2}>
        <ParcelCard parcel={parcel} disableActions />
      </Box>
      <Box mt={3}>
        <Typography variant="h5">Upload your lease to:</Typography>
        <Stack spacing={3} mt={2}>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Avatar sx={{bgcolor: 'black'}}>1</Avatar>
            <Typography variant="body1">
              {
                "Secure a 'community ROFR' with the right to protect your space and match any offer on the platform"
              }
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Avatar sx={{bgcolor: 'black'}}>2</Avatar>
            <Typography variant="body1">
              Get 24/7 personalized, expert advice from copilot including a
              detailed property valuation analysis, lease vs. market rent health
              analysis, and restricted landlord info
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Avatar sx={{bgcolor: 'black'}}>3</Avatar>
            <Typography variant="body1">
              Get moderator status on the property&apos;s feed and get the
              support of your community
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Box py={2}>
        <LeaseUploadForParcelButton onSubmit={onLeaseUpload} parcel={parcel} />
      </Box>
    </Box>
  )
}
