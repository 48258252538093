import * as React from 'react'

import {
  Autocomplete,
  Popper,
  PopperProps,
  SxProps,
  TextField,
} from '@mui/material'

import {SelectOption} from 'src/types'

type Props = {
  id: string
  label: string
  values: Array<SelectOption> | undefined
  options: Array<SelectOption>
  disabled?: boolean
  required?: boolean
  onChange: (inputValue: Array<SelectOption>) => void
  size?: 'medium' | 'small'
  disablePortal?: boolean
  sx?: SxProps
}

export const AutocompleteMultiselectTextField = ({
  id,
  label,
  values,
  options,
  disabled,
  onChange,
  size,
  disablePortal = true,
  required = false,
  sx,
}: Props): JSX.Element => {
  const handleChange = (
    _: React.SyntheticEvent,
    newValue: Array<SelectOption>,
  ) => {
    onChange(newValue)
  }

  return (
    <Autocomplete
      multiple
      id={id}
      disablePortal={disablePortal}
      options={options}
      value={values}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          label={label}
          required={required}
          {...params}
          InputLabelProps={{shrink: true}}
        />
      )}
      onChange={handleChange}
      PopperComponent={FitContentsPopper}
      size={size}
      sx={sx}
    />
  )
}

const FitContentsPopper = function (props: PopperProps) {
  return (
    <Popper
      {...props}
      style={{maxWidth: 'fit-content'}}
      placement="bottom-start"
    />
  )
}
