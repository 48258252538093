import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {Typography, Modal, Box, IconButton, Button} from '@mui/material'
import {grey} from '@mui/material/colors'
import {Stack} from '@mui/system'

import {PlaidButton} from 'src/components/deal/PlaidButton'
import {CalendlyModal} from 'src/components/partners/CalendlyModal'
import {RutterButton} from 'src/components/partners/RutterButton'
import {ProgressBox} from 'src/framework/ui/ProgressBox'
import {
  plaidItemsApiCoPilotAccountPath,
  plaidLinkTokensApiCoPilotAccountPath,
  rutterConnectionsApiCoPilotAccountPath,
} from 'src/generated/routes'
import {useCopilotAccount} from 'src/hooks/request/useCopilotAccount'
import {useToggle} from 'src/hooks/util/useToggle'

interface Props {
  open: boolean
  onClose: () => void
}

export const MakeAnOfferModal = ({open, onClose}: Props) => {
  const {user, refetch: refetchUser, loading: loadingUser} = useCopilotAccount()

  const [calendlyModalOpen, openCalendlyModal, closeCalendlyModal] =
    useToggle(false)

  const [hasScheduledCalendly, setHasScheduledCalendly] = useToggle(false)

  const onConnectionSuccess = React.useCallback(() => {
    refetchUser()
  }, [refetchUser])

  return (
    <Modal open={open} onClose={onClose}>
      <Box height="100%" width="100%" bgcolor="background.paper">
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: grey[500],
            zIndex: 51,
          }}
        >
          <CloseIcon />
        </IconButton>
        <ProgressBox
          loading={loadingUser}
          maxWidth={{xs: '100%', md: '375px'}}
          px={2}
          width="100%"
        >
          <Stack alignItems="center" spacing={4} paddingTop={4}>
            {user && (user.connectedAccounting || user.connectedBanking) ? (
              <>
                <Typography variant="h4">Make an offer</Typography>
                <Typography variant="body1">
                  Thank you for sharing your financial information with us. This
                  connection enables us to create a personalized investment
                  strategy tailored to your business goals. To take the next
                  step, we&apos;d love to offer you a one-on-one session with
                  one of our investment professionals, who will provide
                  dedicated support and guide you through the offer process.
                </Typography>
                <Typography variant="body1">
                  Please use the button below to schedule a convenient time for
                  your consultation:
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={openCalendlyModal}
                  disabled={hasScheduledCalendly}
                >
                  Book a Time with an Investment Professional
                </Button>
                {hasScheduledCalendly && (
                  <Typography variant="h4">
                    Thanks for scheduling a call
                  </Typography>
                )}
                <Typography variant="body1">
                  We look forward to partnering with you and are here to ensure
                  you feel confident every step of the way toward securing your
                  property!
                </Typography>

                <CalendlyModal
                  analyticsPageLocation="copilotMakeAnOffer"
                  calendlyUrl="https://calendly.com/withco/make-an-offer"
                  disableTestCalendlyUrl
                  open={calendlyModalOpen}
                  onClose={closeCalendlyModal}
                  onScheduledCall={setHasScheduledCalendly}
                  user={user}
                />
              </>
            ) : (
              <>
                <Typography variant="h4">Connect your financials</Typography>
                <Typography variant="body1" paragraph>
                  withco needs access to your accounting data to gain a clear
                  picture of your financial health, allowing us to make a
                  tailored, transparent offer that aligns with your unique
                  business needs. This information helps us assess cash flow,
                  revenue trends, and risk accurately, ensuring we can support
                  your long-term success.
                </Typography>
                <Typography variant="body1">
                  Rest assured, your data is handled securely and
                  confidentially, enabling us to partner with you effectively
                  from the start.
                </Typography>

                <Typography variant="body1">
                  Connect your QuickBooks account, Xero account, or Bank account
                  to make an offer
                </Typography>
                <Stack spacing={2} width="100%">
                  <RutterButton
                    onSuccess={onConnectionSuccess}
                    defaultButtonText={'Connect QuickBooks'}
                    platform="QUICKBOOKS"
                    pageLocation="copilotMakeAnOffer"
                    createRutterConnectionPath={rutterConnectionsApiCoPilotAccountPath()}
                    sx={{width: '100%'}}
                    buttonProps={{fullWidth: true}}
                  />
                  <RutterButton
                    onSuccess={onConnectionSuccess}
                    defaultButtonText={'Connect Xero'}
                    platform="XERO"
                    pageLocation="copilotMakeAnOffer"
                    createRutterConnectionPath={rutterConnectionsApiCoPilotAccountPath()}
                    sx={{width: '100%'}}
                    buttonProps={{fullWidth: true}}
                  />
                  <PlaidButton
                    onSuccessCallback={onConnectionSuccess}
                    buttonText="Connect Bank Account"
                    buttonProps={{variant: 'outlined', fullWidth: true}}
                    createLinkTokenPath={plaidLinkTokensApiCoPilotAccountPath()}
                    createItemPath={plaidItemsApiCoPilotAccountPath()}
                  />
                </Stack>
              </>
            )}
          </Stack>
        </ProgressBox>
      </Box>
    </Modal>
  )
}
