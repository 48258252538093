import * as React from 'react'
import {Provider as ReduxProvider} from 'react-redux'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import {useFlags} from 'launchdarkly-react-client-sdk'
import {SnackbarProvider} from 'notistack'

import {ErrorBoundary} from 'src/ErrorBoundary'
import {CopilotAppThemeProvider} from 'src/framework/theming/CopilotAppThemeProvider'
import {NotFoundScreen} from 'src/screens/NotFoundScreen'
import {CopilotHomeScreen} from 'src/screens/copilot/CopilotHomeScreen'
import {CopilotV2HomeScreen} from 'src/screens/copilot/CopilotV2HomeScreen'
import {store} from 'src/store/partners/store'

export const CopilotApp = (): JSX.Element => {
  return (
    <ReduxProvider store={store}>
      <Router>
        <React.Suspense fallback={<></>}>
          <CopilotAppThemeProvider>
            <ErrorBoundary>
              <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
                <Routes />
              </SnackbarProvider>
            </ErrorBoundary>
          </CopilotAppThemeProvider>
        </React.Suspense>
      </Router>
    </ReduxProvider>
  )
}

const Routes = (): JSX.Element => {
  const {copilotV2Enabled} = useFlags()

  const routeConfigs = React.useMemo(
    () => [
      {
        path: ['/', '/copilot'],
        exact: true,
        component: copilotV2Enabled ? CopilotV2HomeScreen : CopilotHomeScreen,
      },
      {
        path: '*',
        component: NotFoundScreen,
      },
    ],
    [copilotV2Enabled],
  )

  return (
    <Switch>
      {routeConfigs.map((config, i) => (
        <Route
          key={i}
          path={config.path}
          exact={config.exact}
          component={config.component}
        />
      ))}
    </Switch>
  )
}
