import * as React from 'react'

import {
  Box,
  Divider,
  InputBaseComponentProps,
  Slider,
  Stack,
  TextField,
} from '@mui/material'

interface Props {
  low: number | null
  high: number | null
  min: number
  max: number
  sliderIncrement?: number
  onChange: (
    low: number | null,
    high: number | null,
    validationErrors: string[],
  ) => void
  inputComponent?: React.FunctionComponent<InputBaseComponentProps>
}
export const RangeFilterInput = ({
  low,
  high,
  min,
  max,
  sliderIncrement,
  onChange,
  inputComponent,
}: Props) => {
  const handleRangeChange = React.useCallback(
    (low, high) => {
      onChange(low, high, [])
    },
    [onChange],
  )

  const handleChangeLow: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value
    handleRangeChange(value === '' ? null : Number(value), high)
  }
  const handleChangeHigh: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value
    handleRangeChange(low, value === '' ? null : Number(value))
  }

  const handleSliderChange = (e: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      return
    }

    const [low, high] = newValue
    handleRangeChange(low, high)
  }

  return (
    <Stack>
      <Stack
        direction="row"
        divider={
          <Divider
            sx={{
              margin: 'auto',
              '.MuiDivider-wrapper': {
                px: 2,
              },
            }}
          >
            -
          </Divider>
        }
        spacing={0}
      >
        <TextField
          value={low ?? min}
          onChange={handleChangeLow}
          variant="outlined"
          inputProps={{
            inputMode: 'numeric',
          }}
          InputProps={inputComponent ? {inputComponent} : undefined}
        />
        <TextField
          value={high ?? max}
          onChange={handleChangeHigh}
          variant="outlined"
          inputProps={{inputMode: 'numeric'}}
          InputProps={inputComponent ? {inputComponent} : undefined}
        />
      </Stack>
      <Box sx={{px: 3}}>
        <Slider
          min={min}
          max={max}
          step={sliderIncrement}
          value={[low ?? min, high ?? max]}
          onChange={handleSliderChange}
        />
      </Box>
    </Stack>
  )
}
