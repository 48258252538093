import * as React from 'react'

import {useMutex} from 'src/contexts/mutex/useMutex'
import {apiCoPilotParcelsClaimedLocationsPath} from 'src/generated/routes'
import {useCopilotAccount} from 'src/hooks/request/useCopilotAccount'
import {useIndex} from 'src/hooks/request/useIndex'
import {
  setClaimedLocations,
  removeClaimedLocations,
} from 'src/store/partners/claimedLocationsSlice'
import {useAppDispatch, useAppSelector} from 'src/store/partners/store'
import {ClaimedLocation} from 'src/types/copilot'
import {ParsedIndexResponse} from 'src/util/request/parseResponse'

export const useClaimedLocations = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((state) => state.claimedLocations)
  const {getLock} = useMutex()

  const {user} = useCopilotAccount()

  const requestOptions = React.useMemo(
    () => ({
      suppressedErrorCodes: [401],
      onSuccess: ({
        entities: claimedLocations,
      }: ParsedIndexResponse<ClaimedLocation>) => {
        dispatch(setClaimedLocations(claimedLocations))
      },
      onError: () => {
        dispatch(removeClaimedLocations())
      },
    }),
    [dispatch],
  )

  const {fetch: fetchClaimedLocations, error: fetchClaimedLocationsError} =
    useIndex<ClaimedLocation>(
      apiCoPilotParcelsClaimedLocationsPath(),
      requestOptions,
    )

  const isClaimedLocation = React.useCallback(
    (taxAssessorId: string): boolean => {
      return state.items.some(
        (cl) => cl.taxAssessorId === taxAssessorId && cl.claimedByCurrentUser,
      )
    },
    [state.items],
  )

  React.useEffect(() => {
    if (state.items.length > 0) return
    if (!user) return
    if (!getLock('copilotClaimedLocations')) return

    fetchClaimedLocations()
  }, [
    fetchClaimedLocationsError,
    state.items,
    dispatch,
    getLock,
    fetchClaimedLocations,
    user,
  ])

  return {
    claimedLocations: state.items,
    hasBeenFetched: state.hasBeenFetched,
    refetch: fetchClaimedLocations,
    isClaimedLocation,
  }
}
