import * as React from 'react'

import {Button, CardMedia, Stack, Typography, IconButton} from '@mui/material'
import {grey} from '@mui/material/colors'

import {Drawer} from 'src/components/copilot/components/Drawer'
import {AddressAutocomplete} from 'src/components/copilot/mapbox/AddressAutocomplete'
import {MapParcel} from 'src/components/copilot/mapbox/SearchMapV2'
import {FileInput} from 'src/framework/FileInput'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {ProgressBox} from 'src/framework/ui/ProgressBox'
import {
  photoSearchApiCoPilotParcelsPath,
  updateCustomerPhotoApiCoPilotParcelsPath,
} from 'src/generated/routes'
import {FileUploadPayload, useFileUpload} from 'src/hooks/request/useFileUpload'
import {useRequest} from 'src/hooks/request/useRequest'
import {useToggle} from 'src/hooks/util/useToggle'
import {Parcel} from 'src/types/copilot/parcel'
import {trackEvent} from 'src/util/analytics'
import {ParsedResponse} from 'src/util/request/parseResponse'

type Step =
  | 'upload'
  | 'address_not_found'
  | 'confirm_address'
  | 'correct_address'
  | 'claim_business'

type PhotoSearchResponse = {
  address: string
  cherreParcelId: string
  city: string
  parcelCentroid: GeoJSON.Point | null
  photoId: string
  photoUrl: string
  postalCode: string
  state: string
  taxAssessorId: string
}

type PhotoSearchResultUpdatePayload = {
  taxAssessorId?: string
  claimedLocationBusinessName?: string | null
}

interface Props {
  onParcelFound: (parcel: MapParcel) => void
}

export const PropertyPhotoUploadButton = ({
  onParcelFound,
}: Props): JSX.Element => {
  const [drawerIsOpen, openDrawer, closeDrawer] = useToggle(false)
  const [step, setStep] = React.useState<Step>()
  const [photoSearchResponse, setPhotoSearchResponse] =
    React.useState<PhotoSearchResponse>()

  const {uploadFile, loading} = useFileUpload<
    FileUploadPayload,
    void,
    PhotoSearchResponse
  >(photoSearchApiCoPilotParcelsPath(), {
    disableDefaultErrorHandling: true,
  })

  const {request: updateCustomerPhoto} = useRequest<
    void,
    PhotoSearchResultUpdatePayload
  >('PUT')

  const handlePhotoSearchResponse = React.useCallback(
    (response: ParsedResponse<PhotoSearchResponse> | void) => {
      if (!response || !response.entity) return

      trackEvent('Found Photo Address', {
        propertyId: response.entity.taxAssessorId,
        address: response.entity.address,
      })
      closeDrawer()
      setPhotoSearchResponse(response.entity)
      setStep('confirm_address')
      setTimeout(openDrawer, 400)
    },
    [closeDrawer, openDrawer],
  )

  const handlePhotoUpdate = React.useCallback(
    async (correctedAddressParcel: Parcel | null) => {
      if (correctedAddressParcel) {
        trackEvent('Correct Photo Address', {
          propertyId: correctedAddressParcel.taxAssessorId,
          address: correctedAddressParcel.address,
        })
      } else {
        trackEvent('Confirm Photo Address', {
          propertyId: photoSearchResponse?.taxAssessorId,
          address: photoSearchResponse?.address,
        })
      }

      if (photoSearchResponse) {
        await updateCustomerPhoto({
          data: {
            taxAssessorId:
              correctedAddressParcel?.taxAssessorId ||
              photoSearchResponse.taxAssessorId,
          },
          url: updateCustomerPhotoApiCoPilotParcelsPath(
            photoSearchResponse.photoId,
          ),
        })

        const targetParcel = correctedAddressParcel || photoSearchResponse
        if (targetParcel) {
          closeDrawer()
          onParcelFound({
            parcelCentroid: targetParcel.parcelCentroid,
            cherreParcelId: targetParcel.cherreParcelId,
          })
        }
      }
    },
    [onParcelFound, photoSearchResponse, updateCustomerPhoto, closeDrawer],
  )

  const handleFileSelect = React.useCallback(
    async (e) => {
      const fileList: FileList | null = e.target?.files

      if (!fileList) return

      const file = fileList[0]

      trackEvent('Upload Photo', {file: file.name})
      setStep('upload')
      openDrawer()
      uploadFile({file})
        .then(handlePhotoSearchResponse)
        .catch(() => {
          setStep('address_not_found')
        })
    },
    [handlePhotoSearchResponse, openDrawer, uploadFile],
  )

  const drawerContents = React.useMemo(() => {
    switch (step) {
      case 'upload':
      case 'address_not_found':
        return (
          <ProgressBox loading={step == 'upload'} height="200px">
            <Typography variant="h2" textAlign="center">
              {step == 'address_not_found'
                ? 'Sorry, location of photo could not be found'
                : 'Uploading photo...'}
            </Typography>
          </ProgressBox>
        )
      case 'confirm_address':
        return (
          photoSearchResponse && (
            <ConfirmationForm
              parcel={photoSearchResponse}
              onConfirm={() => handlePhotoUpdate(null)}
              onWrongAddress={() => setStep('correct_address')}
            />
          )
        )
      case 'correct_address':
        return (
          <CorrectAddressForm
            onSubmit={(parcel: Parcel) => {
              handlePhotoUpdate(parcel)
            }}
          />
        )
    }
  }, [handlePhotoUpdate, photoSearchResponse, step])

  return (
    <>
      <IconButton
        color="secondary"
        sx={{
          backgroundColor: '#fff',
          aspectRatio: '1 / 1',
          height: '46px',
          width: '46px',
          '&:hover': {
            backgroundColor: grey[300],
          },
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: 'rgba(0,0,0,0.23)', // TODO: use theme
          position: 'relative',
        }}
      >
        <FileInput
          id={'photo-upload'}
          fileExtensionRestrictions={['.jpg', '.jpeg', '.heic']}
          onChange={handleFileSelect}
          disabled={loading}
          buttonText={null}
          buttonIcon={<MaterialSymbolIcon>photo_camera</MaterialSymbolIcon>}
          noStartMargin={true}
        />
      </IconButton>
      <Drawer
        onClose={closeDrawer}
        onSubmit={closeDrawer}
        open={drawerIsOpen}
        // We are setting this to 900 because the MapBox Search Box is zIndex 1000 and Drawer is 1200
        sx={{zIndex: 900}}
      >
        {drawerContents}
      </Drawer>
    </>
  )
}

interface ConfirmationFormProps {
  parcel: PhotoSearchResponse
  onWrongAddress: () => void
  onConfirm: () => void
}

const ConfirmationForm = ({
  parcel,
  onWrongAddress,
  onConfirm,
}: ConfirmationFormProps): JSX.Element => {
  return (
    <Stack spacing={2}>
      <Typography variant="h2" textAlign="center">
        Found you!
      </Typography>
      <Typography variant="body1" textAlign="center">
        {`${parcel.address}, ${parcel.city}, ${parcel.state} ${parcel.postalCode}`}
      </Typography>
      <CardMedia
        component="img"
        image={parcel.photoUrl}
        sx={{height: 250, objectFit: 'cover'}}
      />

      <Stack direction="row" gap={3}>
        <Button
          onClick={onWrongAddress}
          variant="outlined"
          sx={{
            flexBasis: '50%',
          }}
        >
          Wrong address
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          sx={{
            flexBasis: '50%',
          }}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  )
}
interface CorrectAddressFormProps {
  onSubmit: (parcel: Parcel) => void
}

const CorrectAddressForm = ({
  onSubmit,
}: CorrectAddressFormProps): JSX.Element => {
  const [input, setInput] = React.useState<string>('')

  return (
    <Stack spacing={2} height="300px">
      <Typography variant="h2" textAlign="center">
        Enter the correct address
      </Typography>
      <AddressAutocomplete
        inputElementId="photo-search-autocomplete"
        input={input}
        setInput={setInput}
        onParcelFound={onSubmit}
      />
    </Stack>
  )
}
