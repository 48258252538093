import * as React from 'react'

import {Box, Paper, CircularProgress} from '@mui/material'

import {AdmittanceForm} from 'src/components/copilot/AdmittanceForm'
import {LoginForm} from 'src/components/copilot/LoginForm'
import {ClaimYourBusinessPage} from 'src/components/copilot/claimYourSpace/ClaimYourBusinessPage'
import {MobileSwipeDrawer} from 'src/framework/ui/MobileSwipeDrawer'
import {useClaimedParcels} from 'src/hooks/request/useClaimedParcels'
import {useCopilotAccount} from 'src/hooks/request/useCopilotAccount'
import {useToggle} from 'src/hooks/util/useToggle'
import {User} from 'src/types'
import {trackEvent} from 'src/util/analytics'
import {ParsedResponse} from 'src/util/request/parseResponse'

interface Props {
  attemptedActionName?: string
  children: JSX.Element
  isActive?: boolean
  onCreateAccount?: () => void
  renderInPlace?: boolean
}

export const AdmittedUserContainer = ({
  attemptedActionName,
  children,
  isActive = true,
  onCreateAccount,
  renderInPlace = false,
}: Props): JSX.Element | null => {
  const {user} = useCopilotAccount()
  const [
    isAdmitUserDrawerRendered,
    renderAdmitUserDrawer,
    unrenderAdmitUserDrawer,
  ] = useToggle(false)

  const handleClick = React.useCallback(
    (e) => {
      e.stopPropagation()
      trackEvent('View Login Drawer', {attemptedAction: attemptedActionName})
      renderAdmitUserDrawer()
    },
    [attemptedActionName, renderAdmitUserDrawer],
  )

  const handleSuccessfulAdmittance = React.useCallback(async () => {
    unrenderAdmitUserDrawer()

    onCreateAccount && onCreateAccount()
  }, [onCreateAccount, unrenderAdmitUserDrawer])

  const userIsAdmitted = React.useMemo(() => !!user?.admitted, [user])

  if (renderInPlace) {
    if (userIsAdmitted) return children

    return isActive ? <LoginAndAdmitForm /> : null
  }

  return (
    <Box position="relative">
      {!userIsAdmitted && (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          onClick={handleClick}
          sx={{cursor: 'pointer'}}
          zIndex={5}
        />
      )}
      {children}
      {isAdmitUserDrawerRendered && (
        <AdmitUserDrawer
          onClose={unrenderAdmitUserDrawer}
          onSuccess={handleSuccessfulAdmittance}
        />
      )}
    </Box>
  )
}

interface AdmitUserDrawerProps {
  onClose: () => void
  onSuccess: () => void
}

const AdmitUserDrawer = ({
  onClose,
  onSuccess,
}: AdmitUserDrawerProps): JSX.Element => {
  return (
    <MobileSwipeDrawer
      open={true}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      big={true}
      sx={{zIndex: 900}}
    >
      <LoginAndAdmitForm onSuccess={onSuccess} />
    </MobileSwipeDrawer>
  )
}

interface LoginAndAdmitFormProps {
  onSuccess?: () => void
}

const LoginAndAdmitForm = ({onSuccess}: LoginAndAdmitFormProps) => {
  const {
    user,
    loading,
    hasBeenFetched,
    refetch: refetchUserFromApi,
  } = useCopilotAccount()

  const {refetch: refetchClaimedParcels} = useClaimedParcels()

  const refetchUserAndParcels = React.useCallback(() => {
    refetchUserFromApi().then(async (response: ParsedResponse<User>) => {
      await refetchClaimedParcels()
      if (response.entity?.admitted) {
        onSuccess && onSuccess()
      }
    })
  }, [onSuccess, refetchClaimedParcels, refetchUserFromApi])

  if (!hasBeenFetched || loading) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 50,
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  // TODO: thoroughly test Login and Admittance Form behavior
  /**
   * if you have a cookie
   * if you fail OTP (and try again in new tab)
   * if you succeed OTP but fail admittance (and try again in new tab)
   * if you pass admittance
   */
  if (!user) {
    // TODO: login OTP/expired session styling to match newer designs
    return (
      <Paper sx={{height: '100%', width: '100%', p: 2}}>
        <LoginForm onSuccess={refetchUserAndParcels} />
      </Paper>
    )
  }

  if (!user.claimedBusiness) {
    return <ClaimYourBusinessPage onComplete={refetchUserAndParcels} />
  }

  if (!user.admitted) {
    // TODO: admittance form styling to match newer designs
    return <AdmittanceForm onSuccess={refetchUserAndParcels} />
  }

  return null
}
