import * as React from 'react'

import {Divider, Typography} from '@mui/material'
import {Box} from '@mui/material'
import {Stack} from '@mui/system'
import {capitalize} from 'lodash'

import {Parcel} from 'src/types/copilot'
import {formatCurrency, formatDecimal} from 'src/util/format'
import {formatAddress} from 'src/util/format/formatAddress'

interface Props {
  parcel: Parcel
}

type ListingStatus = Parcel['listingStatus']

const StatusLabel = ({status}: {status: ListingStatus}) => {
  const getConfig = (status: ListingStatus) => {
    switch (status) {
      case 'for_sale':
        return {
          label: 'For sale',
          color: '#4CAF50', // green
        }
      case 'for_lease':
        return {
          label: 'For lease',
          color: '#2196F3', // blue
        }
      case 'off_market':
        return {
          label: 'Off market',
          color: '#FFC107', // yellow
        }
      default:
        return {
          label: 'Off market',
          color: '#FFC107', // yellow
        }
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '4px 10px',
        borderRadius: '20px',
        backgroundColor: 'rgba(239, 242, 246, 1)',
        maxWidth: 'fit-content',
      }}
    >
      <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: getConfig(status).color,
          marginRight: 1,
        }}
      />
      <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
          color: '#333',
        }}
      >
        {getConfig(status).label}
      </Typography>
    </Box>
  )
}

export const ParcelHighlights = ({parcel}: Props): JSX.Element => {
  return (
    <>
      <Stack spacing={0.5}>
        <StatusLabel status={parcel.listingStatus} />
        <Stack
          display="flex"
          flexDirection={'row'}
          gap={1}
          alignItems={'flex-end'}
        >
          <Typography variant="h4">
            {formatCurrency(parcel.salesPrice?.toString(), {
              precision: 0,
            })}
          </Typography>
          <Typography
            variant="body1"
            color="secondary"
            mb={0.25}
            sx={{fontSize: '.75rem'}}
          >
            {parcel.salesPriceSource == 'Listing' ? 'asking price' : 'estimate'}
            {parcel.salesPriceSource == 'Estimate' &&
            parcel.listingStatus == 'for_sale'
              ? ' (asking price not available)'
              : null}
          </Typography>
        </Stack>
        <Typography>
          {formatAddress(parcel.address, parcel.city, parcel.state, null)}
        </Typography>
        <Stack
          direction="row"
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{borderColor: '#000000'}}
            />
          }
          alignItems="center"
          spacing={1}
        >
          <Typography>{capitalize(parcel.propertyType || '')}</Typography>
          <Typography>
            {formatDecimal(parcel.buildingSqFt, {precision: 0, suffix: 'sqft'})}
          </Typography>
        </Stack>
      </Stack>
    </>
  )
}
