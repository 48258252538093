import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'

import {configureStore} from '@reduxjs/toolkit'

import attachmentTypesReducer from 'src/store/partners/attachmentTypesSlice'
import claimedLocationsReducer from 'src/store/partners/claimedLocationsSlice'
import claimedParcelsReducer from 'src/store/partners/claimedParcelsSlice'
import currentUserReducer from 'src/store/partners/currentUserSlice'
import navPageReducer from 'src/store/partners/navPageSlice'
import watchlistParcelsReducer from 'src/store/partners/watchlistParcelsSlice'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initializeStore = () =>
  configureStore({
    reducer: {
      currentUser: currentUserReducer,
      attachmentTypes: attachmentTypesReducer,
      claimedParcels: claimedParcelsReducer,
      claimedLocations: claimedLocationsReducer,
      watchlistParcels: watchlistParcelsReducer,
      navPage: navPageReducer,
    },
  })
export const store = initializeStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
