import React from 'react'

import {
  TextField,
  InputAdornment,
  InputBaseComponentProps,
  SxProps,
} from '@mui/material'

interface CurrencyInputProps {
  label?: string
  value: number | null
  onChange: (value: number) => void
  placeholder?: string
  sx?: SxProps
  inputSx?: SxProps
  inputProps?: InputBaseComponentProps
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  label,
  value,
  onChange,
  placeholder,
  sx,
  inputSx,
  inputProps,
}) => {
  const displayValue = formatCurrency(value)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[$,]/g, '')
    const numericValue = parseFloat(rawValue)

    if (!isNaN(numericValue)) {
      onChange(numericValue)
    } else if (rawValue === '') {
      onChange(0)
    }
  }

  return (
    <TextField
      label={label}
      value={displayValue || ''}
      onChange={handleChange}
      placeholder={placeholder}
      fullWidth
      sx={sx}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        inputProps: inputProps,
        sx: inputSx,
      }}
    />
  )
}

function formatCurrency(value: number | null): string | null {
  return (
    value
      ?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      ?.slice(1) || null
  ) // Remove the leading '$' as we're using InputAdornment
}

export default CurrencyInput
