import React from 'react'

import {Avatar, Typography} from '@mui/material'
import {Box, Stack} from '@mui/system'

import {ParcelCard} from 'src/components/copilot/ParcelCard'
import {PlaidButton} from 'src/components/deal/PlaidButton'
import {RutterButton} from 'src/components/partners/RutterButton'
import {
  rutterConnectionsApiCoPilotAccountPath,
  plaidLinkTokensApiCoPilotAccountPath,
  plaidItemsApiCoPilotAccountPath,
} from 'src/generated/routes'
import {useCopilotAccount} from 'src/hooks/request/useCopilotAccount'
import {Parcel} from 'src/types/copilot'

interface Props {
  parcel: Parcel
  onComplete: () => void
}

export const ClaimedAsOwnPage = ({parcel, onComplete}: Props) => {
  const {user, refetch: refetchUser} = useCopilotAccount()

  const onConnectionSuccess = React.useCallback(async () => {
    await refetchUser()
    onComplete()
  }, [onComplete, refetchUser])

  return (
    <Box px={2} maxWidth={{xs: '100%', md: '375px'}} width="100%">
      <Stack alignItems="center" mt={6}>
        <Typography variant="h4">Reap your rewards</Typography>
      </Stack>
      <Box mt={2}>
        <ParcelCard parcel={parcel} disableActions />
      </Box>
      <Box mt={3}>
        <Typography variant="h5">Connect your financials to:</Typography>
        <Stack spacing={3} mt={2}>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Avatar sx={{bgcolor: 'black'}}>1</Avatar>
            <Typography variant="body1">
              List your property and get offers from the most strategic,
              long-term investors in your community - small business owners -
              and refinancing and collateralization opportunities as you build
              wealth
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Avatar sx={{bgcolor: 'black'}}>2</Avatar>
            <Typography variant="body1">
              Get 24/7 personalized, expert advice from copilot including a
              detailed property valuation analysis and refinancing analyses
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Avatar sx={{bgcolor: 'black'}}>3</Avatar>
            <Typography variant="body1">
              Get moderator status on all feeds
            </Typography>
          </Stack>
        </Stack>
      </Box>
      {user && (user.connectedAccounting || user.connectedBanking) ? (
        <Box py={2}>
          <Typography variant="body1">
            Thank you for connecting your financials. You&apos;re all set!
          </Typography>
        </Box>
      ) : (
        <Stack width="100%" py={2} spacing={2}>
          <RutterButton
            onSuccess={onConnectionSuccess}
            defaultButtonText={'Connect QuickBooks'}
            platform="QUICKBOOKS"
            pageLocation="copilotMakeAnOffer"
            createRutterConnectionPath={rutterConnectionsApiCoPilotAccountPath()}
            sx={{width: '100%'}}
            buttonProps={{fullWidth: true}}
          />
          <PlaidButton
            onSuccessCallback={onConnectionSuccess}
            buttonText="Connect Bank Account"
            buttonProps={{variant: 'outlined'}}
            createLinkTokenPath={plaidLinkTokensApiCoPilotAccountPath()}
            createItemPath={plaidItemsApiCoPilotAccountPath()}
          />
        </Stack>
      )}
    </Box>
  )
}
