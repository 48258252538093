import * as React from 'react'
import {useRef} from 'react'

import {useFlags} from 'launchdarkly-react-client-sdk'

import {InputData} from 'src/components/copilot/UserInput'
import {
  apiCoPilotCancelInputPath,
  apiCoPilotChatMessagesPath,
  apiCoPilotLeaseDetailsPath,
  apiCoPilotLeaseStatusPath,
} from 'src/generated/routes'
import {useIndex} from 'src/hooks/request/useIndex'
import {useRequest} from 'src/hooks/request/useRequest'
import {useShow} from 'src/hooks/request/useShow'
import {JsonApiIndexResponse} from 'src/types'
import {ChatSelectOption, CustomerLease, Message} from 'src/types/copilot'
import {RecommendationOption} from 'src/types/copilot/message'
import {parseIndexResponse} from 'src/util/request/parseResponse'

type UseCopilotChatResults = {
  messages: Message[]
  lease: CustomerLease | null
  refetchLease: () => Promise<unknown>
  onSubmit: (input?: InputData) => void
  onCancel: () => void
  recommendationCarouselVisible: boolean
  recommendationOptions: ChatSelectOption[]
  recommendationMode: RecommendationOption | null
}

type LeaseStatusResponse = {
  ready: boolean
}

const getDelayFromString = (msg: Message, disabled: boolean): number => {
  const minDelay = 1000
  const maxDelay = 2000
  const minLength = 5

  if (disabled) {
    return 0
  }

  if (msg.type == 'RECOMMENDATION_CAROUSEL') {
    return minDelay
  }

  if (msg.rows) {
    return maxDelay
  }

  if (msg.text == null) {
    return 0
  }

  const delay =
    minDelay +
    (msg.text.length - minLength) * ((maxDelay - minDelay) / (100 - minLength))

  return Math.min(Math.max(delay, minDelay), maxDelay)
}

export const useCopilotChat = (): UseCopilotChatResults => {
  const {copilotMessageDelayDisabled} = useFlags()

  const [messages, setMessages] = React.useState<Message[]>([])
  const [recommendationOptions, setRecommendationOptions] = React.useState<
    ChatSelectOption[]
  >([])
  const [recommendationMode, setRecommendationMode] =
    React.useState<RecommendationOption | null>(null)

  const recommendationCarouselVisible = React.useMemo(() => {
    return recommendationOptions.length > 0
  }, [recommendationOptions])

  const updateRecommendationMode = React.useCallback((value: string | null) => {
    if (
      Object.values(RecommendationOption).includes(
        value as RecommendationOption,
      )
    ) {
      setRecommendationMode(value as RecommendationOption)
    }
  }, [])

  const {entities: newMessages, fetch: fetchMessages} = useIndex<Message>(
    apiCoPilotChatMessagesPath(),
    {
      autoRequest: true,
      suppressedErrorCodes: [500],
    },
  )
  const {request: createMessage} = useRequest<
    JsonApiIndexResponse<Message>,
    InputData
  >('POST', apiCoPilotChatMessagesPath())
  const {request: cancelInput} = useRequest('POST', apiCoPilotCancelInputPath())

  const messageCount = useRef<number>(0)
  const leaseStatusPollIntervalId = useRef<number | undefined>()
  const {request: fetchLeaseStatus} = useRequest<LeaseStatusResponse>(
    'GET',
    apiCoPilotLeaseStatusPath(),
  )

  const {entity: lease, fetch: fetchLease} = useShow<CustomerLease>(
    apiCoPilotLeaseDetailsPath(),
    {
      autoRequest: true,
      suppressedErrorCodes: [500],
    },
  )

  const pollLeaseStatus = React.useCallback(() => {
    fetchLeaseStatus().then((response: LeaseStatusResponse | void) => {
      if (response?.ready) {
        window.clearInterval(leaseStatusPollIntervalId.current)
        leaseStatusPollIntervalId.current = undefined
        fetchLease().then(() => {
          fetchMessages()
        })
      }
    })
  }, [fetchLease, fetchLeaseStatus, fetchMessages])

  const startPollingLeaseStatus = React.useCallback(() => {
    const timer = window.setInterval(pollLeaseStatus, 1000)
    leaseStatusPollIntervalId.current = timer
  }, [pollLeaseStatus])

  const updateMessageList = React.useCallback(
    (newMessagesList: Message[]) => {
      if (messages.length === 0) {
        setMessages(newMessagesList)
        messageCount.current = newMessagesList.length

        newMessagesList.forEach((msg) => {
          updateRecommendationMode(msg.value as string)
          if (msg.type === 'RECOMMENDATION_CAROUSEL') {
            setRecommendationOptions(msg.options ?? [])
          }
        })

        return
      }

      if (newMessagesList.length < messages.length) {
        return
      }

      let delaySum = 0
      const newMessageIndex = messageCount.current
      for (let i = newMessageIndex; i < newMessagesList.length; i++) {
        const msg = newMessagesList[i]
        if (msg.type === 'POLL_LEASE_STATUS') {
          startPollingLeaseStatus()
        }

        delaySum += getDelayFromString(msg, copilotMessageDelayDisabled)
        if (msg.type === 'RECOMMENDATION_CAROUSEL') {
          setTimeout(() => {
            setRecommendationOptions(msg.options ?? [])
          }, delaySum - 10)
        }
        setTimeout(
          () => {
            setMessages((prevState) => [...prevState, msg])
          },
          msg.type === 'USER_MESSAGE' ? 0 : delaySum,
        )
      }

      messageCount.current = newMessagesList.length
    },
    [
      messages,
      updateRecommendationMode,
      copilotMessageDelayDisabled,
      startPollingLeaseStatus,
    ],
  )

  const handleSubmit = (input?: InputData) => {
    updateRecommendationMode(input?.value as string)
    if (input) {
      updateMessageList([...messages, {text: input.text, type: 'USER_MESSAGE'}])
      createMessage({data: {text: input.text, value: input.value}}).then(
        (response) => {
          const {entities: messagesList} = parseIndexResponse(response ?? null)
          updateMessageList(messagesList)
        },
      )
    } else {
      fetchMessages()
    }
  }

  const handleCancel = () => {
    cancelInput().then(() => {
      fetchMessages()
    })
  }

  React.useEffect(() => {
    if (newMessages.length > messages.length) {
      updateMessageList(newMessages)
    }
  }, [newMessages, messages.length, updateMessageList])

  return {
    lease,
    refetchLease: fetchLease,
    messages: messages,
    onSubmit: handleSubmit,
    onCancel: handleCancel,
    recommendationCarouselVisible: recommendationCarouselVisible,
    recommendationOptions: recommendationOptions,
    recommendationMode: recommendationMode,
  }
}
