import {debounce} from 'lodash'

import {User} from 'src/types'
import * as Hotjar from 'src/util/hotjar'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any
  }
}

const DEBOUNCE_DELAY = 1000

// Docs: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
export const identify = (user: User): void => {
  if (!window.analytics) return

  const userAgent = navigator.userAgent
  const hotjarUserId = Hotjar.getUserId()
  if (user.internalUser) {
    window.analytics.identify(user.internalUser.id, {
      email: user.internalUser.email,
      name: user.internalUser.name,
      impersonatingUserEmail: user.email,
      userAgent,
    })
  } else {
    window.analytics.identify(user.id, {
      email: user.email,
      name: user.name,
      // if existing user previously impersonated a user, we want to clear that attribute
      impersonatingUserEmail: getUserProperty('impersonatingUserEmail')
        ? '_'
        : null,
      userAgent,
      hotjarUserId,
    })
  }
}

const getUserProperty = (propertyName: string): string | null => {
  if (!window.analytics || !window.analytics.user) return null

  return window.analytics.user().traits()[propertyName]
}

export const identifyReset = (): void => {
  if (!window.analytics) return

  window.analytics.reset()
}

export const identifyAnonymousUser = (anonymousId: string): void => {
  if (!window.analytics) return

  window.analytics.setAnonymousId(anonymousId)
}

export const trackPage = (
  name: string,
  properties: {[key: string]: unknown} = {},
): void => {
  if (!window.analytics) return

  window.analytics.page(name, properties)
}

export const trackEvent = (
  eventName: string,
  properties: object = {},
): void => {
  if (!window.analytics) return

  window.analytics.track(eventName, properties)
}

export const trackEventDebounced = debounce(trackEvent, DEBOUNCE_DELAY)
