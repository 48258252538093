import React, {useRef} from 'react'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {Typography, CircularProgress, Button} from '@mui/material'
import {green} from '@mui/material/colors'
import {Box, Stack} from '@mui/system'

import {leaseStatusApiCoPilotAccountPath} from 'src/generated/routes'
import {useShow} from 'src/hooks/request/useShow'
import {CustomerLeaseStatus} from 'src/types/copilot/customerLeaseStatus'
import {trackEvent} from 'src/util/analytics'

interface Props {
  leaseId: string
  onComplete: () => void
}

const MAX_POLL_REQUESTS = 10
const POLL_INTERVAL = 3000

export const LeaseProcessingPage = ({leaseId, onComplete}: Props) => {
  const {entity: leaseStatus, fetch: refetchLeaseStatus} =
    useShow<CustomerLeaseStatus>(leaseStatusApiCoPilotAccountPath(leaseId), {
      autoRequest: true,
      disableDefaultErrorHandling: true,
    })

  const leaseProcessingComplete = React.useMemo(() => {
    return leaseStatus?.leaseAddressParsingPassed
  }, [leaseStatus])

  const leaseStatusPollIntervalId = useRef<number | undefined>()
  const requestCount = useRef<number>(0)

  const pollLeaseStatus = React.useCallback(() => {
    requestCount.current += 1

    console.log('polling lease status', requestCount.current)
    refetchLeaseStatus()

    if (requestCount.current >= MAX_POLL_REQUESTS) {
      console.log('maxed out poll requests', requestCount.current)

      window.clearInterval(leaseStatusPollIntervalId.current)
      leaseStatusPollIntervalId.current = undefined
      return
    }
  }, [refetchLeaseStatus])

  React.useEffect(() => {
    if (leaseStatusPollIntervalId.current) {
      return
    }

    console.log('useEffect: set up timer')
    const timer = window.setInterval(pollLeaseStatus, POLL_INTERVAL)
    leaseStatusPollIntervalId.current = timer

    return () => window.clearInterval(leaseStatusPollIntervalId.current)
  }, [pollLeaseStatus])

  React.useEffect(() => {
    if (leaseProcessingComplete && leaseStatusPollIntervalId.current) {
      trackEvent('Completed Processing Lease')
      window.clearInterval(leaseStatusPollIntervalId.current)
      leaseStatusPollIntervalId.current = undefined
    }
  }, [leaseProcessingComplete])

  return (
    <Box paddingX={1} maxWidth={{xs: '100%', md: '375px'}} width="100%">
      <Stack alignItems="center" spacing={4}>
        <Typography variant="h4" mt={6}>
          Processing your lease
        </Typography>
        <Box sx={{p: 3, maxWidth: 400, mx: 'auto'}}>
          <Typography variant="h5" gutterBottom>
            Lease Validation
          </Typography>
          <Stack spacing={2} mt={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              {leaseStatus?.leaseAddressParsingPassed ||
              leaseStatus?.leaseValidationPassed ? (
                <CheckCircleIcon sx={{color: green[500]}} />
              ) : (
                <CircularProgress size="1rem" color="info" />
              )}
              <Typography>Validating file is a lease</Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              {leaseStatus?.leaseAddressParsingPassed ? (
                <CheckCircleIcon sx={{color: green[500]}} />
              ) : (
                <CircularProgress size="1rem" color="info" />
              )}
              <Typography>Extracting address from lease</Typography>
            </Stack>
          </Stack>
        </Box>
        {leaseProcessingComplete && (
          <Stack>
            <Typography variant="h5">Lease processing complete</Typography>
            <Button onClick={onComplete}>Done</Button>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
