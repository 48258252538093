import * as React from 'react'

import {
  Box,
  Typography,
  Stack,
  SvgIcon,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Link,
} from '@mui/material'
import {grey} from '@mui/material/colors'

import {InputData, UserInput} from 'src/components/copilot/UserInput'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {useToggle} from 'src/hooks/util/useToggle'
import {Message} from 'src/types/copilot'
import {LearnMoreSection} from 'src/types/copilot/message'
import {ReactComponent as WithcoAvatar} from 'svg/withco_chat_avatar.svg'

type DisplayedMessage = {
  isStartOfCluster: boolean
  isEndOfCluster: boolean
  isUser: boolean
  message: Message
}

const isUserMessage = (msg: Message) => msg.type === 'USER_MESSAGE'

interface Props {
  messages: Message[]
  refetchLease: () => Promise<unknown>
  onSubmit: (inputData?: InputData) => void
  onCancel: () => void
}

export const ChatInterface = ({
  messages,
  refetchLease,
  onSubmit,
  onCancel,
}: Props) => {
  const messagesEndRef = React.useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'})
  }

  React.useEffect(scrollToBottom, [messages])

  const displayedMessages: Array<DisplayedMessage> = React.useMemo(() => {
    const displayedMessages: Array<DisplayedMessage> = []

    messages.forEach((msg, index) => {
      const isStartOfCluster =
        index === 0 || isUserMessage(msg) !== isUserMessage(messages[index - 1])
      const isEndOfCluster =
        index === messages.length - 1 ||
        isUserMessage(msg) !== isUserMessage(messages[index + 1])

      displayedMessages.push({
        isStartOfCluster,
        isEndOfCluster,
        isUser: isUserMessage(msg),
        message: msg,
      })
    })

    return displayedMessages
  }, [messages])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        py: 2,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
          px: 2,
        }}
      >
        <Stack direction="column" spacing={1}>
          {displayedMessages.map((msg, index, arr) => {
            if (index == arr.length - 1) {
              return (
                <Box
                  key={`chat-message-${index}`}
                  sx={{
                    animation: 'slideUpFadeIn 0.5s ease-in',
                    '@keyframes slideUpFadeIn': {
                      from: {
                        transform: 'translateY(20px)',
                        opacity: 0,
                      },
                      to: {
                        transform: 'translateY(0)',
                        opacity: 1,
                      },
                    },
                  }}
                >
                  <ChatMessage
                    displayedMessage={msg}
                    showUserInput={index === displayedMessages.length - 1}
                    handleSubmit={onSubmit}
                    handleCancel={onCancel}
                    refetchLease={refetchLease}
                    scrollToBottom={scrollToBottom}
                  />
                </Box>
              )
            }

            return (
              <ChatMessage
                key={`chat-message-${index}`}
                displayedMessage={msg}
                showUserInput={index === displayedMessages.length - 1}
                handleSubmit={onSubmit}
                handleCancel={onCancel}
                refetchLease={refetchLease}
                scrollToBottom={scrollToBottom}
              />
            )
          })}
          <div style={{marginTop: 0}} ref={messagesEndRef} />
        </Stack>
      </Box>
    </Box>
  )
}

interface ChatMessageProps {
  displayedMessage: DisplayedMessage
  showUserInput: boolean
  handleSubmit: (inputData?: InputData) => void
  handleCancel: () => void
  refetchLease: () => Promise<unknown>
  scrollToBottom: () => void
}

const ChatMessage = ({
  displayedMessage: {message, isUser, isEndOfCluster, isStartOfCluster},
  handleSubmit,
  handleCancel,
  showUserInput,
  refetchLease,
  scrollToBottom,
}: ChatMessageProps) => {
  const messageTextComponents = React.useMemo(() => {
    if (!message.text) {
      return null
    }

    if (!message.linkUrl) {
      return <Typography>{message.text}</Typography>
    } else {
      const components = message.text.split('<LINK>')
      return (
        <Typography>
          {components[0]}
          <Link href={message.linkUrl}>{message.linkText}</Link>
          {components[1]}
        </Typography>
      )
    }
  }, [message])

  return (
    <Stack direction="column" spacing={1}>
      {isStartOfCluster ? (
        <Box>
          {isUser ? (
            <Typography color="#000" textAlign="right" fontWeight="700">
              You
            </Typography>
          ) : (
            <SvgIcon
              component={WithcoAvatar}
              inheritViewBox
              sx={{width: 'inherit', height: 'inherit '}}
            />
          )}
        </Box>
      ) : null}
      {message.text || message.rows ? (
        <ChatBubble
          isUser={isUser}
          isEndOfCluster={isEndOfCluster}
          fullWidth={!!message.rows}
          learnMore={message.learnMore}
        >
          <Stack direction="column" spacing={1}>
            {messageTextComponents}
            {message.rows ? (
              <ChatTable
                rows={message.rows}
                tableHeader={message.tableHeader}
                borderTop={!!message.text}
              />
            ) : null}
          </Stack>
        </ChatBubble>
      ) : null}
      {showUserInput ? (
        <UserInput
          type={message.type}
          options={message.options}
          skippable={message.skippable}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          refetchLease={refetchLease}
          scrollToBottom={scrollToBottom}
        />
      ) : null}
    </Stack>
  )
}

interface ChatBubbleProps {
  isUser: boolean
  isEndOfCluster: boolean
  children: React.ReactNode
  learnMore?: LearnMoreSection[]
  fullWidth?: boolean
}

const ChatBubble = ({
  isUser,
  isEndOfCluster,
  fullWidth = false,
  children,
  learnMore,
}: ChatBubbleProps) => {
  const borderRadius = React.useMemo(() => {
    if (isUser) {
      return isEndOfCluster ? '30px 4px 30px 30px' : '30px 4px 4px 30px'
    }

    if (learnMore) {
      return '4px 30px 0 0'
    }

    return isEndOfCluster ? '4px 30px 30px 30px' : '4px 30px 30px 4px'
  }, [isEndOfCluster, isUser, learnMore])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isUser ? 'flex-end' : 'flex-start',
      }}
    >
      <Stack direction="column" width={fullWidth ? '100%' : 'auto'}>
        <Box
          sx={{
            p: 2,
            color: isUser ? '#ffffff' : 'text.primary',
            backgroundColor: isUser ? '#323232' : 'background.paper',
            borderRadius: {borderRadius},
            width: fullWidth ? '100%' : 'auto',
          }}
        >
          {children}
        </Box>
        {learnMore && (
          <LearnMoreExpansion learnMore={learnMore} fullWidth={fullWidth} />
        )}
      </Stack>
    </Box>
  )
}

interface ChatTableProps {
  rows: string[][]
  tableHeader?: 'column' | 'row' | 'bullet' | 'financials'
  borderTop: boolean
}
const ChatTable = ({
  rows,
  tableHeader,
  borderTop,
}: ChatTableProps): JSX.Element => {
  return tableHeader == 'financials' ? (
    <Table>
      <TableBody>
        {rows.map((cols, i) => (
          <TableRow key={i}>
            {cols[2] == 'TITLE' ? (
              <TableCell
                sx={{
                  borderTop: 'none',
                  px: 0.5,
                  py: 1,
                }}
                colSpan={2}
              >
                <Typography fontWeight={700}>{cols[0]}</Typography>
              </TableCell>
            ) : (
              <>
                <TableCell
                  align="left"
                  sx={{
                    px: 0.5,
                    py: 1,
                    backgroundColor: cols[2] == 'SUBTOTAL' ? '#eff2f6' : null,
                  }}
                >
                  <Typography
                    fontWeight={cols[2] == 'SUBTOTAL' ? '700' : '400'}
                    pl={cols[2] == 'SUBTOTAL' ? 2 : 0}
                  >
                    {cols[0]}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    px: 0.5,
                    py: 1,
                    backgroundColor: cols[2] == 'SUBTOTAL' ? '#eff2f6' : null,
                  }}
                >
                  <Typography
                    fontWeight={cols[2] == 'SUBTOTAL' ? '700' : '400'}
                  >
                    {cols[1]}
                  </Typography>
                </TableCell>
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <Table>
      <TableBody>
        {rows.map((cols, i) => (
          <TableRow key={i}>
            {tableHeader == 'bullet' ? (
              <TableCell
                sx={{
                  borderTop: borderTop && i == 0 ? '1px solid' : 'none',
                  borderTopColor: grey[300],
                  px: 0,
                  pt: 1,
                  pb: 1,
                }}
              >
                <Typography>
                  <strong>{cols[0]}</strong> {cols[1]}
                </Typography>
              </TableCell>
            ) : (
              cols.map((col, j, arr) => (
                <TableCell
                  key={j}
                  align={arr.length - 1 == j ? 'right' : 'left'}
                  sx={{
                    borderTop: borderTop && i == 0 ? '1px solid' : 'none',
                    borderTopColor: grey[300],
                    px: 0,
                    pt: 1,
                    pb: 1,
                  }}
                >
                  <Typography
                    fontWeight={
                      (tableHeader == 'row' ? i == 0 : j === 0) ? '700' : '400'
                    }
                  >
                    {col}
                  </Typography>
                </TableCell>
              ))
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

interface LearnMoreExpansionProps {
  learnMore: LearnMoreSection[]
  fullWidth: boolean
}

const LearnMoreExpansion = ({
  learnMore,
  fullWidth,
}: LearnMoreExpansionProps) => {
  const [isLearnMoreExpanded, , , toggleLearnMoreExpanded] = useToggle(false)

  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        backgroundColor: '#eff2f6',
        width: fullWidth ? '100%' : 'auto',
      }}
      onClick={toggleLearnMoreExpanded}
    >
      <Stack direction="row" alignItems="center" color="primary.main">
        <MaterialSymbolIcon>
          {isLearnMoreExpanded ? 'arrow_drop_down' : 'arrow_right'}
        </MaterialSymbolIcon>
        <Typography fontSize="0.75rem" fontWeight="700">
          Learn more
        </Typography>
      </Stack>
      <Collapse in={isLearnMoreExpanded} timeout={500}>
        {learnMore.map((section, i) => (
          <Stack key={i} direction="column" py={1} spacing={0.25}>
            <Typography
              fontSize="0.75rem"
              fontWeight="700"
              color="text.primary"
            >
              {section.question}
            </Typography>
            <Typography fontSize="0.75rem" color="text.primary">
              {section.content}
            </Typography>
          </Stack>
        ))}
      </Collapse>
    </Box>
  )
}
