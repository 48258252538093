import React, {useState} from 'react'

import {Box, Typography, Button, Stack, TextField} from '@mui/material'
import {noop} from 'lodash'

import BuildoutCalculatorModal from 'src/components/copilot/costCalculator/BuildoutCalculatorModal'
import LoanPaymentCalculatorModal from 'src/components/copilot/costCalculator/LoanPaymentCalculatorModal'
import {trackEvent} from 'src/util/analytics'
import {formatDecimal} from 'src/util/format'

interface Props {
  propertyPrice: number
  annualPropertyTaxes: number
  buildingSqft: number
  propertyType: string
}

export interface PaymentDetails {
  propertyPrice: number
  buildoutCost: number
  loanType: '7a' | '504'
  interestRate: number
  downpaymentPercent: number
  monthlyPropertyTaxes: number
  monthlyInsurance: number
  businessCashflow: number
}

const CostCalculator = ({
  propertyPrice,
  annualPropertyTaxes,
  buildingSqft,
  propertyType,
}: Props) => {
  const [isLoanPaymentModalOpen, setIsLoanPaymentModalOpen] = useState(false)
  const [isBuildoutCalculatorOpen, setIsBuildoutCalculatorOpen] =
    useState(false)

  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>(
    defaultPaymentDetails(propertyPrice, annualPropertyTaxes, buildingSqft),
  )

  const handleSaveBuildoutCost = (estimatedBuildoutCost: number) => {
    setPaymentDetails({
      ...paymentDetails,
      buildoutCost: estimatedBuildoutCost,
    })
  }

  const {totalCost, loanFee, monthlyLoanPayment, totalMonthlyPayment} =
    costCalculator(paymentDetails)

  return (
    <Box marginTop={8}>
      <Typography variant="h6" textAlign="center">
        Total cost
      </Typography>
      <Typography variant="h4" mb={1} fontWeight="bold" textAlign="center">
        ${formatDecimal(totalCost, {precision: 0})}
      </Typography>

      <Stack spacing={0.5}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2">Purchase price</Typography>
          <CurrencyInput
            value={paymentDetails.propertyPrice}
            onChange={(val) => {
              trackEvent('Adjust Cost Calculator', {
                purchasePrice: val,
              })
              setPaymentDetails({
                ...paymentDetails,
                propertyPrice: val,
              })
            }}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2">Buildout cost</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              onClick={() => {
                trackEvent('Open Buildout Cost Calculator')
                setIsBuildoutCalculatorOpen(true)
              }}
              variant="text"
              sx={{fontSize: '0.75rem', p: 0}}
            >
              Customize
            </Button>
            <CurrencyInput
              value={paymentDetails.buildoutCost}
              onChange={(val) => {
                trackEvent('Adjust Cost Calculator', {
                  buildoutCost: val,
                })
                setPaymentDetails({
                  ...paymentDetails,
                  buildoutCost: val,
                })
              }}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="24px"
        >
          <Typography variant="body2">Other fees</Typography>
          <Typography variant="body2" marginRight="14px">
            ${formatDecimal(loanFee, {precision: 0})}
          </Typography>
        </Stack>
      </Stack>

      <Typography variant="h6" textAlign="center" marginTop={1}>
        Monthly payment
      </Typography>
      <Typography variant="h4" mb={1} fontWeight="bold" textAlign="center">
        ${formatDecimal(totalMonthlyPayment, {precision: 0})}
      </Typography>

      <Stack direction="column" spacing={0.5}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="24px"
        >
          <Typography variant="body2">Loan payment</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              onClick={() => {
                trackEvent('Open Loan Payment Calculator')
                setIsLoanPaymentModalOpen(true)
              }}
              variant="text"
              sx={{fontSize: '0.75rem', p: 0}}
            >
              Customize
            </Button>
            <CurrencyInput
              value={monthlyLoanPayment}
              onChange={noop}
              disabled
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="24px"
        >
          <Typography variant="body2">Insurance</Typography>
          <Typography variant="body2" marginRight="14px">
            ${formatDecimal(paymentDetails.monthlyInsurance, {precision: 0})}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="24px"
        >
          <Typography variant="body2">Taxes</Typography>
          <Typography variant="body2" marginRight="14px">
            $
            {formatDecimal(paymentDetails.monthlyPropertyTaxes, {precision: 0})}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="24px"
        >
          <Typography variant="body2">Utilities</Typography>
          <Typography variant="body2" marginRight="14px">
            Not included
          </Typography>
        </Stack>
      </Stack>

      <Box marginTop={2}>
        <Typography variant="caption" color="text.secondary">
          All calculations are estimates and provided for informational purposes
          only. Actual amounts may vary.
        </Typography>
      </Box>
      {isLoanPaymentModalOpen && (
        <LoanPaymentCalculatorModal
          onClose={() => setIsLoanPaymentModalOpen(false)}
          paymentDetails={paymentDetails}
          onCalculate={(customValues: PaymentDetails) =>
            setPaymentDetails(customValues)
          }
        />
      )}

      <BuildoutCalculatorModal
        open={isBuildoutCalculatorOpen}
        buildingSqft={buildingSqft}
        propertyType={propertyType}
        handleClose={() => setIsBuildoutCalculatorOpen(false)}
        handleSaveBuildoutCost={handleSaveBuildoutCost}
      />
    </Box>
  )
}

const TERM_NUM_YEARS = 25
const LOAN_FEE_PERCENT = 0.025
const DEFAULT_LOAN_TYPE = '7a'
const BASE_INTEREST_RATE_BY_LOAN_TYPE = {'7a': 0.1, '504': 0.075}
const DEFAULT_DOWNPAYMENT_PERCENT = 0.1
const INSURANCE_COST_PSF_PER_YEAR = 2

const defaultPaymentDetails = (
  propertyPrice: number,
  annualPropertyTaxes: number,
  buildingSqFt: number,
) => {
  return {
    buildoutCost: 0,
    businessCashflow: 0,
    downpaymentPercent: DEFAULT_DOWNPAYMENT_PERCENT,
    interestRate: BASE_INTEREST_RATE_BY_LOAN_TYPE[DEFAULT_LOAN_TYPE],
    loanType: DEFAULT_LOAN_TYPE,
    monthlyPropertyTaxes: annualPropertyTaxes / 12,
    monthlyInsurance: (buildingSqFt * INSURANCE_COST_PSF_PER_YEAR) / 12,
    propertyPrice: propertyPrice,
  } as PaymentDetails
}

const interestRateAdjustment = (debtCoverage: number): number => {
  if (debtCoverage < 1) {
    return 0.01
  } else if (debtCoverage < 1.4) {
    return 0
  } else if (debtCoverage < 2) {
    return -0.005
  } else {
    return -0.01
  }
}

interface FeesAndLoanPaymentsResult {
  adjustedInterestRate: number
  loanFee: number
  monthlyLoanPayment: number
  totalCost: number
  totalMonthlyPayment: number
}

export const costCalculator = (
  paymentDetails: PaymentDetails,
): FeesAndLoanPaymentsResult => {
  const {
    buildoutCost,
    businessCashflow,
    downpaymentPercent,
    loanType,
    monthlyPropertyTaxes,
    monthlyInsurance,
    propertyPrice,
  } = paymentDetails

  const preCostPrice = propertyPrice + buildoutCost
  const loanFee = preCostPrice * LOAN_FEE_PERCENT
  const totalCost = preCostPrice + loanFee
  const loanAmount = totalCost * (1 - downpaymentPercent)

  const baseInterestRate = BASE_INTEREST_RATE_BY_LOAN_TYPE[loanType]
  const estimatedAnnualPayment = baseInterestRate * totalCost
  const debtCoverage = businessCashflow
    ? businessCashflow / estimatedAnnualPayment
    : 1
  const rateAdjustment = interestRateAdjustment(debtCoverage)
  const interestRate = baseInterestRate + rateAdjustment

  const monthlyLoanPayment = loanPayment(
    interestRate / 12,
    TERM_NUM_YEARS * 12,
    loanAmount,
  )

  const totalMonthlyPayment =
    monthlyLoanPayment + monthlyPropertyTaxes + monthlyInsurance

  return {
    adjustedInterestRate: interestRate,
    loanFee: loanFee,
    monthlyLoanPayment: monthlyLoanPayment,
    totalCost: totalCost,
    totalMonthlyPayment: totalMonthlyPayment,
  }
}

// Based off excel's PMT function
function loanPayment(rate: number, numPeriods: number, presentValue: number) {
  if (rate === 0) {
    return -presentValue / numPeriods
  }

  const pmt =
    (-presentValue * rate * Math.pow(1 + rate, numPeriods)) /
    (1 - Math.pow(1 + rate, numPeriods))
  return pmt
}

interface CurrencyInputProps {
  value: number | null
  onChange: (value: number) => void
  disabled?: boolean
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const displayValue = formatCurrency(value)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[$,]/g, '')
    const numericValue = parseFloat(rawValue)

    if (!isNaN(numericValue)) {
      onChange(numericValue)
    } else if (rawValue === '') {
      onChange(0)
    }
  }

  return (
    <TextField
      value={displayValue}
      onChange={handleChange}
      fullWidth
      disabled={disabled}
      sx={{maxWidth: '100px'}}
      InputProps={{
        inputProps: {sx: {fontSize: '0.75rem', textAlign: 'right'}},
        sx: {height: '24px'},
      }}
    />
  )
}

function formatCurrency(value: number | null): string | null {
  return (
    value?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }) || null
  )
}

export default CostCalculator
