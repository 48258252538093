import * as React from 'react'

import {Button} from '@mui/material'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'

export const useAxiosErrorHandler = (
  ignoredErrorCodes?: Array<number>,
): ((error: AxiosError, prefix?: string) => void) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar() ?? {}

  const handleError = React.useCallback(
    (error: AxiosError, prefix = '') => {
      const status = error.response?.status
      if (!status || !ignoredErrorCodes?.includes(status)) {
        enqueueSnackbar(
          `${prefix}

          ${error.response?.data?.error || error.message}`,
          {
            key: error.response?.data?.error || error.message,
            variant: 'error',
            autoHideDuration: 5000,
            preventDuplicate: true,
            style: {whiteSpace: 'pre-line'},
            action: (key) => (
              <Button
                variant="text"
                size="small"
                onClick={() => closeSnackbar(key)}
                sx={{
                  color: 'white',
                  paddingTop: 0,
                  paddingBottom: 0.5,
                  fontSize: '0.75rem',
                }}
              >
                Dismiss
              </Button>
            ),
          },
        )
      }
    },
    [closeSnackbar, enqueueSnackbar, ignoredErrorCodes],
  )

  return handleError
}
