import * as React from 'react'

import {Icon, IconProps} from '@mui/material'

interface Props extends IconProps {
  weight?: number
  opticalSize?: number
  fill?: 0 | 1
  gradient?: number
  children: string
}

// https://fonts.google.com/icons
export const MaterialSymbolIcon = ({
  weight,
  opticalSize,
  fill,
  gradient,
  children,
  ...iconProps
}: Props) => {
  const fontVariationSettings = React.useMemo(() => {
    const settings = []
    if (weight) {
      settings.push(`'wght' ${weight}`)
    }
    if (opticalSize) {
      settings.push(`'opsz' ${opticalSize}`)
    }
    if (fill !== undefined) {
      settings.push(`'FILL' ${fill}`)
    }
    if (gradient) {
      settings.push(`'GRAD' ${gradient}`)
    }

    return settings.join(', ')
  }, [fill, gradient, opticalSize, weight])

  return (
    <Icon
      className="material-symbols-outlined"
      {...iconProps}
      sx={{
        fontVariationSettings,
        ...(iconProps?.sx || {}),
      }}
    >
      {children}
    </Icon>
  )
}
