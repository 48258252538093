import React, {useState, useCallback} from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  IconButton,
  Stack,
  Button,
} from '@mui/material'

import {
  BuildingType,
  BUILDOUT_COST_TABLE,
  ImprovementCategory,
  QualityLevel,
} from 'src/components/copilot/costCalculator/CostCalculatorConfig'
import {trackEvent} from 'src/util/analytics'
import {formatCurrency} from 'src/util/format/formatCurrency'

interface LineItemProps {
  category: ImprovementCategory
  qualityLevel: QualityLevel
  cost: number
  onQualityChange: (category: string, quality: string) => void
}

const BUILDOUT_SERVICES_FEE = 0.2

const LineItem: React.FC<LineItemProps> = ({
  category,
  qualityLevel,
  cost,
  onQualityChange,
}) => {
  return (
    <Stack gap={1}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography variant="h5">{category}</Typography>
        <Typography variant="h5">
          {formatCurrency(cost.toString(), {precision: 0})}
        </Typography>
      </Stack>
      <ToggleButtonGroup
        value={qualityLevel}
        exclusive
        onChange={(_, newQuality) => onQualityChange(category, newQuality)}
        aria-label="quality level"
      >
        {['Basic', 'Mid-grade', 'High-end'].map((quality) => (
          <ToggleButton
            key={quality}
            value={quality}
            aria-label={quality}
            sx={{
              px: 1,
              py: 0.5,
              flex: 1,
              borderRadius: '16px',
              textTransform: 'none',
              '&.Mui-selected': {
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              },
            }}
          >
            <Typography variant="body2" fontWeight="400">
              {quality}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  )
}

interface Props {
  open: boolean
  buildingSqft: number
  propertyType: string
  handleClose: () => void
  handleSaveBuildoutCost: (buildoutCost: number) => void
}

const BuildoutCalculatorModal: React.FC<Props> = ({
  open,
  buildingSqft,
  propertyType,
  handleClose,
  handleSaveBuildoutCost,
}) => {
  const [lineItems, setLineItems] = useState<
    {category: ImprovementCategory; qualityLevel: QualityLevel}[]
  >(() =>
    Object.keys(BUILDOUT_COST_TABLE).map((category) => ({
      category: category as ImprovementCategory,
      qualityLevel: 'None' as QualityLevel,
    })),
  )

  const handleQualityChange = (
    category: ImprovementCategory,
    quality: QualityLevel,
  ) => {
    setLineItems((prevItems) =>
      prevItems.map((item) =>
        item.category === category ? {...item, qualityLevel: quality} : item,
      ),
    )
  }

  const getCostPerSqft = useCallback(
    (category: ImprovementCategory, quality: QualityLevel) => {
      if (quality === 'None') {
        return 0
      }

      // 1.2 multiplier for fees. Remove if adding fees separately
      return (
        (BUILDOUT_COST_TABLE[category]?.[quality]?.[
          propertyType as BuildingType
        ] || 0) * BUILDOUT_SERVICES_FEE
      )
    },
    [propertyType],
  )

  const totalCost = lineItems.reduce((sum, {category, qualityLevel}) => {
    return sum + getCostPerSqft(category, qualityLevel) * buildingSqft
  }, 0)

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="buildout-calculator-modal"
      aria-describedby="buildout-calculator-description"
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          pt: 18,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px',
            position: 'absolute',
            bgcolor: 'background.paper',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            background: 'linear-gradient(180deg, white 90%, transparent 100%)', // Gradient from solid color to transparent
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              trackEvent('Close Buildout Cost Calculator')
              handleClose()
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" component="h2" mb={2}>
            Buildout Calculator
          </Typography>
          <Typography variant="body1" mb={1}>
            Total cost of buildout
          </Typography>
          <Typography variant="h4" fontSize="2rem">
            {formatCurrency(totalCost.toString(), {precision: 0})}
          </Typography>
        </Box>
        <Stack gap={2} p={1.5}>
          {lineItems?.map(({category, qualityLevel}) => (
            <LineItem
              key={category}
              category={category}
              qualityLevel={qualityLevel}
              cost={getCostPerSqft(category, qualityLevel) * buildingSqft}
              onQualityChange={(category, quality) => {
                trackEvent('Adjust Buildout Cost Calculator', {
                  category,
                  quality,
                })
                handleQualityChange(
                  category as ImprovementCategory,
                  quality as QualityLevel,
                )
              }}
            />
          ))}
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 1,
            position: 'sticky',
            bottom: 0,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              trackEvent('Apply Buildout Cost Estimate', {
                totalCost,
                lineItems,
              })
              handleSaveBuildoutCost(totalCost)
              handleClose()
            }}
            sx={{
              backgroundColor: 'primary',
              color: 'white',
              padding: '10px 20px',
              width: '100%',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'primary',
              },
            }}
          >
            <Typography variant="h4">Apply Estimate</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default BuildoutCalculatorModal
