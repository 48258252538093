/* eslint-disable react/no-unused-prop-types */
import * as React from 'react'
import {Map, Layer, Marker, MapEvent} from 'react-map-gl'
import type {MapRef} from 'react-map-gl'

import {Box, Typography, useTheme, Stack} from '@mui/material'

import {
  OverlayControlsButton,
  TrafficLayer,
  SatelliteLayer,
  NearbyBusinessesLayer,
  GeographyOverlayRecord,
  initializeGeographyOverlayRecord,
  GeographyOverlay,
  AllOverlaysSettings,
  GeographyLegend,
} from 'src/components/copilot/mapbox/Overlays'
import {RALEIGH_CAMERA_POSITION} from 'src/components/copilot/mapbox/SearchMapV2'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {Parcel} from 'src/types/copilot'

const STYLE_URL = 'mapbox://styles/withcompany/cm1uvw6lq007r01pdegqxa02x'
const PARCEL_LAYER_ID = 'parcels'

interface Props {
  parcel: Parcel
  startingZoom?: number
  enableOverlays?: boolean
}

export const ParcelMap = ({
  parcel,
  startingZoom = 13,
  enableOverlays = true,
}: Props): JSX.Element | null => {
  const publicToken = React.useRef(
    document.querySelector<HTMLMetaElement>('meta[name="mapbox"]')?.content,
  )
  const mapRef = React.useRef<MapRef | null>(null)

  const [viewState, setViewState] = React.useState(
    parcel.parcelCentroid
      ? {
          longitude: parcel.parcelCentroid?.coordinates[0],
          latitude: parcel.parcelCentroid?.coordinates[1],
          zoom: startingZoom,
        }
      : RALEIGH_CAMERA_POSITION,
  )

  const [overlays, setOverlays] = React.useState<AllOverlaysSettings>({
    nearbyBusinesses: {active: false, categories: []},
    traffic: {active: false},
    geography: {active: false, overlay: null},
    satellite: {active: false},
  })
  const [geographyOverlayRecord, setGeographyOverlayRecord] =
    React.useState<GeographyOverlayRecord | null>(null)

  const theme = useTheme()

  const onLoad = React.useCallback(
    (event: MapEvent) => {
      if (!geographyOverlayRecord) {
        const newRecord = initializeGeographyOverlayRecord(event.target)
        setGeographyOverlayRecord(newRecord)
      }
    },
    [setGeographyOverlayRecord, geographyOverlayRecord],
  )

  const parcelPaint = React.useMemo(() => {
    return {
      'fill-color': [
        'case',
        ['==', ['get', 'cherre_parcel_id'], parcel.cherreParcelId],
        theme.palette.primary.main,
        'transparent',
      ],
    }
  }, [parcel, theme])

  return (
    <Stack direction="column" width="100%" height="100%" gap={2}>
      {enableOverlays && (
        <Stack direction="row" width="100%" gap={2} alignItems="center">
          <OverlayControlsButton
            initialOverlays={overlays}
            onChange={setOverlays}
            withNearbyBusinesses={true}
          />
          <Typography variant="button">Set overlays</Typography>
        </Stack>
      )}
      <Box height="100%" width="100%" position="relative">
        <Map
          {...viewState}
          ref={mapRef}
          initialViewState={{
            longitude: parcel.parcelCentroid?.coordinates[0],
            latitude: parcel.parcelCentroid?.coordinates[1],
            zoom: startingZoom,
          }}
          mapLib={window.mapboxgl}
          mapboxAccessToken={publicToken.current}
          mapStyle={STYLE_URL}
          fadeDuration={0}
          onLoad={onLoad}
          onMove={(event) => setViewState(event.viewState)}
          style={{height: '400px', width: '100%', position: 'relative'}}
        >
          <Layer
            key={PARCEL_LAYER_ID}
            id={PARCEL_LAYER_ID}
            type="fill"
            // @ts-expect-error TODO: wtf is wrong with this type
            paint={parcelPaint}
          />
          <ParcelMarker parcel={parcel} />
          {geographyOverlayRecord && (
            <GeographyOverlay
              overlayRecord={geographyOverlayRecord}
              overlay={overlays.geography.overlay}
            />
          )}
          <TrafficLayer active={overlays.traffic.active} mapRef={mapRef} />
          <SatelliteLayer active={overlays.satellite.active} mapRef={mapRef} />
          <NearbyBusinessesLayer
            categories={overlays.nearbyBusinesses.categories}
            active={overlays.nearbyBusinesses.active}
            parcel={parcel}
          />
        </Map>
        {overlays.geography.active && geographyOverlayRecord ? (
          <Box
            sx={{
              position: 'absolute',
              bottom: '8%',
              left: '5%',
              zIndex: 2,
              backgroundColor: '#FFFFFF',
              borderRadius: 2,
              maxWidth: '40%',
              maxHeight: '40%',
              border: '1px solid black',
            }}
          >
            <GeographyLegend
              overlay={overlays.geography.overlay}
              overlayRecord={geographyOverlayRecord}
            />
          </Box>
        ) : null}
      </Box>
    </Stack>
  )
}

const ParcelMarker = ({parcel}: {parcel: Parcel}) => {
  return (
    <Marker
      // TODO: what do if the centroid is missing?
      longitude={parcel.parcelCentroid?.coordinates[0] ?? 0}
      latitude={parcel.parcelCentroid?.coordinates[1] ?? 0}
      key={parcel.cherreParcelId}
    >
      <Box display="flex" alignItems="center">
        <MaterialSymbolIcon fontSize="large" sx={{color: '#006AFF'}}>
          pin_drop
        </MaterialSymbolIcon>
      </Box>
    </Marker>
  )
}
