import {createSlice} from '@reduxjs/toolkit'

import {Parcel} from 'src/types/copilot'

interface WatchlistParcelsState {
  items: Parcel[]
  hasBeenFetched: boolean
}

const initialState: WatchlistParcelsState = {
  items: [],
  hasBeenFetched: false,
}

export const watchlistParcels = createSlice({
  name: 'watchlistParcels',
  initialState,
  reducers: {
    setWatchlistParcels: (state, action) => {
      const payload = action.payload
      state.items = payload
      state.hasBeenFetched = true
    },
    removeWatchlistParcels: (state) => {
      state.items = []
      state.hasBeenFetched = true
    },
  },
})

export const {setWatchlistParcels, removeWatchlistParcels} =
  watchlistParcels.actions
export default watchlistParcels.reducer
