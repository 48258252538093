import * as React from 'react'

import {Close, KeyboardArrowDownOutlined} from '@mui/icons-material'
import {
  ButtonProps,
  Button,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'
import {grey} from '@mui/material/colors'
import {Stack} from '@mui/system'

import {RangeFilterInput} from 'src/components/copilot/RangeFilterInput'
import {SelectFilterInput} from 'src/components/copilot/SelectFilterInput'
import {AreaFormat, WholeDollarFormat} from 'src/framework/form/NumberFormat'
import {GrabbableMobileSwipeDrawer} from 'src/framework/ui/GrabbableMobileSwipeDrawer'

type PropertyType = 'Retail' | 'Industrial' | 'Office'

export type ParcelFilterValue = {
  priceDataType: 'property_value' | 'monthly_payment'
  monthlyPaymentLow: number | null
  monthlyPaymentHigh: number | null
  propertyValueLow: number | null
  propertyValueHigh: number | null
  areaLow: number | null
  areaHigh: number | null
  propertyTypes: Set<PropertyType> | null
}

enum FilterType {
  PropertyPrice = 'PropertyPrice',
  SquareFootage = 'SquareFootage',
  PropertyType = 'PropertyType',
}

type FilterButtonConfig = {
  type: FilterType
  active: boolean
}

const filterInputTitle: {[key in FilterType]: string} = {
  [FilterType.PropertyPrice]: 'Property Price',
  [FilterType.SquareFootage]: 'Available SF',
  [FilterType.PropertyType]: 'Property Type',
}

const filterButtonLabel: {[key in FilterType]: string} = {
  [FilterType.PropertyPrice]: 'Price',
  [FilterType.SquareFootage]: 'Available SF',
  [FilterType.PropertyType]: 'Property Type',
}

interface Props {
  onFilterChange: (filter: ParcelFilterValue | null) => void
}

const emptyFilter: ParcelFilterValue = {
  monthlyPaymentLow: null,
  monthlyPaymentHigh: null,
  propertyValueLow: null,
  propertyValueHigh: null,
  areaLow: null,
  areaHigh: null,
  priceDataType: 'property_value',
  propertyTypes: null,
}

export const ParcelFilters = ({onFilterChange}: Props): JSX.Element => {
  const [selectedFilterType, setSelectedFilterType] =
    React.useState<FilterType | null>(null)
  const [filter, setFilter] = React.useState<ParcelFilterValue>(emptyFilter)

  const [pendingFilter, setPendingFilter] =
    React.useState<ParcelFilterValue>(filter)

  const [errors, setErrors] = React.useState<string[]>([])

  const resetFilters = () => {
    setFilter(emptyFilter)
    setPendingFilter(emptyFilter)
    setErrors([])
    onFilterChange(null)
  }

  const submitFilter = (filter: ParcelFilterValue) => {
    setFilter(filter)
    setPendingFilter(filter)
    onFilterChange(filter)
  }

  const closeFilterInput = () => {
    setSelectedFilterType(null)
    setErrors([])
  }

  const clearFilter = (filterType: FilterType) => {
    const clearedFilters: Partial<ParcelFilterValue> = {}

    switch (filterType) {
      case FilterType.PropertyPrice:
        clearedFilters.priceDataType = 'property_value'
        clearedFilters.monthlyPaymentLow = null
        clearedFilters.monthlyPaymentHigh = null
        clearedFilters.propertyValueLow = null
        clearedFilters.propertyValueHigh = null
        break
      case FilterType.SquareFootage:
        clearedFilters.areaLow = null
        clearedFilters.areaHigh = null
        break
      case FilterType.PropertyType:
        clearedFilters.propertyTypes = null
        break
    }

    setPendingFilter((prev) => ({
      ...prev,
      ...clearedFilters,
    }))
  }

  const filterComponent = React.useMemo(() => {
    switch (selectedFilterType) {
      case FilterType.PropertyPrice:
        return (
          <>
            <ToggleButtonGroup
              exclusive
              fullWidth
              size="small"
              value={pendingFilter.priceDataType}
              onChange={(e, priceDataValue) => {
                setPendingFilter((prev) => ({
                  ...prev,
                  ...{
                    priceDataType: priceDataValue,
                  },
                }))
              }}
            >
              <ToggleButton value="property_value">
                <Typography variant="body2">Est. Property Value</Typography>
              </ToggleButton>
              <ToggleButton value="monthly_payment">
                <Typography variant="body2">Monthly Payment</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
            {pendingFilter.priceDataType === 'property_value' ? (
              <RangeFilterInput
                key={`property_value`}
                low={pendingFilter.propertyValueLow}
                high={pendingFilter.propertyValueHigh}
                min={0}
                max={10000000}
                sliderIncrement={10000}
                onChange={(low, high, validationErrors) => {
                  setPendingFilter((prev) => ({
                    ...prev,
                    ...{
                      propertyValueLow: low,
                      propertyValueHigh: high,
                    },
                  }))
                  setErrors(validationErrors)
                }}
                inputComponent={WholeDollarFormat}
              />
            ) : (
              <RangeFilterInput
                key={`monthly_payment`}
                low={pendingFilter.monthlyPaymentLow}
                high={pendingFilter.monthlyPaymentHigh}
                min={0}
                max={10000}
                sliderIncrement={10}
                onChange={(low, high, validationErrors) => {
                  setPendingFilter((prev) => ({
                    ...prev,
                    ...{
                      monthlyPaymentLow: low,
                      monthlyPaymentHigh: high,
                    },
                  }))
                  setErrors(validationErrors)
                }}
                inputComponent={WholeDollarFormat}
              />
            )}
          </>
        )
      case FilterType.SquareFootage:
        return (
          <RangeFilterInput
            low={pendingFilter.areaLow}
            high={pendingFilter.areaHigh}
            min={0}
            max={10000}
            sliderIncrement={100}
            onChange={(low, high) => {
              setPendingFilter((prev) => ({
                ...prev,
                ...{
                  areaLow: low,
                  areaHigh: high,
                },
              }))
            }}
            inputComponent={AreaFormat}
          />
        )
      case FilterType.PropertyType:
        return (
          <SelectFilterInput
            selections={pendingFilter.propertyTypes}
            onChange={(selections) => {
              setPendingFilter((prev) => ({
                ...prev,
                ...{
                  propertyTypes: selections,
                },
              }))
            }}
            options={[
              {
                value: 'Retail',
                label: 'Retail',
              },
              {value: 'Industrial', label: 'Industrial'},
              {value: 'Office', label: 'Office'},
            ]}
          />
        )
      default:
        return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterType, pendingFilter])

  const filterButtonConfigs: FilterButtonConfig[] = React.useMemo(() => {
    return [
      {
        type: FilterType.PropertyPrice,
        active:
          filter.propertyValueLow !== null ||
          filter.propertyValueHigh !== null ||
          filter.monthlyPaymentLow !== null ||
          filter.monthlyPaymentHigh !== null,
      },
      {
        type: FilterType.SquareFootage,
        active: filter.areaLow !== null || filter.areaHigh !== null,
      },
      {
        type: FilterType.PropertyType,
        active: filter.propertyTypes !== null,
      },
    ]
  }, [filter])

  return (
    <>
      <Stack direction="row" width="fit-content" spacing={1} pr={2}>
        <FilterButton
          onClick={resetFilters}
          endIcon={<Close fontSize="small" />}
        >
          Clear
        </FilterButton>
        {filterButtonConfigs.map(({type, active}) => (
          <FilterButton
            key={`filter-button-${type}`}
            onClick={() => setSelectedFilterType(type)}
            endIcon={<KeyboardArrowDownOutlined fontSize="small" />}
            active={active}
          >
            {filterButtonLabel[type]}
          </FilterButton>
        ))}
      </Stack>
      <GrabbableMobileSwipeDrawer
        open={selectedFilterType !== null}
        onClose={() => {
          setPendingFilter(filter)
          closeFilterInput()
        }}
        onOpen={() => null}
        exposedPuller={false}
        contentSx={{
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
        sx={{
          zIndex: 2100,
        }}
      >
        <Stack spacing={2} sx={{px: 2, pb: 2, width: '100vw'}}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Typography variant="h2">
              {selectedFilterType ? filterInputTitle[selectedFilterType] : ''}
            </Typography>
            <Button
              variant="text"
              sx={{
                fontWeight: 500,
                p: 0,
                textDecoration: 'underline',
                color: grey[700],
              }}
              onClick={() => {
                if (!selectedFilterType) return

                clearFilter(selectedFilterType)
              }}
            >
              Clear
            </Button>
          </Stack>
          {filterComponent}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              submitFilter(pendingFilter)
              closeFilterInput()
            }}
            disabled={errors.length > 0}
          >
            Done
          </Button>
        </Stack>
      </GrabbableMobileSwipeDrawer>
    </>
  )
}

interface FilterButtonProps extends ButtonProps {
  children: React.ReactNode
  active?: boolean
}

const FilterButton = ({
  active = false,
  children,
  ...buttonProps
}: FilterButtonProps) => (
  <Button
    variant="outlined"
    color="secondary"
    size="small"
    sx={{
      px: 1,
      py: 0,
      backgroundColor: (theme) =>
        active ? '#EFF2F6' : theme.palette.background.paper,
      borderColor: grey[300],
      fontWeight: 500,
      '&:hover': {
        backgroundColor: '#EFF2F6',
      },
      '.MuiButton-endIcon': {
        marginLeft: '2px',
      },
      whiteSpace: 'nowrap',
      minWidth: 'auto',
    }}
    {...buttonProps}
  >
    {children}
  </Button>
)
