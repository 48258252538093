export const formatAddress = (
  address: string | null,
  city: string | null,
  state: string | null,
  zip: string | null,
): string | null => {
  if (!address && !city && !state && !zip) return null

  if (address && city && state && zip) {
    return `${address}, ${city}, ${state} ${zip}`
  }

  return [address, city, state].filter(Boolean).join(', ')
}
