import * as React from 'react'

import {
  Box,
  CircularProgress,
  Stack,
  SvgIcon,
  Divider,
  Typography,
  Button,
} from '@mui/material'

import {ParcelHighlights} from 'src/components/copilot/ParcelHighlights'
import {ClaimYourSpaceModal} from 'src/components/copilot/claimYourSpace/ClaimYourSpaceModal'
import {PurchasingPowerSection} from 'src/components/copilot/profile/PurchasingPowerSection'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {
  plaidItemsApiCoPilotAccountPath,
  plaidLinkTokensApiCoPilotAccountPath,
  rutterConnectionsApiCoPilotAccountPath,
  signOutApiCoPilotAccountPath,
} from 'src/generated/routes'
import {useClaimedLocations} from 'src/hooks/request/useClaimedLocations'
import {useClaimedParcels} from 'src/hooks/request/useClaimedParcels'
import {useCopilotAccount} from 'src/hooks/request/useCopilotAccount'
import {useRequest} from 'src/hooks/request/useRequest'
import {usePlaid} from 'src/hooks/usePlaid'
import {useRutter} from 'src/hooks/useRutter'
import {ClaimedLocation, Parcel} from 'src/types/copilot'
import {identifyReset, trackEvent} from 'src/util/analytics'
import {ReactComponent as WithcoLogo} from 'svg/withco_logo.svg'

type OwnedLocation = {
  claimedLocation: ClaimedLocation
  claimedParcel: Parcel
}

export const CopilotProfileScreen = () => {
  const {user, refetch: refetchUser} = useCopilotAccount()

  const {request: signOut} = useRequest('GET', signOutApiCoPilotAccountPath())

  const handleSignOut = React.useCallback(async () => {
    trackEvent('Sign Out')
    identifyReset()
    await signOut()
    window.location.reload()
  }, [signOut])

  const {claimedLocations, hasBeenFetched: claimedLocationsLoaded} =
    useClaimedLocations()
  const {claimedParcels, hasBeenFetched: claimedParcelsLoaded} =
    useClaimedParcels()

  //TODO: refactor this to be less fugly
  const [claimedLocationModalObject, setClaimedLocationModalObject] =
    React.useState<OwnedLocation | null>(null)

  const portfolioReady = React.useMemo(() => {
    return claimedLocationsLoaded && claimedParcelsLoaded
  }, [claimedLocationsLoaded, claimedParcelsLoaded])

  const ownedLocations: OwnedLocation[] = React.useMemo(() => {
    return claimedLocations
      .filter((location) => location.claimedByCurrentUser)
      .map((location) => {
        return {
          claimedLocation: location,
          claimedParcel: claimedParcels.find(
            (p) => p.taxAssessorId === location.taxAssessorId,
          ) as Parcel,
        }
      })
      .filter((location) => location.claimedParcel)
  }, [claimedLocations, claimedParcels])

  const {open: openRutter, isRutterReady} = useRutter({
    platform: 'QUICKBOOKS',
    onSuccess: () => refetchUser(),
    pageLocation: 'ProfilePage',
    createRutterConnectionPath: rutterConnectionsApiCoPilotAccountPath(),
  })

  const {open: openRutterXero, isRutterReady: isRutterXeroReady} = useRutter({
    platform: 'XERO',
    onSuccess: () => refetchUser(),
    pageLocation: 'ProfilePage',
    createRutterConnectionPath: rutterConnectionsApiCoPilotAccountPath(),
  })

  const {open: openPlaid, ready: isPlaidReady} = usePlaid({
    onSuccess: refetchUser,
    createLinkTokenPath: plaidLinkTokensApiCoPilotAccountPath(),
    createItemPath: plaidItemsApiCoPilotAccountPath(),
  })

  if (!user) {
    return null
  }

  return (
    <Stack
      direction="column"
      alignItems="center"
      gap={2}
      width="100%"
      padding={2}
    >
      <SvgIcon
        component={WithcoLogo}
        inheritViewBox
        sx={{fontSize: 96}}
        color="primary"
      />
      <Stack direction="row" width="100%" gap={2}>
        <MaterialSymbolIcon fontSize="large">person</MaterialSymbolIcon>
        <Stack direction="column">
          <Typography variant="body1" fontWeight="bold">
            {user.email}
          </Typography>
          <Typography variant="body1">{user.companyName}</Typography>
        </Stack>
      </Stack>
      <Divider sx={{alignSelf: 'stretch'}} />
      <Box textAlign="left" width="100%">
        <Typography variant="h4">Portfolio</Typography>
        <Typography variant="body2">
          Add and claim spaces to unlock defensive and offensive strategies
        </Typography>
        {portfolioReady ? (
          <Stack direction="column" width="100%" gap={2} pt={2}>
            {ownedLocations.map((location, index) => (
              <PortfolioProperty
                claimedLocation={location.claimedLocation}
                key={index}
                parcel={location.claimedParcel as Parcel}
                onContinueClaim={() => setClaimedLocationModalObject(location)}
              />
            ))}
          </Stack>
        ) : (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 50,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {claimedLocationModalObject && (
          <ClaimYourSpaceModal
            claimedLocation={claimedLocationModalObject.claimedLocation}
            open
            onClose={() => setClaimedLocationModalObject(null)}
            parcel={claimedLocationModalObject.claimedParcel}
          />
        )}
      </Box>
      <Divider sx={{alignSelf: 'stretch'}} />
      <PurchasingPowerSection />
      <Divider sx={{alignSelf: 'stretch'}} />
      <Box textAlign="left" width="100%">
        <Typography variant="h4">Connections</Typography>
        <Typography variant="body2" gutterBottom>
          Add to generate real-time purchasing power and automate paperwork
        </Typography>
        <Stack spacing={1}>
          <ConnectionOption
            icon="query_stats"
            label="Quickbooks"
            connectButtonDisabled={!isRutterReady}
            connected={user.connectedQuickbooks}
            onConnect={openRutter}
          />
          <ConnectionOption
            icon="query_stats"
            label="Xero"
            connectButtonDisabled={!isRutterXeroReady}
            connected={user.connectedXero}
            onConnect={openRutterXero}
          />
          <ConnectionOption
            icon="account_balance"
            label="Bank"
            connectButtonDisabled={!isPlaidReady}
            connected={user.connectedBanking}
            onConnect={openPlaid}
          />
        </Stack>
      </Box>
      <Box mt={2}>
        <Button color="secondary" onClick={handleSignOut}>
          Sign out
        </Button>
      </Box>
    </Stack>
  )
}

interface ConnectionOptionProps {
  icon: string
  label: string
  connectButtonDisabled: boolean
  connected: boolean
  onConnect: () => void
}

const ConnectionOption = ({
  icon,
  label,
  connectButtonDisabled,
  connected,
  onConnect,
}: ConnectionOptionProps) => (
  <Stack direction="row" gap={2} alignItems="center">
    <Box
      sx={{
        borderRadius: '50%',
        border: '1px solid black',
        p: 0.5,
        display: 'flex',
      }}
    >
      <MaterialSymbolIcon fontSize="small">{icon}</MaterialSymbolIcon>
    </Box>
    <Typography variant="body1" minWidth="90px">
      {label}
    </Typography>
    {connected ? (
      <Box
        sx={{
          borderRadius: '50%',
          border: '1px solid green',
          backgroundColor: 'green',
          p: 0.5,
          display: 'flex',
        }}
      >
        <MaterialSymbolIcon fontSize="small" sx={{color: 'white'}}>
          check
        </MaterialSymbolIcon>
      </Box>
    ) : (
      <Button
        disabled={connectButtonDisabled}
        onClick={onConnect}
        sx={{
          borderRadius: '50%',
          border: '1px solid grey',
          display: 'flex',
          minWidth: 0,
          padding: 0.5,
        }}
      >
        <MaterialSymbolIcon fontSize="small" sx={{color: 'grey'}}>
          add
        </MaterialSymbolIcon>
      </Button>
    )}
  </Stack>
)

type PortfolioPropertyProps = {
  claimedLocation: ClaimedLocation
  parcel: Parcel
  onContinueClaim: () => void
}

function PortfolioProperty({
  claimedLocation,
  parcel,
  onContinueClaim,
}: PortfolioPropertyProps) {
  const {user} = useCopilotAccount()

  const claimComplete = React.useMemo(() => {
    if (!claimedLocation.leaseOrOwn) {
      return false
    }

    if (claimedLocation.leaseOrOwn === 'own') {
      return user?.connectedAccounting || user?.connectedBanking
    }

    if (claimedLocation.leaseOrOwn === 'lease') {
      return claimedLocation.hasCustomerLease
    }
  }, [claimedLocation, user])

  return (
    <Stack
      direction="row"
      width="100%"
      alignItems="center"
      gap={2}
      justifyContent="space-between"
    >
      <Stack direction="row" gap={2} alignItems="center">
        <MaterialSymbolIcon fontSize="large" sx={{paddingLeft: 0}}>
          store
        </MaterialSymbolIcon>
        <ParcelHighlights parcel={parcel} />
      </Stack>
      {claimComplete ? (
        <MaterialSymbolIcon
          fontSize="large"
          sx={{color: 'green', paddingRight: 4}}
        >
          verified_user
        </MaterialSymbolIcon>
      ) : (
        <Button onClick={onContinueClaim} sx={{minWidth: 0, padding: 0}}>
          <MaterialSymbolIcon
            fontSize="large"
            sx={{color: 'red', paddingRight: 4}}
          >
            error
          </MaterialSymbolIcon>
        </Button>
      )}
    </Stack>
  )
}
