import * as React from 'react'

import {
  ArrowDropUp,
  ArrowDropDown,
  ImageNotSupported,
} from '@mui/icons-material'
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  CardActions,
  Button,
  Skeleton,
} from '@mui/material'
import {Stack, Box} from '@mui/system'

interface Props {
  address: string
  imageUrl: string | null
  values: [string, string][]
  expanded: boolean
  onExpandToggle: () => void
}

export const PropertyCard = ({
  address,
  imageUrl,
  values,
  expanded,
  onExpandToggle,
}: Props): JSX.Element => {
  return (
    <Card sx={{borderRadius: 2}}>
      {imageUrl ? (
        <CardMedia
          component="img"
          image={imageUrl}
          sx={{height: 150, objectFit: 'cover'}}
        />
      ) : (
        <Box p={1}>
          <Box
            sx={{
              width: '100%',
              height: 150,
              backgroundColor: 'grey.100',
              position: 'relative',
            }}
          >
            <ImageNotSupported
              fontSize="large"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              color="disabled"
            />
          </Box>
        </Box>
      )}
      <CardContent sx={{p: 1}}>
        <Stack>
          <Box mb={1}>
            <Typography fontWeight={700}>{address}</Typography>
          </Box>
          <Table size="small">
            <TableBody>
              {values.slice(0, 3).map(([label, value]) => (
                <TableRow key={label}>
                  <TableCell
                    align="left"
                    sx={{px: 0, py: 0.5, fontWeight: 500}}
                  >
                    {label}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      px: 0,
                      py: 0.5,
                      fontWeight: 500,
                      textTransform: 'capitalize',
                    }}
                  >
                    {value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {values.length > 3 && (
            <>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Table size="small">
                  <TableBody>
                    {values.slice(3).map(([label, value]) => (
                      <TableRow key={label}>
                        <TableCell
                          align="left"
                          sx={{px: 0, py: 0.5, fontWeight: 500}}
                        >
                          {label}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            px: 0,
                            py: 0.5,
                            fontWeight: 500,
                            textTransform: 'capitalize',
                          }}
                        >
                          {value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Collapse>
            </>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          variant="text"
          size="small"
          color="primary"
          onClick={onExpandToggle}
          startIcon={expanded ? <ArrowDropUp /> : <ArrowDropDown />}
          sx={{alignSelf: 'flex-start', p: 0}}
          disableRipple
        >
          {expanded ? 'Show Less' : 'Show More'}
        </Button>
      </CardActions>
    </Card>
  )
}

export const LoadingCard = (): JSX.Element => (
  <Card
    sx={{
      borderRadius: 2,
    }}
  >
    <CardContent sx={{p: 1}}>
      <Skeleton variant="rectangular" width="100%" height="150px" />
    </CardContent>
    <CardContent sx={{p: 1}}>
      <Stack spacing={1.5}>
        <Skeleton variant="rectangular" width="50%" height="16px" />
        <Skeleton variant="rectangular" width="90%" height="16px" />
        <Skeleton variant="rectangular" width="90%" height="16px" />
        <Skeleton variant="rectangular" width="90%" height="16px" />
      </Stack>
    </CardContent>
    <CardActions>
      <Skeleton variant="rectangular" width="25%" height="16px" />
    </CardActions>
  </Card>
)
