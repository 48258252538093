import * as React from 'react'

import {Drawer, IconButton, Typography} from '@mui/material'
import {Box, Stack} from '@mui/system'
import _ from 'lodash'

import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {ProgressBox} from 'src/framework/ui/ProgressBox'
import {genericApiCoPilotParcelsPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {Parcel} from 'src/types/copilot'

interface Props {
  open: boolean
  onBack: () => void
  parcel: Parcel
}

export const ParcelFeedDrawer = ({
  open,
  onBack,
  parcel,
}: Props): JSX.Element => {
  return (
    <Drawer
      open={open}
      anchor="right"
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
        },
      }}
      sx={{
        height: ['100dvh', '100vh'],
        width: ['100dvw', '100vw'],
        zIndex: 900,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        height="100%"
      >
        <Box flex="1 0">
          {open && (
            <ParcelFeed onBack={onBack} taxAssessorId={parcel.taxAssessorId} />
          )}
        </Box>
      </Box>
    </Drawer>
  )
}

interface ParcelFeedProps {
  onBack: () => void
  taxAssessorId: string
}

export const ParcelFeed = ({
  onBack,
  taxAssessorId,
}: ParcelFeedProps): JSX.Element => {
  const {
    request: fetchFeed,
    response: feedResponse,
    completed: fetchFeedCompleted,
  } = useRequest<
    {
      present: string
      historicalEvents: {
        date: string
        description: string
      }[]
    },
    void,
    {endpoint: string; queryParams: {taxAssessorId: string}}
  >('GET', genericApiCoPilotParcelsPath())

  const groupedHistoricalEvents = React.useMemo(
    () =>
      _.chain(feedResponse?.historicalEvents)
        .groupBy((event) => {
          return event.date.slice(0, 4)
        })
        .toPairs()
        .sortBy(([year]) => year)
        .value(),
    [feedResponse],
  )

  const presentParagraphs = React.useMemo(() => {
    return feedResponse
      ? feedResponse.present
          .replace(/\\"/g, '"')
          .replace(/\*\*/g, '')
          .split('\\n')
      : []
  }, [feedResponse])

  React.useEffect(() => {
    if (!fetchFeedCompleted) {
      fetchFeed({
        params: {
          endpoint: '/feed',
          queryParams: {
            taxAssessorId: taxAssessorId,
          },
        },
      })
    }
  }, [fetchFeed, fetchFeedCompleted, taxAssessorId])

  return (
    <>
      <Box height="100%" display="flex" flexDirection="column">
        <Box
          flex="1 0"
          overflow="auto"
          width="100%"
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Box position="relative">
            <Box
              position="absolute"
              sx={{
                left: 8,
                top: 8,
                zIndex: 10,
                backgroundColor: 'rgba(255, 255,255, .9)',
                borderRadius: '50%',
              }}
            >
              <IconButton
                color={'secondary'}
                sx={{backgroundColor: '#fff', padding: 1}}
                onClick={onBack}
              >
                <MaterialSymbolIcon sx={{fontSize: 20}}>
                  arrow_back
                </MaterialSymbolIcon>
              </IconButton>
            </Box>
          </Box>
          <Box px={2} pt={4}>
            <Typography variant="h4" color="#000" textAlign="center">
              Feed
            </Typography>
            <ProgressBox minHeight="200px" loading={!feedResponse}>
              {feedResponse && (
                <>
                  <Typography variant="h5" pt={2}>
                    Current
                  </Typography>
                  {presentParagraphs.map((paragraph, index) => (
                    <Typography variant="body2" key={index} pt={0.5}>
                      {paragraph}
                    </Typography>
                  ))}
                  <Typography variant="h5" pt={2}>
                    Historical
                  </Typography>
                  {groupedHistoricalEvents.map(([year, events], index) => (
                    <Stack direction="row" key={index} spacing={2} pt={0.25}>
                      <Typography
                        variant="body2"
                        fontWeight="700"
                        minWidth="40px"
                      >
                        {year}
                      </Typography>
                      <Stack spacing={0.5}>
                        {events.map((event, index) => (
                          <Typography key={index} variant="body2">
                            {event.description}
                          </Typography>
                        ))}
                      </Stack>
                    </Stack>
                  ))}
                </>
              )}
            </ProgressBox>
          </Box>
        </Box>
      </Box>
    </>
  )
}
