import * as React from 'react'
import {Map, Source, Layer, Marker} from 'react-map-gl'
import type {
  LayerProps,
  ViewStateChangeEvent,
  MapEvent,
  MapMouseEvent,
  MapRef,
  LngLatBoundsLike,
} from 'react-map-gl'

import {FortOutlined, PersonPinCircleOutlined} from '@mui/icons-material'
import {
  Box,
  BoxProps,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import {bboxPolygon} from '@turf/bbox-polygon'
import {booleanContains} from '@turf/boolean-contains'
import {GeoJSONSource} from 'mapbox-gl'

import {Filter} from 'src/components/copilot/AdvancedMapFilters'
import {
  GeographyOverlay,
  GeographyOverlayRecord,
  GeographyLegend,
  initializeGeographyOverlayRecord,
  AllOverlaysSettings,
  TrafficLayer,
  SatelliteLayer,
} from 'src/components/copilot/mapbox/Overlays'
import {apiCoPilotParcelsFilterPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {ClaimedLocation} from 'src/types/copilot'

const STYLE_URL = 'mapbox://styles/withcompany/cm1uvw6lq007r01pdegqxa02x'
const PARCEL_LAYER_ID = 'parcels'
const ZIPCODE_LAYER_ID = 'zipcodes'
export const RALEIGH_CAMERA_POSITION = {
  latitude: 35.7741,
  longitude: -78.6396,
  zoom: 12,
}

type CameraPosition = {
  latitude: number
  longitude: number
  zoom: number
}

type CameraBbox = LngLatBoundsLike

export type CameraSetting =
  | {
      type: 'bbox'
      bbox: CameraBbox
    }
  | {type: 'position'; position: CameraPosition}

export type ClaimedLocationMapParcel = {
  parcel: MapParcel
  claimedLocation: ClaimedLocation
}

export type MapParcel = {
  parcelCentroid?: GeoJSON.Point | null
  cherreParcelId: string | null
}

type LatLong = {
  lat: number
  long: number
}

type BoundingBox = {
  topRight: LatLong
  bottomLeft: LatLong
}

type SourceRequestPayload = {filter: Filter & {boundingBox: BoundingBox}}

function boundsToBox(
  box: Exclude<ReturnType<mapboxgl.Map['getBounds']>, null>,
): BoundingBox {
  const bottomLeft = box.getSouthWest()
  const topRight = box.getNorthEast()

  return {
    topRight: {
      lat: topRight.lat,
      long: topRight.lng,
    },
    bottomLeft: {
      lat: bottomLeft.lat,
      long: bottomLeft.lng,
    },
  }
}

function getSourcePayload(
  box: Exclude<ReturnType<mapboxgl.Map['getBounds']>, null>,
  filter: Filter,
): SourceRequestPayload {
  const boundingBox = boundsToBox(box)
  return {
    filter: {
      ...filter,
      boundingBox,
    },
  }
}

function payloadToSourceId(payload: SourceRequestPayload): string {
  return JSON.stringify(payload)
}

function sourceIdToPayload(sourceId: string): SourceRequestPayload {
  return JSON.parse(sourceId) as SourceRequestPayload
}

function clusterLayer(sourceId: string, color: string): LayerProps {
  return {
    id: `${sourceId}-clusters`,
    type: 'circle',
    source: sourceId,
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': '#FFFFFF',
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      'circle-stroke-color': color,
      'circle-stroke-width': 2,
      'circle-opacity': 1,
      'circle-stroke-opacity': 1,
    },
  }
}

function countsLayer(sourceId: string, color: string): LayerProps {
  return {
    id: `${sourceId}-counts`,
    type: 'symbol',
    source: sourceId,
    filter: ['has', 'point_count'],
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
    paint: {
      'text-color': color,
      'text-opacity': 1,
    },
  }
}

function unclusteredLayer(sourceId: string, color: string): LayerProps {
  return {
    id: `${sourceId}-unclustered`,
    type: 'symbol',
    source: sourceId,
    filter: ['!', ['has', 'point_count']],
    layout: {
      // Icon (pill) settings
      'icon-image': 'pill-background',
      'icon-size': 1,
      'icon-allow-overlap': true,
      'icon-text-fit': 'both', // Make text fit within icon bounds
      'icon-text-fit-padding': [5, 10, 5, 10], // Add some padding inside the pill
      'icon-anchor': 'center',
      'icon-ignore-placement': true, // Prevent icons from being hidden

      // Text settings
      'text-field': [
        'case',
        ['>=', ['to-number', ['get', 'salesPrice']], 1000000],
        [
          'concat',
          '$',
          [
            'number-format',
            ['/', ['round', ['to-number', ['get', 'salesPrice']]], 1000000],
            {
              'min-fraction-digits': 0,
              'max-fraction-digits': 1,
            },
          ],
          'M',
        ],
        ['>=', ['to-number', ['get', 'salesPrice']], 1000],
        [
          'concat',
          '$',
          [
            'number-format',
            [
              '/',
              [
                '*',
                ['round', ['/', ['to-number', ['get', 'salesPrice']], 1000]],
                1000,
              ],
              1000,
            ],
            {
              'min-fraction-digits': 0,
              'max-fraction-digits': 0,
            },
          ],
          'K',
        ],
        [
          'concat',
          '$',
          [
            'number-format',
            ['round', ['to-number', ['get', 'salesPrice']]],
            {
              'min-fraction-digits': 0,
              'max-fraction-digits': 0,
            },
          ],
        ],
      ],
      'text-size': 11,
      'text-justify': 'center',
      'text-anchor': 'center',
      'text-allow-overlap': false,
      'text-ignore-placement': false,
      'text-optional': false,
      'text-padding': 0,

      // Symbol placement settings
      'symbol-placement': 'point',
      'symbol-sort-key': ['get', 'salesPrice'], // Higher prices on top
      'symbol-z-order': 'source',
    },
    paint: {
      'text-color': color,
    },
  }
}

interface Props extends BoxProps {
  defaultCameraPosition?: CameraPosition
  cameraPosition?: CameraSetting
  selectedParcelId: string | null | undefined
  claimedParcels?: ClaimedLocationMapParcel[]
  filter: Filter
  onParcelSelected: (parcelId: string | null) => void
  isVisible: boolean
  overlays: AllOverlaysSettings
}

export function MapBoxMapV2({
  defaultCameraPosition = RALEIGH_CAMERA_POSITION,
  cameraPosition,
  selectedParcelId,
  claimedParcels,
  filter,
  onParcelSelected,
  isVisible,
  overlays,
}: Props) {
  const publicToken = React.useRef(
    document.querySelector<HTMLMetaElement>('meta[name="mapbox"]')?.content,
  )
  const mapRef = React.useRef<MapRef | null>(null)

  const [viewState, setViewState] = React.useState(defaultCameraPosition)
  const [source, setSource] = React.useState<string | null>(null)
  const [geographyOverlayRecord, setGeographyOverlayRecord] =
    React.useState<GeographyOverlayRecord | null>(null)

  const theme = useTheme()

  const {
    request: fetchSource,
    response: data,
    loading,
    // TODO: handle data errors more gracefully
  } = useRequest<GeoJSON.FeatureCollection, SourceRequestPayload>(
    'POST',
    apiCoPilotParcelsFilterPath(),
  )

  // TODO: applying filter can cause memory leak (updating state of unmounted)
  /**
   * triggers:
   *   - first filter application
   *   - share_id=xxxx applying filter that removed current parcel from result
   */
  const updateSource = React.useCallback(
    (sourceId: string, payload: SourceRequestPayload) => {
      // completely unmount the existing source before fetching new data
      setSource(null)
      fetchSource({data: payload}).then(() => setSource(sourceId))
    },
    [setSource, fetchSource],
  )

  /**
   * Avoid using useEffect and mapRef as much as possible. Default to using
   * explicit react state to trigger re-renders in react-map-gl components.
   * Only edge cases like calling map methods like flyTo should be pulled out
   * into useEffect for "uncontrolled" use
   */

  // update source data on filter change
  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }
    const map = mapRef.current
    const box = map.getBounds()
    if (!box) {
      console.log('Error: No bounding box on fetching data on filter change')
      return
    }
    const payload = getSourcePayload(box, filter)
    const sourceId = payloadToSourceId(payload)
    updateSource(sourceId, payload)
  }, [filter, updateSource])

  // Fly to parcel location when one is manually searched for
  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }
    const map = mapRef.current
    if (cameraPosition) {
      if (cameraPosition.type === 'position') {
        map.flyTo({
          center: [
            cameraPosition.position.longitude,
            cameraPosition.position.latitude,
          ],
          zoom: cameraPosition.position.zoom,
        })
      } else if (cameraPosition.type === 'bbox') {
        map.fitBounds(cameraPosition.bbox)
      }
    }
  }, [cameraPosition])

  // Resize when nav'd to in case parent container sizes changed
  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }
    if (isVisible) {
      mapRef.current.resize()
    }
  }, [isVisible])

  const onLoad = (event: MapEvent) => {
    const map = event.target
    map.on('mouseenter', PARCEL_LAYER_ID, () => {
      map.getCanvas().style.cursor = 'pointer'
    })

    map.on('mouseleave', PARCEL_LAYER_ID, () => {
      map.getCanvas().style.cursor = ''
    })
    if (!map.hasImage('pill-background')) {
      // TODO: can probably turn this into a proper react component
      const pill = new Image()
      pill.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
        <svg width="60" height="22" xmlns="http://www.w3.org/2000/svg">
          <rect x="1" y="1" width="58" height="20" rx="14"
                fill="#FFFFFF"
                stroke="${theme.palette.primary.main}"
                stroke-width="2"/>
        </svg>
      `)}`
      pill.onload = () => {
        map.addImage('pill-background', pill)
      }
    }
    // construct overlay color schemes
    if (geographyOverlayRecord === null) {
      const newRecord = initializeGeographyOverlayRecord(map)
      setGeographyOverlayRecord(newRecord)
    }
    // initialize first set of data
    const box = map.getBounds()
    if (!box) {
      console.log('Error: No bounding box on load')
      return
    }
    const payload = getSourcePayload(box, filter)
    const sourceId = payloadToSourceId(payload)
    setSource(sourceId)
    fetchSource({data: payload})
  }

  const onClick = React.useCallback(
    (event: MapMouseEvent) => {
      const map = event.target
      if (!event.features || event.features.length === 0) {
        // click on nothing revelant resets parcel selection
        onParcelSelected(null)
        return
      }

      const relevantFeatures = event.features.filter(
        (f) => !f.layer?.id.endsWith('-counts'),
      )

      const parcelSelected = relevantFeatures.filter(
        (f) => f.layer?.id === PARCEL_LAYER_ID,
      )[0]
      const pointSelected = relevantFeatures.filter((f) =>
        f.layer?.id.endsWith('-unclustered'),
      )[0]
      const clusterSelected = relevantFeatures.filter((f) =>
        f.layer?.id.endsWith('-clusters'),
      )[0]

      if (pointSelected || parcelSelected) {
        const feature = pointSelected ?? parcelSelected
        onParcelSelected(
          // parcels use snake_case but points use camelCase
          feature.properties?.cherre_parcel_id ??
            feature.properties?.cherreParcelId,
        )
        return
      }

      if (clusterSelected) {
        const feature = clusterSelected
        const mapboxSource = map.getSource<GeoJSONSource>(feature.source)

        if (!mapboxSource) {
          console.log('Error: No source for clicked feature')
          return
        }

        const clusterId = feature.properties?.cluster_id
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            console.log('Error in getting cluster zoom expansion')
            return
          }

          map.easeTo({
            // TODO: types here is whack
            center: (
              feature.geometry as Exclude<
                GeoJSON.Geometry,
                GeoJSON.GeometryCollection
              >
            ).coordinates as [number, number],
            zoom: zoom ?? undefined,
            duration: 500,
          })
        })
        return
      }

      // click on nothing revelant resets parcel selection
      onParcelSelected(null)
    },
    [onParcelSelected],
  )

  const onMouseEnter = (event: MapMouseEvent) => {
    const map = event.target
    if (!event.features || event.features.length === 0) {
      return
    }

    const relevantFeatures = event.features.filter(
      (f) =>
        f.layer?.id.endsWith('-clusters') ||
        f.layer?.id.endsWith('-unclustered'),
    )

    if (relevantFeatures.length > 0) {
      map.getCanvas().style.cursor = 'pointer'
    }
  }

  const onMouseLeave = (event: MapMouseEvent) => {
    const map = event.target
    if (!event.features || event.features.length === 0) {
      return
    }

    const relevantFeatures = event.features.filter((f) =>
      f.layer?.id.endsWith('-clusters'),
    )

    if (relevantFeatures.length > 0) {
      map.getCanvas().style.cursor = ''
    }
  }

  const onMoveEnd = React.useCallback(
    (event: ViewStateChangeEvent) => {
      const map = event.target
      // do nothing if no data is yet loaded
      if (!source) {
        return
      }

      // update loaded parcels (if needed)
      const bounds = map.getBounds()
      if (!bounds) {
        console.log(
          'Error: Map has no bounds when checking if a new source is needed',
        )
        return
      }
      if (source) {
        const rawBoundingBox = sourceIdToPayload(source).filter.boundingBox
        const lastBoundingBox = bboxPolygon([
          rawBoundingBox.bottomLeft.long,
          rawBoundingBox.bottomLeft.lat,
          rawBoundingBox.topRight.long,
          rawBoundingBox.topRight.lat,
        ])

        const currentBoundingBox = bboxPolygon([
          bounds.getSouthWest().lng,
          bounds.getSouthWest().lat,
          bounds.getNorthEast().lng,
          bounds.getNorthEast().lat,
        ])

        if (booleanContains(lastBoundingBox, currentBoundingBox)) {
          // we have all the data we need
          return
        }
      }
      // TODO: show a "reload map" button instead of automatically reloading data
      const newPayload = getSourcePayload(bounds, filter)
      const sourceId = payloadToSourceId(newPayload)
      updateSource(sourceId, newPayload)
    },
    [source, updateSource, filter],
  )

  const parcelPaint = React.useMemo(() => {
    const isHighlightedParcelClaimed = claimedParcels?.some(
      (p) => p.parcel.cherreParcelId === selectedParcelId,
    )
    return {
      'fill-color': [
        'case',
        [
          '==',
          ['get', 'cherre_parcel_id'],
          isHighlightedParcelClaimed ? selectedParcelId ?? null : null,
        ],
        '#D7EFFE',
        ['==', ['get', 'cherre_parcel_id'], selectedParcelId ?? null],
        theme.palette.primary.main,
        'transparent',
      ],
    }
  }, [claimedParcels, selectedParcelId, theme])

  const {zipcodePaint, zipcodeLayout} = React.useMemo(() => {
    const zipsToShow = filter.postalCodeList
    if (zipsToShow === undefined || zipsToShow.length === 0) {
      return {zipcodePaint: {}, zipcodeLayout: {visibility: 'none'} as const}
    }
    console.log('zip visible for', zipsToShow)
    return {
      zipcodePaint: {
        'line-opacity': ['match', ['get', 'ZCTA5CE20'], zipsToShow, 1, 0],
      },
      zipcodeLayout: {visibility: 'visible'} as const,
    }
  }, [filter])

  const interactiveLayers = React.useMemo(() => {
    return source && data
      ? [
          PARCEL_LAYER_ID,
          `${source}-clusters`,
          `${source}-counts`,
          `${source}-unclustered`,
        ]
      : [PARCEL_LAYER_ID]
  }, [source, data])

  return (
    <Box height="100%" width="100%" position="relative">
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 50,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Map
        {...viewState}
        ref={mapRef}
        initialViewState={defaultCameraPosition}
        mapLib={window.mapboxgl}
        mapboxAccessToken={publicToken.current}
        mapStyle={STYLE_URL}
        fadeDuration={0}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMove={(event) => setViewState(event.viewState)}
        onMoveEnd={onMoveEnd}
        onLoad={onLoad}
        interactiveLayerIds={interactiveLayers}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Layer
          key={PARCEL_LAYER_ID}
          id={PARCEL_LAYER_ID}
          type="fill"
          // @ts-expect-error TODO: wtf is wrong with this type
          paint={parcelPaint}
        />
        <Layer
          key={ZIPCODE_LAYER_ID}
          id={ZIPCODE_LAYER_ID}
          type="line"
          layout={zipcodeLayout}
          // @ts-expect-error TODO: wtf is wrong with this type
          paint={zipcodePaint}
        />
        {geographyOverlayRecord && (
          <GeographyOverlay
            overlay={overlays.geography.overlay}
            overlayRecord={geographyOverlayRecord}
          />
        )}
        <TrafficLayer active={overlays.traffic.active} mapRef={mapRef} />
        <SatelliteLayer active={overlays.satellite.active} mapRef={mapRef} />
        {claimedParcels &&
          claimedParcels
            .filter((p) => !!p.parcel.parcelCentroid)
            .map((claimedParcel, index) => (
              <Marker
                key={index}
                longitude={
                  claimedParcel.parcel.parcelCentroid?.coordinates[0] ?? 0
                }
                latitude={
                  claimedParcel.parcel.parcelCentroid?.coordinates[1] ?? 0
                }
                onClick={() =>
                  onParcelSelected(claimedParcel.parcel.cherreParcelId)
                }
              >
                <ClaimedParcelMarker
                  claimedLocation={claimedParcel.claimedLocation}
                />
              </Marker>
            ))}
        {source && data ? (
          <Source
            key={source}
            id={source}
            type="geojson"
            data={data}
            cluster={true}
            clusterMaxZoom={13}
            clusterRadius={50}
          >
            <Layer
              key={`${source}-clusters`}
              {...clusterLayer(source, theme.palette.primary.main)}
            ></Layer>
            <Layer
              key={`${source}-counts`}
              {...countsLayer(source, theme.palette.primary.main)}
            ></Layer>
            <Layer
              key={`${source}-unclustered`}
              {...unclusteredLayer(source, theme.palette.primary.main)}
            ></Layer>
          </Source>
        ) : null}
      </Map>
      {overlays.geography.active && geographyOverlayRecord ? (
        <Box
          sx={{
            position: 'absolute',
            bottom: '4%',
            left: '3%',
            zIndex: 2,
            backgroundColor: '#FFFFFF',
            border: '2px solid black',
          }}
        >
          <GeographyLegend
            overlay={overlays.geography.overlay}
            overlayRecord={geographyOverlayRecord}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export const ClaimedParcelMarker = ({
  claimedLocation,
}: {
  claimedLocation: ClaimedLocation
}) => {
  return (
    <Box display="flex" alignItems="center">
      {claimedLocation.owner ? (
        <FortOutlined sx={{color: '#000'}} />
      ) : (
        <PersonPinCircleOutlined sx={{color: '#006AFF'}} />
      )}
      <Typography
        sx={{
          color: claimedLocation.owner ? '#000' : '#006AFF',
          fontFamily: "'Inter', sans-serif",
          fontSize: '0.875rem',
          fontWeight: 500,
        }}
      >
        {claimedLocation.businessName}
      </Typography>
    </Box>
  )
}
