import * as React from 'react'
import {
  PlaidLinkOnEventMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link'

import {camelCase, chain} from 'lodash'
import {useSnackbar} from 'notistack'

import {
  apiPartnersPlaidLinkTokensPath,
  apiPartnersPlaidItemsPath,
  apiPartnersPlaidEventsPath,
} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {trackEvent} from 'src/util/analytics'

type LinkToken = {
  linkToken: string
}

type PublicTokenPayload = {
  publicToken: string
  metadata: PlaidLinkOnSuccessMetadata
}

type PlaidEventPayload = {
  eventName: PlaidLinkStableEvent | string
  metadata: PlaidLinkOnEventMetadata
}

interface Props {
  onSuccess: () => void
  onExit?: () => void
  reinitialize?: boolean
  showToast?: boolean
  createLinkTokenPath?: string
  createItemPath?: string
}

type UsePlaidResults = {
  open: () => void
  ready: boolean
}

const SESSION_STORAGE_KEY = 'plToken'

export const usePlaid = ({
  onSuccess,
  onExit,
  reinitialize = false,
  showToast = true,
  createLinkTokenPath = apiPartnersPlaidLinkTokensPath(),
  createItemPath = apiPartnersPlaidItemsPath(),
}: Props): UsePlaidResults => {
  const {enqueueSnackbar} = useSnackbar()

  const {request: createLinkToken} = useRequest<LinkToken>(
    'POST',
    createLinkTokenPath,
  )
  const {request: createAccessToken} = useRequest<void, PublicTokenPayload>(
    'POST',
    createItemPath,
  )
  const {request: sendPlaidEvent} = useRequest<void, PlaidEventPayload>(
    'POST',
    apiPartnersPlaidEventsPath(),
    {
      disableDefaultErrorHandling: true,
      raise: false,
    },
  )

  const tokenFromSessionStorage = sessionStorage.getItem(SESSION_STORAGE_KEY)
  const [linkToken, setLinkToken] = React.useState<string | null>(
    reinitialize ? tokenFromSessionStorage : null,
  )

  const onPlaidLinkSuccess = React.useCallback(
    (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      createAccessToken({
        data: {publicToken, metadata},
      })
        .then(() => {
          if (showToast) {
            enqueueSnackbar('Successfully linked bank account', {
              variant: 'success',
            })
          }
          onSuccess()
        })
        .finally(() => {
          trackEvent('Complete Plaid Connection')
          sessionStorage.removeItem(SESSION_STORAGE_KEY)
        })
    },
    [createAccessToken, enqueueSnackbar, onSuccess, showToast],
  )

  const onEvent = React.useCallback(
    (
      eventName: PlaidLinkStableEvent | string,
      metadata: PlaidLinkOnEventMetadata,
    ) => {
      const metadataProperties = camelCaseKeys(metadata)

      switch (eventName) {
        case 'OPEN':
          trackEvent('Start Plaid Connection', metadataProperties)
          break
        case 'ERROR':
        case 'FAIL_OAUTH':
        case 'SELECT_DEGRADED_INSTITUTION':
        case 'SELECT_DOWN_INSTITUTION':
          trackEvent('Plaid Connection Error', metadataProperties)
          sendPlaidEvent({data: {eventName, metadata}})
          break
        case 'EXIT':
        case 'CLOSE_OAUTH':
          trackEvent('Exit Plaid Connection', metadataProperties)
          break
        default:
          trackEvent('Plaid Connection Flow Event', {
            eventName,
            ...metadataProperties,
          })
          break
      }
    },
    [sendPlaidEvent],
  )

  const {open: openPlaidLinkForm, ready} = usePlaidLink({
    token: linkToken,
    onSuccess: onPlaidLinkSuccess,
    onEvent: onEvent,
    onExit: () => {
      onExit && onExit()
    },
    receivedRedirectUri: reinitialize ? window.location.href : undefined,
  })

  const open = React.useCallback(() => {
    openPlaidLinkForm()
  }, [openPlaidLinkForm])

  React.useEffect(() => {
    if (!linkToken) {
      createLinkToken().then((result) => {
        if (typeof result != 'object') {
          return
        }
        setLinkToken(result.linkToken)
        sessionStorage.setItem(SESSION_STORAGE_KEY, result.linkToken)
      })
    }
  }, [createLinkToken, linkToken])

  React.useEffect(() => {
    if (reinitialize && ready) {
      trackEvent('Reinitializing Plaid Connection')
      openPlaidLinkForm()
    }
  }, [openPlaidLinkForm, ready, reinitialize])

  return {
    open,
    ready,
  }
}

const camelCaseKeys = (obj: object): object => {
  return chain(obj)
    .transform((result: {[key: string]: unknown}, value, key) => {
      const camelKey = camelCase(key)
      result[camelKey] = value
    }, {})
    .value()
}
