import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {Modal, Box, IconButton, Stack, Typography} from '@mui/material'
import {grey} from '@mui/material/colors'

import {PlaidButton} from 'src/components/deal/PlaidButton'
import {RutterButton} from 'src/components/partners/RutterButton'
import {
  rutterConnectionsApiCoPilotAccountPath,
  plaidLinkTokensApiCoPilotAccountPath,
  plaidItemsApiCoPilotAccountPath,
} from 'src/generated/routes'
import {useCopilotAccount} from 'src/hooks/request/useCopilotAccount'
import {trackEvent} from 'src/util/analytics'

interface Props {
  open: boolean
  onClose: () => void
}

export const PurchasingPowerModal = ({open, onClose}: Props) => {
  const {user, refetch: refetchUser} = useCopilotAccount()

  const onConnectionSuccess = React.useCallback(() => {
    refetchUser()
  }, [refetchUser])

  return (
    <Modal open={open} onClose={onClose}>
      <Box height="100%" width="100%" bgcolor="background.paper">
        <IconButton
          onClick={() => {
            trackEvent('Close Purchasing Power Modal')
            onClose()
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: grey[500],
            zIndex: 51,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Stack pt={6} px={2}>
          <Typography variant="h4" textAlign="center">
            Play offense
          </Typography>
          <Typography variant="h5" mt={4}>
            Purchasing Power
          </Typography>
          <Typography variant="h4" mt={2}>
            $ _ , _ _ _ , _ _ _
          </Typography>
          {user && (user.connectedAccounting || user.connectedBanking) ? (
            <Typography variant="body1" mt={2}>
              Thanks for connecting your financials. The purchasing power figure
              above is updated based on our evaluation of your financials.
            </Typography>
          ) : (
            <Stack spacing={2} mt={2}>
              <Typography variant="body1">
                The best defense is a strong offense. Connect your financials to
                understand how much property your business can afford.
              </Typography>
              <RutterButton
                onSuccess={onConnectionSuccess}
                defaultButtonText={'Connect QuickBooks'}
                platform="QUICKBOOKS"
                pageLocation="copilotMakeAnOffer"
                createRutterConnectionPath={rutterConnectionsApiCoPilotAccountPath()}
                sx={{width: '100%'}}
                buttonProps={{fullWidth: true}}
              />
              <PlaidButton
                onSuccessCallback={onConnectionSuccess}
                buttonText="Connect Bank Account"
                buttonProps={{variant: 'outlined'}}
                createLinkTokenPath={plaidLinkTokensApiCoPilotAccountPath()}
                createItemPath={plaidItemsApiCoPilotAccountPath()}
              />
            </Stack>
          )}
        </Stack>
      </Box>
    </Modal>
  )
}
