import * as React from 'react'
import Carousel from 'react-material-ui-carousel'

import {ImageNotSupported} from '@mui/icons-material'
import {
  Card,
  CardMedia,
  CardContent,
  Skeleton,
  CardActions,
  Drawer,
  DrawerProps,
} from '@mui/material'
import {Box, Stack} from '@mui/system'

import {AdmittedUserContainer} from 'src/components/copilot/AdmittedUserContainer'
import {ParcelHighlights} from 'src/components/copilot/ParcelHighlights'
import {WatchlistButton} from 'src/components/copilot/WatchlistButton'
import {useToggle} from 'src/hooks/util/useToggle'
import {Parcel} from 'src/types/copilot'
import {trackEvent} from 'src/util/analytics'

type DrawerContentProps = {
  onBack: () => void
  isOnWatchlist: boolean
  taxAssessorId: string
}

interface Props {
  parcel: Parcel
  isOnWatchlist?: boolean
  disableActions?: boolean
  DrawerContent?: React.ComponentType<DrawerContentProps>
}

export const ParcelCard = ({
  parcel,
  isOnWatchlist = false,
  disableActions = false,
  DrawerContent,
}: Props): JSX.Element => {
  const [parcelDrawerOpen, openParcelDrawer, closeParcelDrawer] =
    useToggle(false)

  const handleOpenParcelDrawer = React.useCallback(() => {
    if (disableActions) return

    trackEvent('View Property Details', {
      propertyId: parcel.taxAssessorId,
      address: parcel.address,
    })
    openParcelDrawer()
  }, [disableActions, parcel, openParcelDrawer])

  const [loadSecondaryImages, setLoadSecondaryImages] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setLoadSecondaryImages(true)
    }, 1000)
  }, [])

  return (
    <>
      <AdmittedUserContainer
        attemptedActionName="openPropertyDetails"
        onCreateAccount={handleOpenParcelDrawer}
      >
        <Card
          sx={{borderRadius: 2, position: 'relative'}}
          onClick={handleOpenParcelDrawer}
        >
          {!disableActions && (
            <Box position="absolute" sx={{right: 8, top: 8, zIndex: 10}}>
              <WatchlistButton parcel={parcel} isOnWatchlist={isOnWatchlist} />
            </Box>
          )}
          {parcel.propertyImageUrls.length > 0 ? (
            <Carousel
              animation="slide"
              autoPlay={false}
              duration={500}
              indicators={false}
              navButtonsAlwaysVisible={true}
              navButtonsProps={{
                style: {
                  backgroundColor: 'rgba(200, 200, 200, 0.60)',
                  padding: 4,
                },
              }}
            >
              {parcel.propertyImageUrls.map((imageUrl, index) => (
                <CardMedia
                  key={index}
                  component="img"
                  image={
                    index == 0 || loadSecondaryImages ? imageUrl : undefined
                  }
                  sx={{height: 150, objectFit: 'cover'}}
                />
              ))}
            </Carousel>
          ) : (
            <Box>
              <Box
                sx={{
                  width: '100%',
                  height: 150,
                  backgroundColor: 'grey.100',
                  position: 'relative',
                }}
              >
                <ImageNotSupported
                  fontSize="large"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  color="disabled"
                />
              </Box>
            </Box>
          )}
          <CardContent sx={{p: 2, '&:last-child': {paddingBottom: '12px'}}}>
            <ParcelHighlights parcel={parcel} />
          </CardContent>
        </Card>
      </AdmittedUserContainer>
      {!disableActions && DrawerContent && (
        <ParcelCardDrawer open={parcelDrawerOpen}>
          <DrawerContent
            taxAssessorId={parcel.taxAssessorId}
            onBack={closeParcelDrawer}
            isOnWatchlist={isOnWatchlist}
          />
        </ParcelCardDrawer>
      )}
    </>
  )
}

export const LoadingCard = (): JSX.Element => (
  <Card
    sx={{
      borderRadius: 2,
    }}
  >
    <CardContent sx={{p: 1}}>
      <Skeleton variant="rectangular" width="100%" height="130px" />
    </CardContent>
    <CardContent sx={{p: 1}}>
      <Stack spacing={1.5}>
        <Skeleton variant="rectangular" width="50%" height="16px" />
        <Skeleton variant="rectangular" width="90%" height="16px" />
        <Skeleton variant="rectangular" width="90%" height="16px" />
      </Stack>
    </CardContent>
    <CardActions>
      <Skeleton variant="rectangular" width="25%" height="16px" />
    </CardActions>
  </Card>
)

interface ParcelCardDrawerProps extends DrawerProps {
  children: React.ReactNode
}

export const ParcelCardDrawer = ({
  children,
  ...drawerProps
}: ParcelCardDrawerProps): JSX.Element => {
  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
        },
      }}
      sx={{
        height: ['100dvh', '100vh'],
        width: ['100dvw', '100vw'],
        zIndex: 900,
      }}
      {...drawerProps}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        height="100%"
      >
        <Box flex="1 0">{drawerProps.open ? <>{children}</> : null}</Box>
      </Box>
    </Drawer>
  )
}
