import * as React from 'react'

import {Send} from '@mui/icons-material'
import {Stack, Typography, TextField, IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'

import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {ProgressBox} from 'src/framework/ui/ProgressBox'
import {verifyAdmittanceApiCoPilotAccountPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
})

type AdmittanceFormProps = {
  onSuccess: () => void
}

export function AdmittanceForm({onSuccess}: AdmittanceFormProps) {
  const [input, setInput] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [verifyInProgress, setVerifyInProgress] = React.useState(false)
  const {request: verifyReferralCode} = useRequest<
    void,
    {referralCode: string}
  >('POST', verifyAdmittanceApiCoPilotAccountPath(), {
    disableDefaultErrorHandling: true,
  })

  const classes = useStyles()

  const handleSubmit = async () => {
    if (input.length == 0) {
      return
    }

    setVerifyInProgress(true)
    setErrorMessage(null)
    setTimeout(() => {
      verifyReferralCode({
        data: {
          referralCode: input,
        },
      })
        .then(() => {
          onSuccess()
        })
        .catch((err) => {
          setVerifyInProgress(false)
          if (err.response.status === 403) {
            setErrorMessage(
              "We're sorry but that code isn't valid. Please try again.",
            )
          } else {
            setErrorMessage("We're sorry. Something unexpected went wrong.")
          }
        })
    }, 1000)
  }

  return (
    <Stack direction="column" alignItems="center" p={2} gap={2}>
      <Stack direction="column" spacing={2} textAlign="center" px={1}>
        <Typography variant="h4">Verify your identity</Typography>
        <Typography variant="body1" fontWeight="normal">
          This product is exclusive to local business owners. We rely on your
          community to verify new members.
        </Typography>
        <Typography variant="body1" fontWeight="normal">
          Enter a referral code to continue.
        </Typography>
        {errorMessage ? (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <MaterialSymbolIcon color="error">error</MaterialSymbolIcon>
            <Typography color="error">{errorMessage}</Typography>
          </Stack>
        ) : null}
      </Stack>
      <ProgressBox loading={verifyInProgress}>
        <TextField
          fullWidth
          autoFocus
          variant="outlined"
          disabled={verifyInProgress}
          className={classes.input}
          placeholder="0000"
          type="number"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }}
          inputProps={{
            inputMode: 'numeric',
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={handleSubmit}
                sx={{mr: -1}}
                disabled={input.length == 0}
              >
                <Send />
              </IconButton>
            ),
          }}
        />
      </ProgressBox>
    </Stack>
  )
}
