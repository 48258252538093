export const formatCurrencyCompact = (amount: number | null) => {
  if (amount === null || amount === undefined) {
    return null
  }

  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 2,
    minimumSignificantDigits: Math.abs(amount) < 10 ? 1 : 2,
  })
  return formatter.format(amount)
}

export const formatCurrencyRangeCompact = (
  min: number | null,
  max: number | null,
) => {
  if (!min && !max) {
    return '--'
  } else if (!min) {
    formatCurrencyCompact(max)
  } else if (!max) {
    formatCurrencyCompact(min)
  } else {
    return min === max
      ? formatCurrencyCompact(min)
      : `${formatCurrencyCompact(min)} - ${formatCurrencyCompact(max)}`
  }
}
