import * as React from 'react'

import {Box} from '@mui/system'

type CopilotScreenProps = {
  isActive: boolean
  children: React.ReactNode
}

export function CopilotScreen({
  isActive,
  children,
}: CopilotScreenProps): JSX.Element {
  const [hasBeenRendered, setHasBeenRendered] = React.useState(false)

  React.useEffect(() => {
    if (isActive) {
      setHasBeenRendered(true)
    }
  }, [isActive])

  return (
    <Box
      sx={{
        display: isActive ? 'block' : 'none',
        height: '100%',
        width: '100%',
        overflow: 'auto',
      }}
    >
      {hasBeenRendered ? children : null}
    </Box>
  )
}
