import React from 'react'

import {Button, Typography} from '@mui/material'
import {grey} from '@mui/material/colors'
import {Box, Stack} from '@mui/system'

import {AddressAutocomplete} from 'src/components/copilot/mapbox/AddressAutocomplete'
import {apiCoPilotParcelsClaimedLocationsPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {ClaimedLocation, Parcel} from 'src/types/copilot'

interface Props {
  onComplete: () => void
}

export const ClaimYourBusinessPage = ({onComplete}: Props) => {
  const [businessAddress, setBusinessAddress] = React.useState<string>('')

  const [parcelResult, setParcelResult] = React.useState<Parcel>()

  const handleParcelFound = React.useCallback((parcel: Parcel) => {
    setParcelResult(parcel)
  }, [])

  const {request: saveClaimedLocation} = useRequest<
    void,
    Partial<ClaimedLocation>
  >('POST', apiCoPilotParcelsClaimedLocationsPath())

  const handleBusinessSelection = React.useCallback(
    async (businessName) => {
      if (!parcelResult) {
        return
      }

      await saveClaimedLocation({
        data: {
          taxAssessorId: parcelResult.taxAssessorId,
          businessName,
        },
      })

      onComplete()
    },
    [onComplete, parcelResult, saveClaimedLocation],
  )

  return (
    <Box px={2} maxWidth={{xs: '100%', md: '375px'}} width="100%">
      <Stack alignItems="center" spacing={2} mt={6}>
        <Typography variant="h4">Claim your business</Typography>
        <AddressAutocomplete
          fetchNearbyBusinesses={true}
          inputElementId="claim-business-autocomplete"
          input={businessAddress}
          setInput={setBusinessAddress}
          onParcelFound={handleParcelFound}
        />
        {parcelResult && (
          <Stack width="100%" spacing={0.5}>
            <Button
              color="secondary"
              onClick={() => handleBusinessSelection('')}
              sx={{color: grey[500]}}
            >
              My business is not shown
            </Button>
            {parcelResult?.nearbyBusinesses.map((business, index) => (
              <Stack
                key={index}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack paddingX={1} paddingY={0.5}>
                  <Typography variant="body1" fontWeight="700">
                    {business.name}
                  </Typography>
                  <Typography variant="body2">{business.vicinity}</Typography>
                </Stack>
                <Button
                  onClick={() => handleBusinessSelection(business.name)}
                  sx={{paddingX: 1, paddingY: 0.5, minWidth: 'auto'}}
                >
                  Claim
                </Button>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
