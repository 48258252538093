import * as React from 'react'

import {CssBaseline, ThemeProvider, createTheme, alpha} from '@mui/material'
import {grey} from '@mui/material/colors'

const WITHCO_BLUE = '#006AFF'
const WITHCO_GREY = '#323232'

const interMediumFontFace = `
@font-face {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}`

const interBoldFontFace = `
@font-face {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}`

export const withcoBlue = {
  background: alpha(WITHCO_BLUE, 0.03),
  backgroundHover: alpha(WITHCO_BLUE, 0.08),
  main: WITHCO_BLUE,
}

const theme = createTheme({
  palette: {
    text: {
      primary: grey[900],
    },
    background: {
      default: '#f8f8f8',
      paper: '#ffffff',
      blue: withcoBlue.background,
    },
    primary: {
      main: WITHCO_BLUE,
      contrastText: '#ffffff',
    },
    secondary: {
      main: WITHCO_GREY,
    },
    info: {
      main: grey[700],
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    body1: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
      fontWeight: 700,
    },
    caption: {
      fontSize: '0.625rem',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: `
          overscrollBehavior: 'none';
          ${interMediumFontFace};
          ${interBoldFontFace};
        `,
        body: {
          overscrollBehavior: 'none',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0.625rem 1rem',
          borderRadius: '3rem',
        },
        outlinedSecondary: {
          borderColor: grey[400],
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: grey[300],
          },
        },
        outlinedInfo: {
          border: 'none',
        },
      },
    },
  },
})

theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.125rem',
    fontWeight: '700',
  },
}

theme.typography.strong = {
  ...theme.typography.strong,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}

interface Props {
  children: React.ReactNode
}

export const CopilotAppThemeProvider = ({children}: Props): JSX.Element => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
)
