import * as React from 'react'

import {User} from 'src/types'
import {identify} from 'src/util/analytics'
import Hotjar from 'src/util/hotjar'

export const useAnalyticsUser = (user: User | null | undefined): void => {
  const [identifiedUserId, setIdentifiedUserId] = React.useState<string | null>(
    null,
  )

  React.useEffect(() => {
    if (user && identifiedUserId !== user.id) {
      identify(user)
      setIdentifiedUserId(user.id)

      Hotjar.identify(user.id, user.email, user.name || '')

      const intercomAppId = document.querySelector<HTMLMetaElement>(
        'meta[name="intercomAppId"]',
      )?.content

      if (intercomAppId) {
        window.Intercom('boot', {
          app_id: intercomAppId,
          email: user.email,
          user_id: user.id,
          custom_launcher_selector: '.open-intercom',
        })
      }
    }
  }, [identifiedUserId, user])
}
