import * as React from 'react'

import {Box, Container, useMediaQuery, useTheme} from '@mui/material'

import {ChatInterface} from 'src/components/copilot/ChatInterface'
import {
  ParcelFilters,
  ParcelFilterValue,
} from 'src/components/copilot/ParcelFilters'
import {LoadingCard, PropertyCard} from 'src/components/copilot/PropertyCard'
import {RecommendationCarousel} from 'src/components/copilot/RecommendationCarousel'
import {InputData} from 'src/components/copilot/UserInput'
import {MapBoxMap} from 'src/components/copilot/mapbox/SearchMap'
import Carousel from 'src/framework/ui/Carousel'
import {
  GrabbableMobileSwipeDrawer,
  swipeAreaWidth,
} from 'src/framework/ui/GrabbableMobileSwipeDrawer'
import {apiCoPilotListingsPath} from 'src/generated/routes'
import {useIndex} from 'src/hooks/request/useIndex'
import {useCopilotChat} from 'src/hooks/useCopilotChat'
import {useToggle} from 'src/hooks/util/useToggle'
import {Listing} from 'src/types/copilot'
import {RecommendationOption} from 'src/types/copilot/message'
import {formatCurrency, formatDecimal} from 'src/util/format'

type InventoryQueryParams = {
  inventory_only?: boolean
  available_area_low?: number
  available_area_high?: number
  property_types?: string[]
  property_value_low?: number
  property_value_high?: number
  monthly_payment_low?: number
  monthly_payment_high?: number
  parcel_ids?: string[]
}

type Mode = 'inventory' | 'map'

export const CopilotHomeScreen = (): JSX.Element => {
  const {
    messages,
    onSubmit,
    onCancel,
    lease,
    refetchLease,
    recommendationCarouselVisible,
    recommendationOptions,
    recommendationMode,
  } = useCopilotChat()
  const [isChatOpen, openChatDrawer, closeChatDrawer] = useToggle(true)
  const [listings, setListings] = React.useState<Listing[] | null>(null)
  const [selectedListing, setSelectedListing] = React.useState<Listing | null>(
    null,
  )
  const [filter, setFilter] = React.useState<ParcelFilterValue | null>()
  const [mode, setMode] = React.useState<Mode>('map')
  const [isCarouselOpen, openCarousel, closeCarousel] = useToggle(false)
  const [isPropertyCardExpanded, setPropertyCardExpanded] =
    React.useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const cameraPosition = React.useMemo(() => {
    if (!lease?.parcelCentroid) {
      return undefined
    }

    if (mode === 'inventory') {
      return {
        zoom: 8,
      }
    }

    return {
      lat: lease.parcelCentroid.coordinates[1],
      lng: lease.parcelCentroid.coordinates[0],
      zoom: 17,
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lease?.parcelCentroid, mode, selectedListing])

  const {fetch: fetchListings, loading: isListingsFetchLoading} =
    useIndex<Listing>(apiCoPilotListingsPath())

  const fetchInventory = React.useCallback(() => {
    const filterParams: InventoryQueryParams = {
      inventory_only: true,
    }

    if (filter) {
      Object.assign(filterParams, {
        available_area_low: filter.areaLow,
        available_area_high: filter.areaHigh,
        property_types: filter.propertyTypes
          ? Array.from(filter.propertyTypes)
          : undefined,
      })

      if (filter.priceDataType === 'property_value') {
        Object.assign(filterParams, {
          property_value_high: filter.propertyValueHigh,
          property_value_low: filter.propertyValueLow,
        })
      } else {
        Object.assign(filterParams, {
          monthly_payment_low: filter.monthlyPaymentLow,
          monthly_payment_high: filter.monthlyPaymentHigh,
        })
      }
    }

    for (const [key, value] of Object.entries(filterParams)) {
      if (value === null || value === undefined) {
        delete filterParams[key as keyof typeof filterParams]
      }
    }

    fetchListings({params: filterParams}).then(({entities: listings}) => {
      setListings(listings)
      setSelectedListing(listings[0])
      setPropertyCardExpanded(false)
    })
  }, [fetchListings, filter])

  const handleSubmit = React.useCallback(
    (inputData?: InputData) => {
      if (inputData?.value === 'CONTINUE_TO_MAP') {
        closeChatDrawer()
        return
      }

      if (inputData?.value === 'CONTINUE_TO_INVENTORY') {
        closeChatDrawer()
        openCarousel()
        setMode('inventory')
        fetchInventory()
        return
      }

      if (inputData?.value === RecommendationOption.OWN_CURRENT_BUILDING) {
        openChatDrawer()
        setListings([])
        setPropertyCardExpanded(false)
      }

      onSubmit(inputData)
    },
    [closeChatDrawer, fetchInventory, onSubmit, openCarousel, openChatDrawer],
  )

  const handleParcelsSelected = React.useCallback(
    (nonInventoryParcelIds: string[], inventoryParcelIds: string[]) => {
      if (inventoryParcelIds.length) {
        openCarousel()
        fetchListings({params: {parcel_ids: inventoryParcelIds}}).then(
          ({entities: listings}) => {
            setListings(listings)
            setSelectedListing(listings[0])
          },
        )
        return
      }

      if (nonInventoryParcelIds.length) {
        openCarousel()
        fetchListings({params: {parcel_ids: nonInventoryParcelIds}}).then(
          ({entities: listings}) => {
            setListings(listings)
          },
        )
        return
      }

      closeCarousel()
      if (mode === 'inventory') {
        fetchInventory()
      }
    },
    [closeCarousel, fetchInventory, fetchListings, mode, openCarousel],
  )

  const handleFilterChange = React.useCallback(
    (filter: ParcelFilterValue | null) => {
      setFilter(filter)
    },
    [],
  )

  React.useEffect(() => {
    if (mode === 'inventory') {
      openCarousel()
      fetchInventory()
    }
  }, [fetchInventory, filter, mode, openCarousel])

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: ['100dvh', '100vh'],
        width: ['100dvw', '100vw'],
      }}
    >
      <Box sx={{height: '100%', width: '100%'}}>
        <Box
          position="absolute"
          top={0}
          left={0}
          zIndex={50}
          maxWidth={'100%'}
          overflow={'auto'}
          mt={3}
          px={3}
          display={
            mode === 'inventory' && (!isChatOpen || !isMobile)
              ? 'block'
              : 'none'
          }
        >
          <ParcelFilters onFilterChange={handleFilterChange} />
        </Box>
        <MapBoxMap
          sx={{height: '100%', width: '100%'}}
          cameraPosition={cameraPosition}
          highlightedParcelId={lease?.cherreParcelId}
          highlightedInventoryMarkerId={
            isCarouselOpen ? selectedListing?.cherreParcelId : null
          }
          showParcels={!!lease}
          inventoryIds={
            mode === 'inventory'
              ? (listings ?? []).map((l) => l.cherreParcelId)
              : []
          }
          onParcelsSelected={handleParcelsSelected}
        />
        {isCarouselOpen && (
          <Box
            position="absolute"
            bottom={swipeAreaWidth + 20}
            zIndex={50}
            left={{xs: 0, md: 3}}
            maxWidth={{xs: '100%', md: '375px'}}
            width="100%"
          >
            <Carousel
              onChange={(index) =>
                setSelectedListing(listings?.[index] ?? null)
              }
            >
              {isListingsFetchLoading ? (
                <LoadingCard />
              ) : (
                (listings ?? []).map((listing, index) => (
                  <PropertyCard
                    key={`listing-card-${listing.taxAssessorId}-${index}`}
                    address={listing.address ?? '--'}
                    imageUrl={listing.imageUrls?.[0] ?? null}
                    values={listingToTableRows(listing)}
                    expanded={isPropertyCardExpanded}
                    onExpandToggle={() =>
                      setPropertyCardExpanded(!isPropertyCardExpanded)
                    }
                  />
                ))
              )}
            </Carousel>
          </Box>
        )}
      </Box>
      {isMobile ? (
        <GrabbableMobileSwipeDrawer
          open={isChatOpen}
          onClose={closeChatDrawer}
          onOpen={openChatDrawer}
          drawerBleeding={80}
          hideBackdrop
          contentSx={{
            backgroundColor: 'transparent',
            backdropFilter: 'blur(4px)',
            marginBottom: recommendationCarouselVisible ? '45px' : 0,
          }}
        >
          <ChatInterface
            messages={messages}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            refetchLease={refetchLease}
          />
        </GrabbableMobileSwipeDrawer>
      ) : (
        <Box
          sx={{
            maxHeight: '75vh',
            maxWidth: '400px',
            position: 'absolute',
            right: 0,
            bottom: '50%',
            transform: 'translate(-24px, 50%)',
            backgroundColor: 'transparent',
            backdropFilter: 'blur(4px)',
            borderRadius: 2,
            boxShadow: 2,
            py: 3,
            overflow: 'auto',
            zIndex: 100,
          }}
        >
          <ChatInterface
            messages={messages}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            refetchLease={refetchLease}
          />
        </Box>
      )}
      <RecommendationCarousel
        visible={recommendationCarouselVisible}
        options={recommendationOptions}
        recommendationMode={recommendationMode}
        onSubmit={handleSubmit}
      />
    </Container>
  )
}

const roundToNearest = (value: number | null, nearest: number): number => {
  if (value === null) return 0
  if (nearest === 0) return value

  return Math.round(value / nearest) * nearest
}

const listingToTableRows = (listing: Listing): [string, string][] => {
  // Helper function to format range
  const formatRange = (
    min: string | number | null,
    max: string | number | null,
  ): string => {
    const values = []
    if (min !== null) values.push(min)
    if (max !== null && min !== max) values.push(max)

    if (values.length === 0) return '--'

    return values.join(' - ')
  }

  const getListingStatus = (): string => {
    if (listing.saleOrLeaseCode === 'lease') return 'For Lease'
    if (listing.saleOrLeaseCode === 'sale') return 'For Sale'
    return 'Unlisted'
  }

  const getAskingRow = (): [string, string] | null => {
    if (listing.saleOrLeaseCode === 'lease') {
      const precision =
        listing.rentAskingPriceMin === listing.rentAskingPriceMax ? 2 : 0

      return [
        'Asking Rent',
        `${
          formatRange(
            formatCurrency(listing.rentAskingPriceMin?.toString(), {
              precision: precision,
            }),
            formatCurrency(listing.rentAskingPriceMax?.toString(), {
              precision: precision,
            }),
          ) ?? '--'
        } PSF | ${listing.leaseExpenseType}`,
      ]
    }

    if (listing.saleOrLeaseCode === 'sale') {
      return [
        'Asking Price',
        formatCurrency(listing.saleAskingPrice?.toString(), {precision: 0}) ??
          '--',
      ]
    }

    return null
  }

  if (listing.isInventory) {
    return [
      ['Eligible for Ownership', listing.eligibleForOwnership ? 'Yes' : 'No'],
      [
        'Property Value Estimate',
        formatCurrency(
          `${roundToNearest(listing.propertyValueEstimate, 10000)}`,
          {
            precision: 0,
          },
        ) ?? '--',
      ],
      ['Listing Status', getListingStatus()],
      getAskingRow() ?? ['Asking Price', '--'],
      [
        'Available SF',
        [
          formatRange(
            formatDecimal(listing.availableAreaMin?.toString(), {precision: 0}),
            formatDecimal(listing.availableAreaMax?.toString(), {precision: 0}),
          ),
          'SF',
        ].join(' '),
      ],
      [
        'Building Size',
        [
          formatDecimal(listing.buildingSqFt, {precision: 0}) ?? '--',
          'SF',
        ].join(' '),
      ],
      ['Property Type', listing.propertyType ?? '--'],
      [
        'Current Use',
        listing.currentUses && listing.currentUses.length > 0
          ? listing.currentUses.join(', ')
          : '--',
      ],
      ['Year Built', listing.yearBuilt ?? '--'],
    ]
  }

  return [
    ['Eligible for Ownership', listing.eligibleForOwnership ? 'Yes' : 'No'],
    ['Listing Status', getListingStatus()],
    [
      'Building Size',
      [formatDecimal(listing.buildingSqFt, {precision: 0}) ?? '--', 'SF'].join(
        ' ',
      ),
    ],
    ['Property Type', listing.propertyType ?? '--'],
    [
      'Allowable Use',
      listing.allowableUses && listing.allowableUses.length > 0
        ? listing.allowableUses.join(', ')
        : '--',
    ],
    ['Year Built', listing.yearBuilt ?? '--'],
  ]
}
