import {createSlice} from '@reduxjs/toolkit'

import {ClaimedLocation} from 'src/types/copilot'

interface ClaimedLocationsState {
  items: ClaimedLocation[]
  hasBeenFetched: boolean
}

const initialState: ClaimedLocationsState = {
  items: [],
  hasBeenFetched: false,
}

export const claimedLocationsSlice = createSlice({
  name: 'claimedLocations',
  initialState,
  reducers: {
    setClaimedLocations: (state, action) => {
      const payload = action.payload
      state.items = payload
      state.hasBeenFetched = true
    },
    removeClaimedLocations: (state) => {
      state.items = []
      state.hasBeenFetched = true
    },
  },
})

export const {setClaimedLocations, removeClaimedLocations} =
  claimedLocationsSlice.actions
export default claimedLocationsSlice.reducer
