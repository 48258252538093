import * as React from 'react'

import {Typography, ToggleButton, ToggleButtonGroup} from '@mui/material'

interface ToggleFilterInputProps<T extends string> {
  value: T | null
  onChange: (value: T) => void
  options: {value: T; label: string}[]
}

export function ToggleFilterInput<T extends string>({
  value,
  onChange,
  options,
}: ToggleFilterInputProps<T>) {
  return (
    <ToggleButtonGroup
      exclusive
      fullWidth
      size="small"
      value={value}
      onChange={(e, toggleValue) => {
        onChange(toggleValue)
      }}
    >
      {options.map((option) => (
        <ToggleButton value={option.value} key={option.value}>
          <Typography>{option.label}</Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
