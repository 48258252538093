import {createSlice} from '@reduxjs/toolkit'

import {Parcel} from 'src/types/copilot'

interface ClaimedParcelsState {
  items: Parcel[]
  hasBeenFetched: boolean
}

const initialState: ClaimedParcelsState = {
  items: [],
  hasBeenFetched: false,
}

export const claimedParcelsSlice = createSlice({
  name: 'claimedParcels',
  initialState,
  reducers: {
    setClaimedParcels: (state, action) => {
      const payload = action.payload
      state.items = payload
      state.hasBeenFetched = true
    },
    removeClaimedParcels: (state) => {
      state.items = []
      state.hasBeenFetched = true
    },
  },
})

export const {setClaimedParcels, removeClaimedParcels} =
  claimedParcelsSlice.actions
export default claimedParcelsSlice.reducer
