import * as React from 'react'

import axios, {AxiosError, AxiosResponse, Method} from 'axios'
import {merge} from 'lodash'
import {stringify} from 'qs'

import {loginPath} from 'src/generated/routes'
import {useAxiosCancelToken} from 'src/hooks/request/useAxiosCancelToken'
import {useAxiosErrorHandler} from 'src/hooks/request/useAxiosErrorHandler'
import {useCsrfToken} from 'src/hooks/request/useCsrfToken'

export type UseLazyRequestResult<R, D, P> = {
  request: (opts?: RequestOptions<D, P>) => Promise<R | void>
  response: R | null
  loading: boolean
  completed: boolean
  error: AxiosResponse | null
  statusCode: number | null
}

export type UseLazyRequestOptions<R> = {
  suppressedErrorCodes?: Array<number>
  onSuccess?: (res: R) => void
  onError?: (error: AxiosError) => void
  raise?: boolean
  headers?: Record<string, string>
  autoRequest?: boolean
  disableDefaultErrorHandling?: boolean
}

export type RequestOptions<D, P = void> = {
  data?: D
  params?: P
  url?: string
}

/*
 * This hook is used to manage request state and keep our request behaviors
 * (HTTP headers, error handling, etc.) internally consistent.
 *
 * It can be called with a URL to generate an asynchronous request handler for
 * that URL, or with no URL to generate an asynchronous request handler whose
 * target is dynamic.
 *
 * Examples:
 * const {request: createFile, loading: isCreatingFile} = useRequest(
 *   'POST',
 *   '/api/internal/files',
 * )
 * ...
 * createFile({name: 'Name'})
 *
 * const {request: update, loading: isUpdating} = useRequest('PUT')
 * ...
 * update('/api/internal/files/1', {name: 'New name'})
 */
export function useRequest<R = void, D = void, P = void>(
  method: Method,
  defaultUrl?: string,
  options: UseLazyRequestOptions<R> = {},
): UseLazyRequestResult<R, D, P> {
  const [{response, loading, error, completed, statusCode}, setResult] =
    React.useState<{
      response: R | null
      loading: boolean
      error: AxiosResponse | null
      completed: boolean
      statusCode: number | null
    }>({
      response: null,
      loading: false,
      completed: false,
      error: null,
      statusCode: null,
    })

  const {
    suppressedErrorCodes,
    raise = true,
    onError,
    onSuccess,
    headers,
    autoRequest = false,
    disableDefaultErrorHandling = false,
  } = options

  const csrfToken = useCsrfToken()
  const cancelToken = useAxiosCancelToken()
  const handleAxiosError = useAxiosErrorHandler(suppressedErrorCodes)

  const request = React.useCallback(
    async ({params, data, url: targetUrl}: RequestOptions<D, P> = {}) => {
      setResult((prevState) => merge({}, prevState, {loading: true}))
      const url = targetUrl ?? defaultUrl

      const requestHeaders = merge({['X-CSRF-Token']: csrfToken}, headers)

      return axios
        .request<R>({
          url,
          method,
          headers: requestHeaders,
          cancelToken,
          params,
          data,
          paramsSerializer: (params) =>
            stringify(params, {arrayFormat: 'brackets'}),
        })
        .then((axiosResponse) => {
          const response = axiosResponse.data
          setResult({
            response,
            error: null,
            loading: false,
            completed: true,
            statusCode: axiosResponse.status,
          })
          onSuccess && onSuccess(response)
          return response
        })
        .catch((err: Error | AxiosError) => {
          if (axios.isCancel(err)) return
          if (axios.isAxiosError(err)) {
            const pathname = window.location.pathname
            const isPartnerPortal =
              !pathname.startsWith('/portal/login') &&
              pathname.startsWith('/portal')
            const isUnauthenticatedSmb =
              err.response?.status === 401 && isPartnerPortal
            const redirectToLoginScreen = isUnauthenticatedSmb

            if (redirectToLoginScreen) {
              window.location.href = loginPath()
            }
            setResult({
              response: null,
              error: err.response ?? null,
              loading: false,
              completed: true,
              statusCode: err.response?.status ?? null,
            })
            if (!disableDefaultErrorHandling && !redirectToLoginScreen) {
              handleAxiosError(
                err,
                `Something went wrong: [${method.toUpperCase()} ${url}]`,
              )
            }
            onError && onError(err)
            if (
              raise &&
              !redirectToLoginScreen &&
              (disableDefaultErrorHandling ||
                !suppressedErrorCodes?.includes(err.response?.status ?? 0))
            ) {
              throw err
            }
          } else {
            setResult((prev) => ({...prev, loading: false}))
            throw err
          }
        })
    },
    [
      defaultUrl,
      csrfToken,
      headers,
      method,
      cancelToken,
      onSuccess,
      disableDefaultErrorHandling,
      onError,
      raise,
      suppressedErrorCodes,
      handleAxiosError,
    ],
  )

  const [fetchedUrl, setFetchedUrl] = React.useState<string | null>(null)
  React.useEffect(
    function autoRequestDefaultUrl() {
      if (!autoRequest || !defaultUrl) return
      if (fetchedUrl === defaultUrl) return
      setFetchedUrl(defaultUrl ?? null)
      request()
    },
    [defaultUrl, fetchedUrl, request, autoRequest],
  )

  return {request, response, loading, error, completed, statusCode}
}
