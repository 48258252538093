import * as React from 'react'

import {
  IconButton,
  Typography,
  Box,
  Divider,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import {grey} from '@mui/material/colors'

import {AdmittedUserContainer} from 'src/components/copilot/AdmittedUserContainer'
import {RangeFilterInput} from 'src/components/copilot/RangeFilterInput'
import {SelectFilterInput} from 'src/components/copilot/SelectFilterInput'
import {ToggleFilterInput} from 'src/components/copilot/ToggleFilterInput'
import {AutocompleteMultiselectTextField} from 'src/framework/form/AutocompleteMultiselectTextField'
import {AreaFormat, WholeDollarFormat} from 'src/framework/form/NumberFormat'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {MobileSwipeDrawer} from 'src/framework/ui/MobileSwipeDrawer'
import {trackEvent, trackEventDebounced} from 'src/util/analytics'

type TRange = {
  low: number
  high: number
}

type PropertyType =
  | 'RECREATIONAL'
  | 'COMMERCIAL'
  | 'OFFICE'
  | 'EXEMPT'
  | 'INDUSTRIAL'

type PostalCode = string

type TotalPriceRange = TRange

type MontlyPriceRange = TRange

type ListingStatus = 'for_sale' | 'for_lease' | 'off_market'

type AreaRange = TRange

type ParkingSpaceRange = TRange

type YearBuiltRange = TRange

type Vacant = true | false

type BathCountRange = TRange

type HvaccDescription = 'Central' | 'Yes' | 'Evaporative Cooler' | 'None'

export type Filter = {
  propertyTypeList?: PropertyType[]
  postalCodeList?: PostalCode[]
  totalPriceRange?: TotalPriceRange
  monthlyPriceRange?: MontlyPriceRange
  listingStatus?: ListingStatus
  areaRange?: AreaRange
  parkingSpaceRange?: ParkingSpaceRange
  yearBuiltRange?: YearBuiltRange
  isVacant?: Vacant
  bathCountRange?: BathCountRange
  hvaccDescription?: HvaccDescription
}

type Props = {
  initialFilter: Filter
  onFilterChange: (newFilter: Filter) => void
}

export const AdvancedMapFilters = ({
  initialFilter,
  onFilterChange,
}: Props): JSX.Element => {
  const [renderDrawer, setRenderDrawer] = React.useState(false)

  const handleClick = React.useCallback((e) => {
    e.stopPropagation()
    setRenderDrawer(true)
  }, [])

  return (
    <>
      <AdmittedUserContainer
        attemptedActionName="openFilters"
        onCreateAccount={() => setRenderDrawer(true)}
      >
        <IconButton
          color="secondary"
          sx={{
            backgroundColor: '#fff',
            aspectRatio: '1 / 1',
            height: '46px',
            '&:hover': {
              backgroundColor: grey[300],
            },
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'rgba(0,0,0,0.23)', // TODO: use theme
            position: 'relative',
          }}
          onClick={handleClick}
        >
          <MaterialSymbolIcon>tune</MaterialSymbolIcon>
        </IconButton>
      </AdmittedUserContainer>
      {renderDrawer && (
        <AdvancedMapFiltersDrawer
          initialFilter={initialFilter}
          onClose={() => setRenderDrawer(false)}
          applyFilters={onFilterChange}
        />
      )}
    </>
  )
}

type AdvancedMapFiltersDrawerProps = {
  initialFilter: Filter
  onClose: () => void
  applyFilters: (filter: Filter) => void
}

export const AdvancedMapFiltersDrawer = ({
  initialFilter,
  onClose,
  applyFilters,
}: AdvancedMapFiltersDrawerProps): JSX.Element => {
  return (
    <MobileSwipeDrawer
      open={true}
      onClose={() => {
        trackEvent('Close Filters')
        onClose()
      }}
      onClick={(e) => e.stopPropagation()}
      title={'Filters'}
      big={true}
    >
      <Box px={2} py={2}>
        <PropertyFilterForm
          initialFilter={initialFilter}
          submitFilter={(newFilter: Filter) => {
            trackEvent('Apply Filters')
            applyFilters(newFilter)
            onClose()
          }}
        />
      </Box>
    </MobileSwipeDrawer>
  )
}

type FilterComponentProps = {
  label: string
  clearFilter: (key: keyof Filter) => void
  filterType: keyof Filter
  children: React.ReactNode
}
const FilterComponent = ({
  label,
  clearFilter,
  filterType,
  children,
}: FilterComponentProps) => {
  return (
    <Accordion
      defaultExpanded
      disableGutters
      sx={{border: 'none', '&::before': {display: 'none'}, paddingX: 0}}
    >
      <AccordionSummary
        expandIcon={<MaterialSymbolIcon>expand_more</MaterialSymbolIcon>}
        sx={{paddingX: 0}}
      >
        <Typography variant="h2">{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {' '}
        <Stack direction="column" spacing={2} alignItems="flex-start">
          {children}
          <Button
            variant="text"
            sx={{
              fontWeight: 500,
              p: 0,
              textDecoration: 'underline',
              color: grey[700],
            }}
            onClick={() => {
              clearFilter(filterType)
            }}
          >
            Clear
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

type PriceType = 'totalPriceRange' | 'monthlyPriceRange'
type PriceFilterComponentProps = {
  monthly?: TRange
  total?: TRange
  onChange(type: PriceType, low: number, high: number): void
  clearFilter: (key: keyof Filter) => void
  onError: (type: PriceType, errors: string[]) => void
}
const PriceFilterComponent = ({
  total,
  monthly,
  onChange,
  clearFilter,
  onError,
}: PriceFilterComponentProps) => {
  const [type, setType] = React.useState<PriceType>(
    monthly === undefined ? 'totalPriceRange' : 'monthlyPriceRange',
  )

  const handleSliderUpdate = React.useCallback(
    (low: number | null, high: number | null, validationErrors: string[]) => {
      if (validationErrors.length > 0) {
        console.log('Error: price filter validation error')
        onError(type, validationErrors)
      }
      onChange(
        type,
        low ?? 0,
        high ?? (type === 'monthlyPriceRange' ? 10000 : 10000000),
      )
    },
    [onChange, type, onError],
  )
  return (
    <Accordion
      defaultExpanded
      disableGutters
      sx={{border: 'none', '&::before': {display: 'none'}, paddingX: 0}}
    >
      <AccordionSummary
        expandIcon={<MaterialSymbolIcon>expand_more</MaterialSymbolIcon>}
        sx={{paddingX: 0}}
      >
        <Typography variant="h2">Price</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {' '}
        <Stack direction="column" spacing={2} alignItems="flex-start">
          <>
            <ToggleButtonGroup
              exclusive
              fullWidth
              size="small"
              value={type}
              onChange={(e, priceDataValue) => {
                setType(priceDataValue)
              }}
            >
              <ToggleButton value="totalPriceRange">
                <Typography>Est. Property Value</Typography>
              </ToggleButton>
              <ToggleButton value="monthlyPriceRange">
                <Typography>Monthly Payment</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
            {type === 'totalPriceRange' ? (
              <RangeFilterInput
                key={type}
                low={total?.low ?? null}
                high={total?.high ?? null}
                min={0}
                max={10000000}
                sliderIncrement={10000}
                onChange={handleSliderUpdate}
                inputComponent={WholeDollarFormat}
              />
            ) : (
              <RangeFilterInput
                key={type}
                low={monthly?.low ?? null}
                high={monthly?.high ?? null}
                min={0}
                max={10000}
                sliderIncrement={10}
                onChange={handleSliderUpdate}
                inputComponent={WholeDollarFormat}
              />
            )}
          </>
          <Button
            variant="text"
            sx={{
              fontWeight: 500,
              p: 0,
              textDecoration: 'underline',
              color: grey[700],
            }}
            onClick={() => {
              clearFilter(type)
            }}
          >
            Clear
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

type FormErrors = Partial<Record<keyof Filter, string[]>>

type PropertyFilterFormProps = {
  initialFilter: Filter
  submitFilter: (filter: Filter) => void
}

// TODO: show count in submit button
const PropertyFilterForm: React.FC<PropertyFilterFormProps> = ({
  initialFilter,
  submitFilter,
}) => {
  const [filter, setFilter] = React.useState<Filter>(initialFilter)
  const [errors, setErrors] = React.useState<FormErrors>({})
  const onError = (key: keyof Filter, newErrors: string[]) => {
    setErrors({
      ...errors,
      [key]: newErrors,
    })
  }

  const clearError = (key: keyof Filter) => {
    setErrors({
      ...errors,
      [key]: undefined,
    })
  }

  const updateFilter = <K extends keyof Filter>(key: K, value: Filter[K]) => {
    trackEventDebounced('Set Filter', {filter: key, filterValue: value})
    clearError(key)
    setFilter({...filter, [key]: value})
  }

  const clearFilter = (key: keyof Filter) => {
    trackEvent('Clear Filter', {filter: key})
    clearError(key)
    setFilter({...filter, [key]: undefined})
  }

  const hasErrors = (): boolean => {
    for (const val of Object.values(errors)) {
      if (val !== undefined && val instanceof Array && val.length > 0) {
        return true
      }
    }
    return false
  }

  const disabled = hasErrors()

  return (
    <Paper
      sx={{width: '100%', maxWidth: 600, mx: 'auto', p: 2, border: 'none'}}
    >
      <FilterComponent
        label="Listing Status"
        filterType="listingStatus"
        clearFilter={clearFilter}
      >
        <ToggleFilterInput<ListingStatus>
          value={filter.listingStatus ?? null}
          onChange={(value) => updateFilter('listingStatus', value)}
          options={[
            {value: 'for_sale', label: 'For Sale'},
            {value: 'for_lease', label: 'For Lease'},
            {value: 'off_market', label: 'Off Market'},
          ]}
        />
      </FilterComponent>
      <Divider />

      <FilterComponent
        label="Postal Code"
        filterType="postalCodeList"
        clearFilter={clearFilter}
      >
        <AutocompleteMultiselectTextField
          id="postalCodeList"
          label=""
          sx={{width: '100%'}}
          values={
            filter.postalCodeList?.map((pc) => ({value: pc, label: pc})) ?? []
          }
          onChange={(value) =>
            updateFilter(
              'postalCodeList',
              value?.map((vl) => vl.value),
            )
          }
          options={[
            {value: '27615', label: '27615'},
            {value: '27697', label: '27697'},
            {value: '27616', label: '27616'},
            {value: '27604', label: '27604'},
            {value: '27628', label: '27628'},
            {value: '27609', label: '27609'},
            {value: '27610', label: '27610'},
            {value: '27601', label: '27601'},
            {value: '27690', label: '27690'},
            {value: '27605', label: '27605'},
            {value: '27614', label: '27614'},
            {value: '27607', label: '27607'},
            {value: '27603', label: '27603'},
            {value: '27612', label: '27612'},
            {value: '27606', label: '27606'},
            {value: '27613', label: '27613'},
            {value: '27617', label: '27617'},
            {value: '27608', label: '27608'},
          ]}
        />
      </FilterComponent>
      <Divider />

      <PriceFilterComponent
        total={filter.totalPriceRange}
        monthly={filter.monthlyPriceRange}
        clearFilter={clearFilter}
        onChange={(type, low, high) => {
          updateFilter(type, {low, high})
        }}
        onError={onError}
      />
      <Divider />

      <FilterComponent
        label="Property Type"
        filterType="propertyTypeList"
        clearFilter={clearFilter}
      >
        <SelectFilterInput<PropertyType>
          selections={
            filter.propertyTypeList !== undefined
              ? new Set(filter.propertyTypeList)
              : null
          }
          onChange={(value) =>
            updateFilter('propertyTypeList', value ? [...value] : undefined)
          }
          options={[
            {value: 'RECREATIONAL', label: 'Recreational'},
            {value: 'COMMERCIAL', label: 'Commercial'},
            {value: 'OFFICE', label: 'Office'},
            {value: 'EXEMPT', label: 'Exempt'},
            {value: 'INDUSTRIAL', label: 'Industrial'},
          ]}
        />
      </FilterComponent>
      <Divider />

      <FilterComponent
        label="Area"
        filterType="areaRange"
        clearFilter={clearFilter}
      >
        <RangeFilterInput
          low={filter.areaRange?.low ?? null}
          high={filter.areaRange?.high ?? null}
          min={0}
          max={10000}
          sliderIncrement={100}
          onChange={(low, high) => {
            updateFilter('areaRange', {
              low: low ?? 0,
              high: high ?? 10000,
            })
          }}
          inputComponent={AreaFormat}
        />
      </FilterComponent>
      <Divider />

      <FilterComponent
        label="Year Built"
        filterType="areaRange"
        clearFilter={clearFilter}
      >
        <RangeFilterInput
          low={filter.yearBuiltRange?.low ?? null}
          high={filter.yearBuiltRange?.high ?? null}
          min={1900}
          max={new Date().getFullYear()}
          sliderIncrement={5}
          onChange={(low, high) =>
            updateFilter('yearBuiltRange', {
              low: low ?? 1900,
              high: high ?? new Date().getFullYear(),
            })
          }
        />
      </FilterComponent>
      <Divider />

      <FilterComponent
        label="Vacancy"
        filterType="isVacant"
        clearFilter={clearFilter}
      >
        <ToggleFilterInput
          value={
            filter.isVacant === undefined
              ? null
              : filter.isVacant
              ? 'Vacant'
              : 'Occupied'
          }
          onChange={(value) => updateFilter('isVacant', value === 'Vacant')}
          options={[
            {value: 'Vacant', label: 'Vacant'},
            {value: 'Occupied', label: 'Occupied'},
          ]}
        />
      </FilterComponent>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          submitFilter(filter)
        }}
        disabled={disabled}
        fullWidth
      >
        Submit
      </Button>
    </Paper>
  )
}
