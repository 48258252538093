import React from 'react'

import {Box, Stack, Typography, Button} from '@mui/material'

import {PurchasingPowerModal} from 'src/components/copilot/profile/PurchasingPowerModal'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {purchasingPowerApiCoPilotAccountPath} from 'src/generated/routes'
import {useCopilotAccount} from 'src/hooks/request/useCopilotAccount'
import {useShow} from 'src/hooks/request/useShow'
import {useToggle} from 'src/hooks/util/useToggle'
import {trackEvent} from 'src/util/analytics'
import {formatCurrency} from 'src/util/format'

export const PurchasingPowerSection = () => {
  const {user} = useCopilotAccount()

  const hasConnectedFinancials = React.useMemo(() => {
    return user?.connectedAccounting || user?.connectedBanking
  }, [user])

  const {entity: abilityToPay} = useShow<{purchasingPower: string}>(
    purchasingPowerApiCoPilotAccountPath(),
    {autoRequest: true},
  )

  const [
    purchasingPowerModalOpen,
    openPurchasingPowerModalOpen,
    closePurchasingPowerModalOpen,
  ] = useToggle(false)

  return (
    <Box textAlign="left" width="100%">
      <Typography variant="h4">Purchasing Power</Typography>
      <Typography variant="body2" gutterBottom>
        Understand how much property your business can afford to play offense
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">
          {abilityToPay?.purchasingPower
            ? formatCurrency(abilityToPay.purchasingPower, {precision: 0})
            : '$ _ , _ _ _ , _ _ _'}
        </Typography>

        {hasConnectedFinancials && abilityToPay?.purchasingPower && (
          <Box
            sx={{
              borderRadius: '50%',
              border: '1px solid green',
              backgroundColor: 'green',
              p: 0.5,
              display: 'flex',
            }}
          >
            <MaterialSymbolIcon fontSize="small" sx={{color: 'white'}}>
              north_east
            </MaterialSymbolIcon>
          </Box>
        )}
        {hasConnectedFinancials && !abilityToPay?.purchasingPower && (
          <Box
            sx={{
              borderRadius: '50%',
              border: '1px solid #ff9800',
              backgroundColor: '#ff9800',
              p: 0.5,
              display: 'flex',
            }}
          >
            <MaterialSymbolIcon fontSize="small" sx={{color: 'white'}}>
              schedule
            </MaterialSymbolIcon>
          </Box>
        )}
        {!hasConnectedFinancials && (
          <Button
            onClick={() => {
              trackEvent('Open Purchasing Power Modal')
              openPurchasingPowerModalOpen()
            }}
            sx={{
              borderRadius: '50%',
              border: '1px solid red',
              backgroundColor: 'red',
              p: 0.5,
              display: 'flex',
              minWidth: '30px',
            }}
          >
            <MaterialSymbolIcon fontSize="small" sx={{color: 'white'}}>
              priority_high
            </MaterialSymbolIcon>
          </Button>
        )}
      </Stack>
      {hasConnectedFinancials && !abilityToPay?.purchasingPower && (
        <Typography variant="body2" sx={{color: '#ff9800'}}>
          We&apos;re analysing your accounting data to calculate your purchasing
          power. This usually takes about 5 minutes.
        </Typography>
      )}
      <PurchasingPowerModal
        open={purchasingPowerModalOpen}
        onClose={closePurchasingPowerModalOpen}
      />
    </Box>
  )
}
