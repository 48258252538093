export const formatDate = (value: string | null | undefined): string => {
  if (!value) return '-'

  const secondsSinceEpoch = Date.parse(value)
  return new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(new Date(secondsSinceEpoch))
}

export const formatDateAsYear = (value: string | null | undefined): string => {
  if (!value) return '-'

  const secondsSinceEpoch = Date.parse(value)
  return new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    year: 'numeric',
  }).format(new Date(secondsSinceEpoch))
}
