import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {Modal, Box, IconButton} from '@mui/material'
import {grey} from '@mui/material/colors'

import {ClaimedAsLeasePage} from 'src/components/copilot/claimYourSpace/ClaimedAsLeasePage'
import {ClaimedAsOwnPage} from 'src/components/copilot/claimYourSpace/ClaimedAsOwnPage'
import {LeaseOrOwnPage} from 'src/components/copilot/claimYourSpace/LeaseOrOwnPage'
import {LeaseProcessingPage} from 'src/components/copilot/claimYourSpace/LeaseProcessingPage'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {useClaimedParcels} from 'src/hooks/request/useClaimedParcels'
import {ClaimedLocation, Parcel} from 'src/types/copilot'
import {CustomerLeaseStatus} from 'src/types/copilot/customerLeaseStatus'
import {trackEvent} from 'src/util/analytics'

type ModalPage =
  | 'leaseOrOwn'
  | 'claimedAsLease'
  | 'claimedAsOwn'
  | 'leaseProcessing'

const backNavigationRouting: Record<ModalPage, ModalPage | null> = {
  leaseOrOwn: null,
  claimedAsLease: 'leaseOrOwn',
  claimedAsOwn: 'leaseOrOwn',
  leaseProcessing: 'claimedAsLease',
}

const startingPage = (
  claimedLocation: ClaimedLocation | undefined,
): ModalPage => {
  if (!!claimedLocation?.leaseOrOwn) {
    return claimedLocation.leaseOrOwn == 'lease'
      ? 'claimedAsLease'
      : 'claimedAsOwn'
  }

  return 'leaseOrOwn'
}

interface Props {
  claimedLocation?: ClaimedLocation
  open: boolean
  onClose: () => void
  parcel: Parcel
}

export const ClaimYourSpaceModal = ({
  claimedLocation,
  open,
  onClose,
  parcel,
}: Props) => {
  const [page, setPage] = React.useState<ModalPage>(
    startingPage(claimedLocation),
  )
  const [leaseStatus, setLeaseStatus] = React.useState<CustomerLeaseStatus>()

  const {refetch: refetchClaimedParcels} = useClaimedParcels()

  const backButtonDestination = React.useMemo(() => {
    return backNavigationRouting[page]
  }, [page])

  const handleClose = React.useCallback(async () => {
    await refetchClaimedParcels()
    onClose()
  }, [onClose, refetchClaimedParcels])

  React.useEffect(() => {
    if (open)
      trackEvent('Open Claim Your Space', {
        propertyId: parcel.taxAssessorId,
        address: parcel.address,
      })
  }, [open, parcel])

  React.useEffect(() => {
    if (open && page) {
      trackEvent('View Claim Your Space Modal', {
        propertyId: parcel.taxAssessorId,
        address: parcel.address,
        page,
      })
    }
  }, [open, page, parcel])

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        height="100%"
        width="100%"
        bgcolor="background.paper"
        overflow="scroll"
      >
        {backButtonDestination && (
          <IconButton
            onClick={() => setPage(backButtonDestination)}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: grey[500],
              zIndex: 51,
            }}
          >
            <MaterialSymbolIcon sx={{fontSize: 24}}>
              arrow_back
            </MaterialSymbolIcon>
          </IconButton>
        )}
        <IconButton
          onClick={() => {
            trackEvent('Close Claim Your Space Modal', {
              propertyId: parcel.taxAssessorId,
              address: parcel.address,
              page,
            })
            handleClose()
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: grey[500],
            zIndex: 51,
          }}
        >
          <CloseIcon />
        </IconButton>
        {page === 'leaseOrOwn' && (
          <LeaseOrOwnPage
            parcel={parcel}
            onSelectLease={() => setPage('claimedAsLease')}
            onSelectOwn={() => setPage('claimedAsOwn')}
          />
        )}
        {page === 'claimedAsLease' && (
          <ClaimedAsLeasePage
            parcel={parcel}
            onLeaseUpload={(leaseStatus: CustomerLeaseStatus) => {
              setLeaseStatus(leaseStatus)
              setPage('leaseProcessing')
            }}
          />
        )}
        {page === 'leaseProcessing' && leaseStatus && (
          <LeaseProcessingPage
            leaseId={leaseStatus.id}
            onComplete={handleClose}
          />
        )}
        {page === 'claimedAsOwn' && (
          <ClaimedAsOwnPage parcel={parcel} onComplete={handleClose} />
        )}
      </Box>
    </Modal>
  )
}
