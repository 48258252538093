import * as React from 'react'

import {Button} from '@mui/material'
import {grey} from '@mui/material/colors'
import {Box, Stack, useTheme} from '@mui/system'

import {InputData} from 'src/components/copilot/UserInput'
import {ChatSelectOption} from 'src/types/copilot'
import {RecommendationOption} from 'src/types/copilot/message'

export interface Props {
  visible: boolean
  options: ChatSelectOption[]
  recommendationMode: RecommendationOption | null
  onSubmit: (inputData?: InputData) => void
}

export const RecommendationCarousel = ({
  visible,
  options,
  recommendationMode,
  onSubmit,
}: Props): JSX.Element | null => {
  const theme = useTheme()

  const isCurrentMode = React.useCallback(
    (option: ChatSelectOption) => {
      return option.value === recommendationMode
    },
    [recommendationMode],
  )

  if (!visible) {
    return null
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        height: '45px',
        width: '100%',
        bottom: 0,
        zIndex: 2000,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        backgroundColor: 'transparent',
        backdropFilter: 'blur(4px)',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowX: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          animation: 'slideUpFadeIn 0.5s ease-in',
          '@keyframes slideUpFadeIn': {
            from: {
              transform: 'translateY(20px)',
              opacity: 0,
            },
            to: {
              transform: 'translateY(0)',
              opacity: 1,
            },
          },
        }}
      >
        <Stack
          direction="row"
          minWidth="max-content"
          spacing={1}
          alignItems="center"
        >
          {options.map((option, index) => (
            <Button
              key={index}
              sx={{
                fontWeight: '400',
                fontSize: '0.8125rem',
                backgroundColor: '#fff',
                borderColor: '#000',
                maxHeight: '30px',
                '&:hover': {
                  backgroundColor: grey[300],
                },
                '&.Mui-disabled': {
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                },
              }}
              variant="outlined"
              disabled={isCurrentMode(option)}
              color="secondary"
              onClick={() =>
                onSubmit({value: option.value, text: option.label})
              }
            >
              {option.label}
            </Button>
          ))}
        </Stack>
      </Box>
    </Box>
  )
}
