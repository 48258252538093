import * as React from 'react'

// The React components are in public beta and mapbox has an issue
// where they are not registering their custom HTML components
// before letting React take over so we'll see a console error about this on first mount
import {GeocodeFeature} from '@mapbox/search-js-core'
import {Geocoder} from '@mapbox/search-js-react'
import {LngLatBounds, LngLatBoundsLike} from 'mapbox-gl'

import {ProgressBox} from 'src/framework/ui/ProgressBox'
import {searchApiCoPilotParcelsPath} from 'src/generated/routes'
import {useShow} from 'src/hooks/request/useShow'
import {Parcel} from 'src/types/copilot'

type GeocoderSearchProps = {
  fetchNearbyBusinesses?: boolean
  input: string
  onParcelFound: (parcel: Parcel) => void
  onNonParcelSelected: (bbox: LngLatBoundsLike | undefined) => void
  setInput: (input: string) => void
}

export const GeocoderSearch = ({
  fetchNearbyBusinesses = false,
  input,
  onParcelFound,
  onNonParcelSelected,
  setInput,
}: GeocoderSearchProps): JSX.Element | null => {
  const publicToken = React.useRef(
    document.querySelector<HTMLMetaElement>('meta[name="mapbox"]')?.content,
  )
  const {fetch: searchParcelByAddress, loading: searchInProgress} =
    useShow<Parcel>(searchApiCoPilotParcelsPath())

  const onChange = React.useCallback((v: string) => setInput(v), [setInput])

  const handleSearchSelection = React.useCallback(
    (feature: GeocodeFeature) => {
      if (feature) {
        const address = feature.properties.context.address?.name
        if (!address) {
          onNonParcelSelected(
            feature.properties.bbox as Exclude<LngLatBoundsLike, LngLatBounds>,
          )
          return
        }
        setInput(feature.properties.full_address)
        searchParcelByAddress({
          params: {
            address: feature.properties.full_address,
            ...(fetchNearbyBusinesses ? {fetch_businesses: 'true'} : {}),
          },
        }).then(({entity: parcel}) => {
          if (parcel) {
            onParcelFound(parcel)
          }
        })
      }
    },
    [
      fetchNearbyBusinesses,
      onParcelFound,
      searchParcelByAddress,
      setInput,
      onNonParcelSelected,
    ],
  )

  React.useEffect(() => {
    // TODO: this flashes the original height once
    // editing `application.css` doesn't work - probably due to shadow DOM bs
    // or the custom stylesheet mapbox injects inline with the component
    const applyStyles = () => {
      // Target the custom element
      const geocoderElement = document.querySelector('mapbox-geocoder')
      if (!geocoderElement) return

      // Style the Geocoder container
      const geocoderDiv = geocoderElement.querySelector('[class*="--Geocoder"]')
      if (geocoderDiv) {
        // @ts-expect-error style doesn't exist on Element
        geocoderDiv.style.height = '46px'
      }

      // Style the Geocoder input
      const geocoderInput = geocoderElement.querySelector('[class*="--Input"]')
      if (geocoderInput) {
        // @ts-expect-error style doesn't exist on Element
        geocoderInput.style.height = '46px'
      }
    }

    // Wait for the Geocoder to mount
    const timeout = setTimeout(applyStyles, 0)

    // Cleanup
    return () => clearTimeout(timeout)
  })

  if (publicToken.current === undefined) {
    return null
  }

  return (
    <ProgressBox loading={searchInProgress} height="46px">
      <Geocoder
        accessToken={publicToken.current}
        marker={false}
        options={{
          country: 'us',
          bbox: [-78.995048, 35.519458, -78.253711, 36.076443],
        }}
        value={input}
        onChange={onChange}
        onRetrieve={handleSearchSelection}
      />
    </ProgressBox>
  )
}
