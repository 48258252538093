import * as React from 'react'

import {Typography} from '@mui/material'
import {grey} from '@mui/material/colors'
import {Box} from '@mui/system'

import {
  MobileSwipeDrawer,
  MobileSwipeDrawerProps,
} from 'src/framework/ui/MobileSwipeDrawer'

export type DrawerProps = MobileSwipeDrawerProps & {
  title?: string
  caption?: string
}

export const Drawer = React.memo(
  ({
    title,
    caption,
    children,
    ...rest
  }: React.PropsWithChildren<DrawerProps>) => {
    return (
      <MobileSwipeDrawer {...rest}>
        <Box px={2} pb={2}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box mb={1}>
              {title && (
                <Typography variant="h2" textAlign="center" mb={1}>
                  {title}
                </Typography>
              )}
              {caption && (
                <Typography variant="caption" mb={1} color={grey[700]}>
                  {caption}
                </Typography>
              )}
            </Box>
            <Box mb={1} flexGrow={1}>
              {children}
            </Box>
          </Box>
        </Box>
      </MobileSwipeDrawer>
    )
  },
)

Drawer.displayName = 'Drawer'
