import {createSlice} from '@reduxjs/toolkit'

import {NavPage} from 'src/hooks/copilot/useNavPage'

interface NavPageState {
  page: NavPage
}

const initialState: NavPageState = {
  page: 'Search',
}

export const navPageSlice = createSlice({
  name: 'navPage',
  initialState,
  reducers: {
    setNavPage: (state, action) => {
      const payload = action.payload
      state.page = payload
    },
  },
})

export const {setNavPage} = navPageSlice.actions
export default navPageSlice.reducer
