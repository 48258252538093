export enum ExpenseResponsibility {
  Tenant = 'tenant',
  Landlord = 'landlord',
  Both = 'both',
}

export type CustomerLease = {
  id: string
  buildingSqFt: number | null
  cherreParcelId: string | null
  parcelBoundaryGeoJson: GeoJSON.GeoJSON | null
  parcelCentroid: GeoJSON.Point | null
  address: string | null
  leaseCommencementDate: string | null
  leaseExpirationDate: string | null
  landlordName: string | null
  tenantName: string | null
  netRentableArea: number | null
  propertySF: number | null
  multiTenant: boolean
  propertyType: string | null
  propertyUse: string | null
  propertyTaxResponsibleParty: ExpenseResponsibility | null
  propertyInsuranceResponsibleParty: ExpenseResponsibility | null
  propertyUtilitiesResponsibleParty: ExpenseResponsibility | null
  repairsAndMaintenanceResponsibleParty: ExpenseResponsibility | null
  commonAreaMaintenanceResponsibleParty: ExpenseResponsibility | null
  renewalOption: boolean
  numRenewals: number | null
  lengthPerRenewal: string | null
  rentRenewalType: string | null
  earlyTerminationOption: boolean
  breakDate: string | null
  purchaseOption: boolean
  purchaseOptionSaleDate: string | null
  purchaseOptionSalePrice: number | null
  taxAssessorId: number | null
}
