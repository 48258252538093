import React from 'react'

import {Typography, Box, Button, Icon, Stack} from '@mui/material'
import {grey} from '@mui/material/colors'

import {useClaimedLocations} from 'src/hooks/request/useClaimedLocations'
import {Parcel} from 'src/types/copilot'
import {formatCurrencyCompact} from 'src/util/format/formatCurrencyCompact'

interface Props {
  handleClaimSpace: () => void
  parcel: Parcel
}

export const RentVsBuyWidget = ({handleClaimSpace, parcel}: Props) => {
  const {isClaimedLocation} = useClaimedLocations()

  const isClaimed = React.useMemo(() => {
    return isClaimedLocation(parcel.taxAssessorId)
  }, [isClaimedLocation, parcel])

  return (
    <Stack
      direction="row"
      m={1.5}
      p={1}
      gap={1}
      alignItems="center"
      borderRadius={1}
      bgcolor={grey[100]}
    >
      <Icon
        color="secondary"
        sx={{
          fontSize: 40,
          margin: '0 16px 2px',
        }}
      >
        balance
      </Icon>
      <Box gap={0.5}>
        <Typography variant="h6" sx={{fontWeight: 'bold'}}>
          Rent vs. Buy
        </Typography>
        <Typography variant="body2">
          {`Over the next 10 years, we estimate you'd pay ${formatCurrencyCompact(
            parcel.rentVsBuy.totalRentPaid,
          )} in rent vs. owning a ${formatCurrencyCompact(
            parcel.rentVsBuy.futureSalesPrice,
          )} building`}
        </Typography>
        <Button
          variant="text"
          sx={{padding: 0}}
          onClick={handleClaimSpace}
          disabled={isClaimed}
        >
          Claim this space as yours
        </Button>
      </Box>
    </Stack>
  )
}
