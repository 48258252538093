import * as React from 'react'
import {Layer, Source, MapboxMap, MapRef} from 'react-map-gl'

import {
  Stack,
  Box,
  Button,
  Typography,
  Paper,
  IconButton,
  Switch,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
} from '@mui/material'
import chroma from 'chroma-js'

import {AdmittedUserContainer} from 'src/components/copilot/AdmittedUserContainer'
import {RadioFilterInput} from 'src/components/copilot/RadioFilterInput'
import {SelectFilterInput} from 'src/components/copilot/SelectFilterInput'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {MobileSwipeDrawer} from 'src/framework/ui/MobileSwipeDrawer'
import {apiCoPilotParcelsNearbyBusinessesPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {Parcel} from 'src/types/copilot'
import {trackEvent} from 'src/util/analytics'

const STEPS = 4

type MinMax = {
  min: number
  max: number
}
export type GeographyOverlayMinMax = Record<GeographyOverlay, MinMax>

type GeographyOverlayLegendLabel = {
  label: string
  // TODO: how to deal with singulars?
  unit: string
  colorExpression: ReturnType<typeof generateFillColorExpression>
  prefix?: boolean
  formatter: (value: number) => string
}

export type GeographyOverlayRecord = Record<
  GeographyOverlay,
  GeographyOverlayLegendLabel
>

export const DEMOGRAPHICS_SOURCE = 'demographics'

function toFixedN(precision: number) {
  return (value: number): string => {
    return Number(value.toFixed(precision)).toLocaleString()
  }
}

function ratioToPercent(ratio: number): string {
  if (ratio < 1) {
    return Number(((1 - ratio) * -100).toFixed(1)).toLocaleString()
  } else if (ratio === 1) {
    return '100'
  } else {
    return Number(((ratio - 1) * 100).toFixed(1)).toLocaleString()
  }
}

export const GEOGRAPHY_OVERLAYS = {
  population_median_age_total: {
    label: 'Median Age',
    unit: ' years old',
    color: 'Blues',
    formatter: toFixedN(2),
  },
  population_count_total: {
    label: 'Population',
    unit: ' people',
    color: 'Reds',
    formatter: toFixedN(0),
  },
  population_count_change_ratio_since_2010: {
    label: 'Population % Change since 2010',
    unit: '%',
    color: 'Greens',
    formatter: ratioToPercent,
  },
  household_median_income_total: {
    label: 'Median Household Income',
    unit: '$',
    prefix: true,
    color: 'Oranges',
    formatter: toFixedN(0),
  },
  household_median_income_change_ratio_since_2010: {
    label: 'Median Household Income % Change since 2010',
    unit: '%',
    color: 'Greys',
    formatter: ratioToPercent,
  },
  household_with_children: {
    label: 'Households with Children',
    unit: ' households',
    color: 'Purples',
    formatter: toFixedN(0),
  },
  commute_car_commuters: {
    label: 'Car Commuters',
    unit: ' commuters',
    color: 'PuRd',
    formatter: toFixedN(0),
  },
  commute_walk_commuters: {
    label: 'Walk Commuters',
    unit: ' commuters',
    color: 'GnBu',
    formatter: toFixedN(0),
  },
  education_bachelors_total: {
    label: 'College Educated',
    unit: ' people',
    color: 'RdBu',
    formatter: toFixedN(0),
  },
  education_bachelors_change_ratio_since_2010: {
    label: 'College Educated % Change since 2010',
    unit: '%',
    color: 'OrRd',
    formatter: ratioToPercent,
  },
  labor_labor_force_count_total: {
    label: 'Labor Force',
    unit: ' people',
    color: 'RdYlBu',
    formatter: toFixedN(0),
  },
} as const

export type GeographyOverlay = keyof typeof GEOGRAPHY_OVERLAYS

export function initializeGeographyOverlayRecord(map: MapboxMap) {
  const newRecord = {} as GeographyOverlayRecord
  const features = map.querySourceFeatures('composite', {
    sourceLayer: DEMOGRAPHICS_SOURCE,
  })

  ;(Object.keys(GEOGRAPHY_OVERLAYS) as GeographyOverlay[]).forEach(
    (overlay) => {
      const minValue = features.reduce((min, feature) => {
        if (feature.properties === null) {
          return min
        }
        const value = feature.properties[overlay]
        return value <= min ? value : min
      }, Infinity)

      const maxValue = features.reduce((max, feature) => {
        if (feature.properties === null) {
          return max
        }
        const value = feature.properties[overlay]
        return value > max ? value : max
      }, -Infinity)
      const colorExpression = generateFillColorExpression(
        minValue,
        maxValue,
        overlay,
        GEOGRAPHY_OVERLAYS[overlay].color,
        STEPS,
      )
      newRecord[overlay] = {
        colorExpression,
        ...GEOGRAPHY_OVERLAYS[overlay],
      }
    },
  )
  return newRecord
}

export function generateFillColorExpression(
  min: number,
  max: number,
  property: GeographyOverlay,
  colorScale: keyof typeof chroma.brewer,
  steps: number,
): [string, [string], [string, string], ...(string | number)[]] | null {
  // Generate step values
  const stepSize = (max - min) / steps
  if (stepSize === 0) {
    return null
  }
  const stepValues = Array.from(
    {length: steps + 1},
    (_, i) => min + i * stepSize,
  )

  // Generate colors using chroma.js
  const colors = chroma.scale(colorScale).colors(steps + 1)

  // Build interpolation expression
  const expression: [
    string,
    [string],
    [string, string],
    ...(string | number)[],
  ] = ['interpolate', ['linear'], ['get', property]]
  stepValues.forEach((value, index) => {
    expression.push(value, colors[index])
  })
  return expression
}

type GeographyOverlayProps = {
  overlay: GeographyOverlay | null
  overlayRecord: GeographyOverlayRecord
}

export function GeographyOverlay({
  overlay,
  overlayRecord,
}: GeographyOverlayProps) {
  return (
    <>
      {(Object.keys(GEOGRAPHY_OVERLAYS) as GeographyOverlay[]).map((layer) => {
        const label = overlayRecord[layer]
        if (!label || !label.colorExpression) {
          return null
        }
        return (
          <Layer
            key={layer}
            id={layer}
            type="fill"
            source="composite"
            source-layer={DEMOGRAPHICS_SOURCE}
            layout={{visibility: overlay === layer ? 'visible' : 'none'}}
            paint={{
              'fill-color': label.colorExpression,
              'fill-opacity': 0.3,
            }}
            beforeId="neighborhoods"
          />
        )
      })}
    </>
  )
}

type LegendItem = {color: string; value: number}

function colorsToLegend(colors: (string | number)[]): LegendItem[] {
  const final: LegendItem[] = []
  let temp: LegendItem | undefined = undefined
  colors.map((item) => {
    if (typeof item === 'string') {
      temp = {color: item, value: 0}
    }
    if (typeof item === 'number' && temp !== undefined) {
      temp.value = item
      final.push(temp)
      temp = undefined
    }
  })
  return final
}

type GeographyLegendProps = {
  overlay: GeographyOverlay | null
  overlayRecord: GeographyOverlayRecord
}

export function GeographyLegend({
  overlay,
  overlayRecord,
}: GeographyLegendProps) {
  if (!overlay) {
    return null
  }
  const label = overlayRecord[overlay]
  if (!label.colorExpression) {
    return (
      <Stack
        padding={1}
        direction="column"
        gap={1}
        fontSize="12px"
        sx={{backgroundColor: '#FFFFFF', borderRadius: '2px'}}
      >
        <Typography>{label.label}</Typography>
        <Typography>No Data</Typography>
      </Stack>
    )
  }
  const legendItems = colorsToLegend(
    label.colorExpression.slice(3) as (string | number)[],
  )
  return (
    <Stack
      padding={1}
      direction="column"
      gap={1}
      fontSize="12px"
      sx={{backgroundColor: '#FFFFFF', borderRadius: '2px'}}
    >
      <Typography>{label.label}</Typography>
      {legendItems.map(({color, value}, idx) => {
        return (
          <Stack
            key={color + value}
            direction="row"
            gap={1}
            alignItems="center"
            maxWidth="100%"
            sx={{breakInside: 'avoid'}}
          >
            <Box
              sx={{
                width: '18px',
                height: '18px',
                backgroundColor: color,
                borderRadius: 1,
                border: '1px solid #000',
              }}
            ></Box>
            <div>
              {idx === 0 ? '< ' : idx === legendItems.length - 1 ? '> ' : ''}
              {label.prefix ? label.unit : null}
              {label.formatter(value)}
              {label.prefix ? null : label.unit}
            </div>
          </Stack>
        )
      })}
    </Stack>
  )
}

type BaseOverlayProps = {active: boolean}

type TrafficProps = {
  mapRef: React.RefObject<MapRef>
} & BaseOverlayProps

export function TrafficLayer({active, mapRef}: TrafficProps) {
  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }
    const map = mapRef.current.getMap()

    if (map.getLayer('traffic')) {
      map.setLayoutProperty(
        'traffic',
        'visibility',
        active ? 'visible' : 'none',
      )
    }
  }, [active, mapRef])

  return null
}

type SatelliteProps = {
  mapRef: React.RefObject<MapRef>
} & BaseOverlayProps

export function SatelliteLayer({active, mapRef}: SatelliteProps) {
  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }
    const map = mapRef.current.getMap()

    if (map.getLayer('satellite')) {
      map.setLayoutProperty(
        'satellite',
        'visibility',
        active ? 'visible' : 'none',
      )
    }
  }, [active, mapRef])

  return null
}

type BusinessCategory =
  | 'accommodation'
  | 'agriculture'
  | 'automotive'
  | 'building & home services'
  | 'child care'
  | 'education'
  | 'fitness & recreation'
  | 'food & beverage'
  | 'grocery & convenience'
  | 'heavy industrial'
  | 'light industrial'
  | 'local services'
  | 'medical'
  | 'personal care'
  | 'pet care'
  | 'professional services'
  | 'public sector'
  | 'retail'

type NearbyBusiness = {
  displayName: string
  address: string
  taxAssessorId: string
  location: GeoJSON.Point
}

type NearbyBusinessesProps = {
  categories: BusinessCategory[]
  parcel: Parcel
} & BaseOverlayProps

export function NearbyBusinessesLayer({
  active,
  parcel,
  categories,
}: NearbyBusinessesProps) {
  const {request, response, completed} = useRequest<
    NearbyBusiness[],
    void,
    {taxAssessorId: string; categories: string}
  >('GET', apiCoPilotParcelsNearbyBusinessesPath())
  const theme = useTheme()

  React.useEffect(() => {
    if (active && categories.length > 0) {
      request({
        params: {
          taxAssessorId: parcel.taxAssessorId,
          categories: categories.join(','),
        },
      })
    }
  }, [active, parcel, categories, request])

  const asGeoJSON: GeoJSON.FeatureCollection | null = React.useMemo(() => {
    if (response === null) {
      return null
    }

    if (response.length === 0) {
      // TODO: pre-load all categories and do client side filtering in this component
      console.log('no nearby businesses')
    }
    // TODO: need to adjust zoom of map to account for farther nearby businesses?
    // maybe can actually just set default zoom to account for that distance?
    return {
      type: 'FeatureCollection',
      features: response.map((nb) => {
        return {
          type: 'Feature',
          geometry: nb.location,
          properties: {
            displayName: nb.displayName,
            taxAssessorId: nb.taxAssessorId,
            address: nb.address,
          },
        }
      }),
    }
  }, [response])

  if (!active || categories.length === 0 || !completed || asGeoJSON === null) {
    return null
  }

  return (
    <Source
      key="nearby-businesses"
      id="nearby-businesses"
      type="geojson"
      data={asGeoJSON}
    >
      <Layer
        key="nearby-businesses-layer"
        id="nearby-businesses-layer"
        source="nearby-businesses"
        type="circle"
        layout={{
          visibility: active ? 'visible' : 'none',
        }}
        // TODO: nicer paint settings
        paint={{
          'circle-color': '#FFFFFF',
          'circle-radius': 10,
          'circle-stroke-color': theme.palette.primary.main,
          'circle-stroke-width': 2,
          'circle-opacity': 1,
          'circle-stroke-opacity': 1,
        }}
      ></Layer>
    </Source>
  )
}

export type AllOverlaysSettings = {
  geography: {overlay: GeographyOverlay | null} & BaseOverlayProps
  traffic: BaseOverlayProps
  satellite: BaseOverlayProps
  nearbyBusinesses: {categories: BusinessCategory[]} & BaseOverlayProps
}

type OverlayControlsButtonProps = {
  initialOverlays: AllOverlaysSettings
  onChange: (newOverlays: AllOverlaysSettings) => void
  withNearbyBusinesses?: boolean
}

export const OverlayControlsButton = ({
  initialOverlays,
  onChange,
  withNearbyBusinesses,
}: OverlayControlsButtonProps): JSX.Element => {
  const [renderDrawer, setRenderDrawer] = React.useState(false)

  const handleClick = React.useCallback(
    (e) => {
      e.stopPropagation()
      trackEvent(`Open ${withNearbyBusinesses ? 'Property Map ' : ''}Overlays`)
      setRenderDrawer(true)
    },
    [withNearbyBusinesses],
  )

  return (
    <>
      <AdmittedUserContainer
        attemptedActionName="openOverlays"
        onCreateAccount={() => setRenderDrawer(true)}
      >
        <IconButton
          color="secondary"
          sx={{
            backgroundColor: '#fff',
            aspectRatio: '1 / 1',
            height: '46px',
            width: '46px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'rgba(0,0,0,0.23)', // TODO: use theme
            position: 'relative',
          }}
          onClick={handleClick}
        >
          <MaterialSymbolIcon>graphic_eq</MaterialSymbolIcon>
        </IconButton>
      </AdmittedUserContainer>
      {renderDrawer && (
        <OverlaysDrawer
          initialOverlays={initialOverlays}
          onClose={() => setRenderDrawer(false)}
          applyOverlays={onChange}
          withNearbyBusinesses={withNearbyBusinesses}
        />
      )}
    </>
  )
}

type OverlaysDrawerProps = {
  initialOverlays: AllOverlaysSettings
  onClose: () => void
  applyOverlays: (overlays: AllOverlaysSettings) => void
  withNearbyBusinesses?: boolean
}

export const OverlaysDrawer = ({
  initialOverlays,
  onClose,
  applyOverlays,
  withNearbyBusinesses,
}: OverlaysDrawerProps): JSX.Element => {
  return (
    <MobileSwipeDrawer
      open={true}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      title={'Overlays'}
      big={true}
    >
      <Box px={2} py={2}>
        <OverlaysForm
          initialOverlays={initialOverlays}
          submitOverlays={(newOverlays: AllOverlaysSettings) => {
            applyOverlays(newOverlays)
            onClose()
          }}
          withNearbyBusinesses={withNearbyBusinesses}
        />
      </Box>
    </MobileSwipeDrawer>
  )
}

type OverlayControlProps = {
  label: string
  active: boolean
  children: React.ReactNode
  onToggle: () => void
}
const OverlayControl = ({
  label,
  children,
  active,
  onToggle,
}: OverlayControlProps) => {
  return (
    <Accordion
      defaultExpanded
      disableGutters
      sx={{border: 'none', '&::before': {display: 'none'}, paddingX: 0}}
    >
      <AccordionSummary
        expandIcon={<MaterialSymbolIcon>expand_more</MaterialSymbolIcon>}
        sx={{paddingX: 0}}
      >
        <Typography variant="h2">{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" spacing={2} alignItems="flex-start">
          {/* TODO: this switch UX sucks */}
          <Switch
            checked={active}
            onChange={onToggle}
            inputProps={{'aria-label': 'controlled'}}
          />
          {children}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

type OverlaysFormProps = {
  initialOverlays: AllOverlaysSettings
  submitOverlays: (overlays: AllOverlaysSettings) => void
  withNearbyBusinesses?: boolean
}

const OverlaysForm: React.FC<OverlaysFormProps> = ({
  initialOverlays,
  submitOverlays,
  withNearbyBusinesses,
}) => {
  const [overlays, setOverlays] =
    React.useState<AllOverlaysSettings>(initialOverlays)

  const toggleOverlay = <K extends keyof AllOverlaysSettings>(key: K) => {
    trackEvent(`Toggle ${withNearbyBusinesses ? 'Property Map ' : ''}Overlay`, {
      overlay: key,
      active: !overlays[key].active,
    })

    setOverlays({
      ...overlays,
      [key]: {active: !overlays[key].active},
    })
  }
  const updateOverlay = <K extends keyof AllOverlaysSettings>(
    key: K,
    value: Omit<AllOverlaysSettings[K], 'active'>,
  ) => {
    trackEvent(`Set ${withNearbyBusinesses ? 'Property Map ' : ''}Overlay`, {
      overlay: key,
      value,
    })
    setOverlays({...overlays, [key]: {...value, active: overlays[key].active}})
  }

  return (
    <Paper
      sx={{width: '100%', maxWidth: 600, mx: 'auto', p: 2, border: 'none'}}
    >
      <OverlayControl
        label="Geography"
        active={overlays.geography.active}
        onToggle={() => toggleOverlay('geography')}
      >
        <RadioFilterInput<GeographyOverlay>
          value={overlays.geography.overlay}
          onChange={(value) => updateOverlay('geography', {overlay: value})}
          options={(
            Object.entries(GEOGRAPHY_OVERLAYS) as [
              keyof typeof GEOGRAPHY_OVERLAYS,
              {
                label: string
                unit: string
                color: string
                formatter: (val: number) => string
              },
            ][]
          ).map(([overlay, label]) => {
            return {
              label: label.label,
              value: overlay,
            }
          })}
        />
      </OverlayControl>
      <Divider />

      <OverlayControl
        label="Traffic"
        active={overlays.traffic.active}
        onToggle={() => toggleOverlay('traffic')}
      >
        {<></>}
      </OverlayControl>
      <Divider />

      <OverlayControl
        label="Satellite"
        active={overlays.satellite.active}
        onToggle={() => toggleOverlay('satellite')}
      >
        {<></>}
      </OverlayControl>
      <Divider />

      {withNearbyBusinesses && (
        <OverlayControl
          label="Nearby Businesses"
          active={overlays.nearbyBusinesses.active}
          onToggle={() => toggleOverlay('nearbyBusinesses')}
        >
          <SelectFilterInput<BusinessCategory>
            selections={new Set(overlays.nearbyBusinesses.categories)}
            onChange={(value) =>
              updateOverlay('nearbyBusinesses', {
                categories: value ? [...value] : [],
              })
            }
            options={[
              {value: 'accommodation', label: 'Accommodation'},
              {value: 'agriculture', label: 'Agriculture'},
              {value: 'automotive', label: 'Automotive'},
              {
                value: 'building & home services',
                label: 'Building & Home Services',
              },
              {value: 'child care', label: 'Child Care'},
              {value: 'education', label: 'Education'},
              {value: 'fitness & recreation', label: 'Fitness & Recreation'},
              {value: 'food & beverage', label: 'Food & Beverage'},
              {value: 'grocery & convenience', label: 'Grocery & Convenience'},
              {value: 'heavy industrial', label: 'Heavy Industrial'},
              {value: 'light industrial', label: 'Light Industrial'},
              {value: 'local services', label: 'Local Services'},
              {value: 'medical', label: 'Medical'},
              {value: 'personal care', label: 'Personal Care'},
              {value: 'pet care', label: 'Pet Care'},
              {value: 'professional services', label: 'Professional Services'},
              {value: 'public sector', label: 'Public Sector'},
              {value: 'retail', label: 'Retail'},
            ]}
          />
        </OverlayControl>
      )}

      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          trackEvent(
            `Apply ${withNearbyBusinesses ? 'Property Map ' : ''}Overlays`,
            {overlays},
          )
          submitOverlays(overlays)
        }}
        fullWidth
        sx={{
          marginTop: 2,
        }}
      >
        Apply
      </Button>
    </Paper>
  )
}
