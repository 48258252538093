import * as React from 'react'

import {useMutex} from 'src/contexts/mutex/useMutex'
import {apiCoPilotParcelsPath} from 'src/generated/routes'
import {useClaimedLocations} from 'src/hooks/request/useClaimedLocations'
import {useIndex} from 'src/hooks/request/useIndex'
import {
  setClaimedParcels,
  removeClaimedParcels,
} from 'src/store/partners/claimedParcelsSlice'
import {useAppDispatch, useAppSelector} from 'src/store/partners/store'
import {Parcel} from 'src/types/copilot'
import {ParsedIndexResponse} from 'src/util/request/parseResponse'

export const useClaimedParcels = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((state) => state.claimedParcels)
  const {getLock} = useMutex()

  // TODO: the relationship between claimedLocations and claimedParcels is completely fucked
  const {
    claimedLocations,
    hasBeenFetched: claimedLocationsHaveBeenFetched,
    refetch: fetchClaimedLocations,
  } = useClaimedLocations()

  const requestOptions = React.useMemo(
    () => ({
      suppressedErrorCodes: [401],
      onSuccess: ({entities: claimedParcels}: ParsedIndexResponse<Parcel>) => {
        dispatch(setClaimedParcels(claimedParcels))
      },
      onError: () => {
        dispatch(removeClaimedParcels())
      },
    }),
    [dispatch],
  )

  const {fetch: fetchClaimedParcels} = useIndex<Parcel>(
    apiCoPilotParcelsPath(),
    requestOptions,
  )

  const refetchClaimedLocationsThenParcels = React.useCallback(() => {
    fetchClaimedLocations().then(({entities: latestClaimedLocations}) => {
      if (!latestClaimedLocations) return

      fetchClaimedParcels({
        params: {
          tax_assessor_ids: latestClaimedLocations.map((l) => l.taxAssessorId),
        },
      })
    })
  }, [fetchClaimedLocations, fetchClaimedParcels])

  React.useEffect(() => {
    if (state.items.length > 0) return
    if (!claimedLocationsHaveBeenFetched) return
    if (!getLock('copilotClaimedParcels')) return

    fetchClaimedParcels({
      params: {tax_assessor_ids: claimedLocations.map((l) => l.taxAssessorId)},
    })
  }, [
    state.items,
    getLock,
    fetchClaimedParcels,
    claimedLocationsHaveBeenFetched,
    claimedLocations,
  ])

  return {
    claimedParcels: state.items,
    hasBeenFetched: state.hasBeenFetched,
    refetch: refetchClaimedLocationsThenParcels,
  }
}
