import * as React from 'react'

import {
  Box,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
  IconButton,
  Divider,
} from '@mui/material'
import {grey} from '@mui/material/colors'
import {noop} from 'lodash'

import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'

export type MobileSwipeDrawerProps = Omit<SwipeableDrawerProps, 'onOpen'> & {
  onOpen?: React.ReactEventHandler
  title?: string
  big?: boolean
}

const bigDrawerPaperProps = {
  height: '90vh !important',
  maxHeight: '90vh !important',
  display: 'flex !important',
  flexDirection: 'column !important',
}

export const MobileSwipeDrawer = React.memo(
  ({
    onClose,
    onOpen = noop,
    title,
    open,
    children,
    big = false,
    ...drawerProps
  }: React.PropsWithChildren<MobileSwipeDrawerProps>): JSX.Element => {
    return (
      <SwipeableDrawer
        anchor="bottom"
        PaperProps={{
          sx: {
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            ...(big && bigDrawerPaperProps),
          },
        }}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        {...drawerProps}
      >
        <Box
          sx={{
            height: 40,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            ...(title ? {height: 64} : {}), // Adjust height when showing title
          }}
        >
          {title ? (
            <TitleBar title={title} onClose={onClose} />
          ) : (
            <Box
              p={2}
              sx={{display: 'flex', justifyContent: 'center', width: '100%'}}
            >
              <Puller />
            </Box>
          )}
        </Box>
        {title && <Divider />}
        <Box sx={{flex: 1, overflowY: 'auto', pb: 3}}> {children}</Box>
      </SwipeableDrawer>
    )
  },
)

MobileSwipeDrawer.displayName = 'MobileSwipeDrawer'

const Puller = () => (
  <Box
    sx={{
      width: '32px',
      height: '4px',
      backgroundColor: grey[700],
      borderRadius: '100px',
    }}
  />
)

interface TitleBarProps {
  title: string
  onClose: React.ReactEventHandler
}

const TitleBar = ({title, onClose}: TitleBarProps) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      p: 2,
      position: 'relative',
    }}
  >
    <IconButton
      edge="start"
      color="inherit"
      onClick={onClose}
      aria-label="close"
      sx={{position: 'absolute', left: 8, paddingLeft: 2, padding: '12px'}}
    >
      <MaterialSymbolIcon>close</MaterialSymbolIcon>
    </IconButton>
    <Typography
      variant="h4"
      component="div"
      sx={{
        flex: 1,
        textAlign: 'center',
        mx: 6,
      }}
    >
      {title}
    </Typography>
  </Box>
)
