import React, {useState} from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Modal,
  IconButton,
  FormControl,
  SelectChangeEvent,
  InputLabel,
  Stack,
} from '@mui/material'

import CurrencyInput from 'src/components/copilot/components/CurrencyInput'
import {
  costCalculator,
  PaymentDetails,
} from 'src/components/copilot/costCalculator/CostCalculator'
import {trackEvent, trackEventDebounced} from 'src/util/analytics'
import {formatCurrency} from 'src/util/format/formatCurrency'

interface Props {
  onClose: () => void
  onCalculate: (values: PaymentDetails) => void
  paymentDetails: PaymentDetails
}

const LoanPaymentCalculatorModal: React.FC<Props> = ({
  onClose,
  onCalculate,
  paymentDetails,
}) => {
  const [customPaymentDetails, setCustomPaymentDetails] =
    useState<PaymentDetails>(paymentDetails)

  const {monthlyLoanPayment, adjustedInterestRate} =
    costCalculator(customPaymentDetails)

  const handleBusinessCashflowChange = (value: number) => {
    trackEventDebounced('Adjust Loan Payment Calculator', {
      businessCashflow: value,
    })
    setCustomPaymentDetails((prev) => ({
      ...prev,
      ['businessCashflow']: value,
    }))
  }

  const handleLoanTypeChange = (event: SelectChangeEvent<'7a' | '504'>) => {
    trackEventDebounced('Adjust Loan Payment Calculator', {
      loanType: event.target.value,
    })
    setCustomPaymentDetails((prev) => ({
      ...prev,
      loanType: event.target.value as '7a' | '504',
    }))
  }

  const handleCalculate = () => {
    trackEvent('Apply Loan Payment Calculator', {
      businessCashflow: customPaymentDetails.businessCashflow,
      loanType: customPaymentDetails.loanType,
      interestRate: adjustedInterestRate,
    })
    onCalculate({
      ...customPaymentDetails,
      interestRate: adjustedInterestRate,
    })
    onClose()
  }

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          bgcolor: 'background.paper',
        }}
      >
        <Box px={1.5} pt={3}>
          <Typography variant="h4" textAlign="center">
            Loan Payment Calculator
          </Typography>
          <IconButton
            onClick={() => {
              trackEvent('Close Loan Payment Calculator')
              onClose()
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" textAlign="center" mt={2}>
            Monthly loan payment
          </Typography>
          <Typography variant="h4" fontSize="2rem" textAlign="center" mt={1}>
            {formatCurrency(monthlyLoanPayment.toString(), {precision: 0})}
          </Typography>
          <Stack spacing={2} mt={4.5}>
            <CurrencyInput
              label="Business cashflow"
              value={
                customPaymentDetails.businessCashflow === 0
                  ? null
                  : customPaymentDetails.businessCashflow
              }
              onChange={handleBusinessCashflowChange}
              placeholder={'Profits before loan payments and rent'}
              sx={{'& .MuiOutlinedInput-root': {height: '40px'}}}
            />
            <FormControl fullWidth size="small">
              <InputLabel id={'loan-type-label'}>Loan type</InputLabel>
              <Select
                label="Loan type"
                labelId="loan-type-label"
                name="loanType"
                value={customPaymentDetails.loanType}
                onChange={handleLoanTypeChange}
                sx={{height: '40px'}}
              >
                <MenuItem value="7a">7(a)</MenuItem>
                <MenuItem value="504">504</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="Derived Interest Rate"
              label="Interest rate"
              variant="outlined"
              size="small"
              value={`${(adjustedInterestRate * 100).toFixed(2)}%`}
              disabled
              type="text"
              fullWidth
              inputProps={{
                style: {height: '40px', boxSizing: 'border-box'},
              }}
              sx={{'& .MuiOutlinedInput-root': {height: '40px'}}}
            />
            <TextField
              name="Down payment percent"
              label={'Down payment percent'}
              variant="outlined"
              size="small"
              value={`${(customPaymentDetails.downpaymentPercent * 100).toFixed(
                2,
              )}%`}
              disabled
              type="text"
              fullWidth
              inputProps={{
                style: {height: '40px', boxSizing: 'border-box'},
              }}
              sx={{'& .MuiOutlinedInput-root': {height: '40px'}}}
            />
          </Stack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 1,
            position: 'absolute',
            bottom: 0,
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            onClick={handleCalculate}
            sx={{
              backgroundColor: 'primary',
              color: 'white',
              padding: '10px 20px',
              width: '100%',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'primary',
              },
            }}
          >
            <Typography variant="h4">Calculate</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default LoanPaymentCalculatorModal
