import * as React from 'react'

import {ArrowDropDown, ArrowRight} from '@mui/icons-material'
import {Box, Button, Collapse, Typography} from '@mui/material'
import {Stack} from '@mui/system'

import {AreaFormat} from 'src/framework/form/NumberFormat'
import {OutlinedSelect} from 'src/framework/form/OutlinedSelect'
import {OutlinedTextField} from 'src/framework/form/OutlinedTextField'
import {apiCoPilotLeaseDetailsPath} from 'src/generated/routes'
import {useForm} from 'src/hooks/form/useForm'
import {useRequest} from 'src/hooks/request/useRequest'
import {CustomerLease} from 'src/types/copilot'
import {ExpenseResponsibility} from 'src/types/copilot/customerLease'

type LeaseFormFields = {
  address: string
  buildingSqFt: string
  leaseCommencementDate: string
  nextLeaseExpirationDate: string
  landlordName: string
  tenantName: string
  netRentableArea: string
  multiTenant: boolean
  propertyType: string
  propertyUse: string
  propertyTaxResponsibleParty: string
  propertyInsuranceResponsibleParty: string
  propertyUtilitiesResponsibleParty: string
  repairsAndMaintenanceResponsibleParty: string
  commonAreaMaintenanceResponsibleParty: string
  renewalOption: boolean
  numRenewals: string
  lengthPerRenewal: string
  rentRenewalType: string
  earlyTerminationOption: boolean
  breakDate: string
  purchaseOption: boolean
  purchaseOptionDate: string
  purchaseOptionPrice: string
}

enum Section {
  Summary = 'Summary',
  PropertyDescription = 'Property Description',
  AdditionalRentExpenses = 'Additional Rent Expenses',
  RenewalOption = 'Renewal Option',
  OtherOptions = 'Other Options',
}

interface Props {
  lease: CustomerLease | null
  onSave: () => void
}

export const LeaseForm = ({lease, onSave}: Props): JSX.Element => {
  const [openSections, setOpenSections] = React.useState<Set<Section>>(
    new Set([
      Section.Summary,
      Section.PropertyDescription,
      Section.AdditionalRentExpenses,
      Section.RenewalOption,
      Section.OtherOptions,
    ]),
  )
  const {formState, handlers, setters, diff} = useForm<LeaseFormFields>({
    address: lease?.address ?? '',
    buildingSqFt: lease?.buildingSqFt ? String(lease.buildingSqFt) : '',
    leaseCommencementDate: lease?.leaseCommencementDate ?? '',
    nextLeaseExpirationDate: lease?.leaseExpirationDate ?? '',
    landlordName: lease?.landlordName ?? '',
    tenantName: lease?.tenantName ?? '',
    netRentableArea: lease?.netRentableArea
      ? String(lease.netRentableArea)
      : '',
    multiTenant: lease?.multiTenant ?? false,
    propertyType: lease?.propertyType ?? '',
    propertyUse: lease?.propertyUse ?? '',
    propertyTaxResponsibleParty: lease?.propertyTaxResponsibleParty ?? '',
    propertyInsuranceResponsibleParty:
      lease?.propertyInsuranceResponsibleParty ?? '',
    propertyUtilitiesResponsibleParty:
      lease?.propertyUtilitiesResponsibleParty ?? '',
    repairsAndMaintenanceResponsibleParty:
      lease?.repairsAndMaintenanceResponsibleParty ?? '',
    commonAreaMaintenanceResponsibleParty:
      lease?.commonAreaMaintenanceResponsibleParty ?? '',
    renewalOption: lease?.renewalOption ?? false,
    numRenewals: lease?.numRenewals ? String(lease.numRenewals) : '',
    lengthPerRenewal: lease?.lengthPerRenewal ?? '',
    rentRenewalType: lease?.rentRenewalType ?? '',
    earlyTerminationOption: lease?.earlyTerminationOption ?? false,
    breakDate: lease?.breakDate ?? '',
    purchaseOption: lease?.purchaseOption ?? false,
    purchaseOptionDate: lease?.purchaseOptionSaleDate ?? '',
    purchaseOptionPrice: lease?.purchaseOptionSalePrice
      ? String(lease.purchaseOptionSalePrice)
      : '',
  })

  const {request} = useRequest<void, Partial<LeaseFormFields>>(
    'PUT',
    apiCoPilotLeaseDetailsPath(),
  )

  const createBooleanChangeHandler = React.useCallback(
    (
        key: keyof typeof formState,
        dependencies: Array<keyof typeof formState>,
      ): React.ChangeEventHandler<HTMLInputElement> =>
      (event) => {
        if (event.target.value === 'no') {
          setters[key](false)
          dependencies.forEach((dep) => setters[dep](''))
        }

        if (event.target.value === 'yes') {
          setters[key](true)
        }
      },
    [setters],
  )

  const handleClick = React.useCallback(() => {
    request({data: diff}).finally(() => {
      onSave()
    })
  }, [diff, onSave, request])

  const toggleSection = (section: Section) => {
    if (openSections.has(section)) {
      setOpenSections((prev) => {
        const oldSet = new Set(prev)
        oldSet.delete(section)
        return oldSet
      })
    } else {
      setOpenSections((prev) => new Set(prev).add(section))
    }
  }

  const fieldsForSection = React.useCallback(
    (section: Section) => {
      switch (section) {
        case Section.Summary:
          return (
            <>
              <OutlinedTextField
                id="summary-commencement-date"
                label="Commencement Date"
                value={formState.leaseCommencementDate}
                onChange={handlers.leaseCommencementDate.change}
                type="date"
              />
              <OutlinedTextField
                id="summary-next-expiration-date"
                label="Next Expiration Date"
                value={formState.nextLeaseExpirationDate}
                onChange={handlers.nextLeaseExpirationDate.change}
                type="date"
              />
              <OutlinedTextField
                id="summary-landlord-party"
                label="Landlord Party"
                value={formState.landlordName}
                onChange={handlers.landlordName.change}
              />
              <OutlinedTextField
                id="summary-tenant-party"
                label="Tenant Party"
                value={formState.tenantName}
                onChange={handlers.tenantName.change}
              />
              <OutlinedTextField
                id="summary-rentable-sf"
                label="Rentable SF"
                value={formState.netRentableArea}
                onChange={handlers.netRentableArea.change}
                inputComponent={AreaFormat}
              />
            </>
          )
        case Section.PropertyDescription:
          return (
            <>
              <OutlinedTextField
                id="property-description-property-sf"
                label="Property SF"
                value={formState.buildingSqFt}
                onChange={handlers.buildingSqFt.change}
                inputComponent={AreaFormat}
              />
              <OutlinedSelect
                id="property-description-multi-tenant"
                label="Tenancy"
                value={formState.multiTenant ? 'yes' : 'no'}
                onChange={createBooleanChangeHandler('multiTenant', [])}
                options={[
                  {value: 'no', label: 'Single-tenant'},
                  {value: 'yes', label: 'Multi-tenant'},
                ]}
              />
              <OutlinedSelect
                id="property-description-property-type"
                label="Property Type"
                value={formState.propertyType}
                onChange={handlers.propertyType.change}
                options={[
                  {value: 'Office', label: 'Office'},
                  {value: 'Retail', label: 'Retail'},
                  {value: 'Industrial', label: 'Industrial'},
                  {value: '', label: 'Other'},
                ]}
              />
              <OutlinedTextField
                id="property-description-property-use"
                label="Property Use"
                value={formState.propertyUse}
                onChange={handlers.propertyUse.change}
                disabled
              />
            </>
          )
        case Section.AdditionalRentExpenses:
          return (
            <>
              <OutlinedSelect
                id="additional-rent-property-tax"
                label="Property Tax"
                value={formState.propertyTaxResponsibleParty}
                onChange={handlers.propertyTaxResponsibleParty.change}
                options={[
                  {value: ExpenseResponsibility.Tenant, label: 'Me'},
                  {value: ExpenseResponsibility.Landlord, label: 'My Landlord'},
                  {value: ExpenseResponsibility.Both, label: 'Both'},
                  {value: '', label: 'N/A'},
                ]}
              />
              <OutlinedSelect
                id="additional-rent-property-insurance"
                label="Property Insurance"
                value={formState.propertyInsuranceResponsibleParty}
                onChange={handlers.propertyInsuranceResponsibleParty.change}
                options={[
                  {value: ExpenseResponsibility.Tenant, label: 'Me'},
                  {value: ExpenseResponsibility.Landlord, label: 'My Landlord'},
                  {value: ExpenseResponsibility.Both, label: 'Both'},
                  {value: '', label: 'N/A'},
                ]}
              />
              <OutlinedSelect
                id="additional-rent-repairs-maintenance"
                label="Repairs & Maintenance"
                value={formState.repairsAndMaintenanceResponsibleParty}
                onChange={handlers.repairsAndMaintenanceResponsibleParty.change}
                options={[
                  {value: ExpenseResponsibility.Tenant, label: 'Me'},
                  {value: ExpenseResponsibility.Landlord, label: 'My Landlord'},
                  {value: ExpenseResponsibility.Both, label: 'Both'},
                  {value: '', label: 'N/A'},
                ]}
              />
              <OutlinedSelect
                id="additional-rent-property-utilities"
                label="Property Utilities"
                value={formState.propertyUtilitiesResponsibleParty}
                onChange={handlers.propertyUtilitiesResponsibleParty.change}
                options={[
                  {value: ExpenseResponsibility.Tenant, label: 'Me'},
                  {value: ExpenseResponsibility.Landlord, label: 'My Landlord'},
                  {value: ExpenseResponsibility.Both, label: 'Both'},
                  {value: '', label: 'N/A'},
                ]}
              />
              <OutlinedSelect
                id="additional-rent-cam"
                label="CAM"
                value={formState.commonAreaMaintenanceResponsibleParty}
                onChange={handlers.commonAreaMaintenanceResponsibleParty.change}
                options={[
                  {value: ExpenseResponsibility.Tenant, label: 'Me'},
                  {value: ExpenseResponsibility.Landlord, label: 'My Landlord'},
                  {value: ExpenseResponsibility.Both, label: 'Both'},
                  {value: '', label: 'N/A'},
                ]}
              />
            </>
          )
        case Section.RenewalOption:
          return (
            <>
              <OutlinedSelect
                id="renewal-option-renewal-option"
                label="Renewal Option"
                value={formState.renewalOption ? 'yes' : 'no'}
                onChange={createBooleanChangeHandler('renewalOption', [
                  'numRenewals',
                  'lengthPerRenewal',
                  'rentRenewalType',
                ])}
                options={[
                  {value: 'yes', label: 'Yes'},
                  {value: 'no', label: 'No'},
                ]}
              />
              <OutlinedTextField
                id="renewal-option-number-of-renewals"
                label="Number of Renewals"
                value={formState.numRenewals}
                onChange={handlers.numRenewals.change}
                disabled={!formState.renewalOption}
              />
              <OutlinedTextField
                id="renewal-option-length-per-renewal"
                label="Length per Renewal"
                value={formState.lengthPerRenewal}
                onChange={handlers.lengthPerRenewal.change}
                disabled={!formState.renewalOption}
              />
              <OutlinedSelect
                id="renewal-option-rent-renewal-type"
                label="Rent Renewal Type"
                value={formState.rentRenewalType}
                onChange={handlers.rentRenewalType.change}
                disabled={!formState.renewalOption}
                options={[
                  {value: 'fair_market_rent', label: 'Fair Market Rent'},
                  {value: 'cpi', label: 'CPI'},
                  {value: 'annual_increase', label: 'Annual Increase'},
                  {value: '', label: 'N/A'},
                ]}
              />
            </>
          )
        case Section.OtherOptions:
          return (
            <>
              <OutlinedSelect
                id="other-options-termination-option"
                label="Termination Option"
                value={formState.earlyTerminationOption ? 'yes' : 'no'}
                onChange={createBooleanChangeHandler('earlyTerminationOption', [
                  'breakDate',
                ])}
                options={[
                  {value: 'yes', label: 'Yes'},
                  {value: 'no', label: 'No'},
                ]}
              />
              <OutlinedTextField
                id="other-options-break-date"
                label="Break Date"
                value={formState.breakDate}
                onChange={handlers.breakDate.change}
                disabled={!formState.earlyTerminationOption}
                type="date"
              />
              <OutlinedSelect
                id="other-options-purchase-option"
                label="Purchase Option"
                value={formState.purchaseOption ? 'yes' : 'no'}
                onChange={createBooleanChangeHandler('purchaseOption', [
                  'purchaseOptionDate',
                  'purchaseOptionPrice',
                ])}
                options={[
                  {value: 'yes', label: 'Yes'},
                  {value: 'no', label: 'No'},
                ]}
              />
              <OutlinedTextField
                id="other-options-purchase-option-date"
                label="Purchase Option Date"
                value={formState.purchaseOptionDate}
                onChange={handlers.purchaseOptionDate.change}
                disabled={!formState.purchaseOption}
                type="date"
              />
              <OutlinedTextField
                id="other-options-purchase-option-price"
                label="Purchase Option Price"
                value={formState.purchaseOptionPrice}
                onChange={handlers.purchaseOptionPrice.change}
                disabled={!formState.purchaseOption}
              />
            </>
          )
      }
    },
    [formState, handlers, createBooleanChangeHandler],
  )

  return (
    <Box display="flex" flexDirection="column">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pl={3}
        sx={{borderBottom: '1px solid', borderColor: 'grey.200'}}
      >
        <Typography variant="h2">Edit Lease Information</Typography>
        <Button variant="text" onClick={handleClick} sx={{py: 2, px: 3}}>
          Save
        </Button>
      </Stack>
      <Stack px={3} spacing={4} py={4}>
        {[
          Section.Summary,
          Section.PropertyDescription,
          Section.AdditionalRentExpenses,
          Section.RenewalOption,
          Section.OtherOptions,
        ].map((section) => {
          return (
            <Stack key={section}>
              <Button
                variant="text"
                color="secondary"
                onClick={() => {
                  toggleSection(section)
                }}
                startIcon={
                  openSections.has(section) ? <ArrowDropDown /> : <ArrowRight />
                }
                sx={{alignSelf: 'flex-start', p: 0}}
                disableRipple
              >
                <Typography variant="h2">{section}</Typography>
              </Button>
              <Collapse in={openSections.has(section)}>
                <Stack spacing={2} pt={2}>
                  {fieldsForSection(section)}
                </Stack>
              </Collapse>
            </Stack>
          )
        })}
      </Stack>
    </Box>
  )
}
