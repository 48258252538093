import * as React from 'react'

import {
  Box,
  FormControl,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  MenuItemProps,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material'

import {SelectOption} from 'src/types'

type Props = {
  label: string
  value: string | undefined
  options: Array<SelectOption>
  required?: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  minWidth?: number
  inputProps?: InputBaseComponentProps
  optionProps?: MenuItemProps
} & Omit<SelectProps<string>, 'onChange' | 'value'>

export const OutlinedSelect = ({
  id,
  label,
  value,
  options,
  onChange,
  minWidth = 195,
  required = false,
  inputProps,
  optionProps,
  ...selectProps
}: Props): JSX.Element => {
  return (
    <Box display="flex" flexWrap="wrap" data-testid={id}>
      <FormControl
        variant="outlined"
        sx={{minWidth, flexGrow: 1}}
        required={required}
      >
        <InputLabel shrink htmlFor={id} disabled={selectProps.disabled}>
          {label}
        </InputLabel>
        <Select
          MenuProps={{sx: {maxHeight: '250px'}}}
          value={value}
          inputProps={inputProps}
          input={
            <OutlinedInput
              notched
              label={required ? `${label} *` : label}
              name={id}
              id={id}
            />
          }
          onChange={onChange as (event: SelectChangeEvent) => void}
          {...selectProps}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} {...optionProps}>
              {option.label || ' '}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
