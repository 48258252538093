import React from 'react'

import {Typography, ToggleButtonGroup, ToggleButton} from '@mui/material'
import {Box, Stack, useTheme} from '@mui/system'

import {ParcelCard} from 'src/components/copilot/ParcelCard'
import {ParcelMap} from 'src/components/copilot/mapbox/ParcelMap'
import {apiCoPilotParcelsClaimedLocationsPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {ClaimedLocation, Parcel} from 'src/types/copilot'

interface Props {
  parcel: Parcel
  onSelectLease: () => void
  onSelectOwn: () => void
}

export const LeaseOrOwnPage = ({parcel, onSelectLease, onSelectOwn}: Props) => {
  const theme = useTheme()

  const {request: saveClaimedLocation} = useRequest<
    void,
    Partial<ClaimedLocation>
  >('POST', apiCoPilotParcelsClaimedLocationsPath())

  const handleSelection = React.useCallback(
    async (_, leaseOrOwn: 'lease' | 'own') => {
      await saveClaimedLocation({
        data: {
          taxAssessorId: parcel.taxAssessorId,
          leaseOrOwn,
        },
      })

      if (leaseOrOwn === 'lease') {
        onSelectLease()
      } else {
        onSelectOwn()
      }
    },
    [onSelectLease, onSelectOwn, parcel.taxAssessorId, saveClaimedLocation],
  )

  return (
    <>
      <Box position="absolute" zIndex={50} width="100%">
        <Stack alignItems="center" spacing={4}>
          <Typography variant="h4" mt={6}>
            Do you lease or own?
          </Typography>
          <ToggleButtonGroup
            color="primary"
            size="small"
            exclusive
            onChange={handleSelection}
          >
            <ToggleButton
              value="lease"
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <Typography variant="h5" minWidth="130px">
                Lease
              </Typography>
            </ToggleButton>
            <ToggleButton
              value="own"
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              }}
            >
              <Typography variant="h5" minWidth="130px">
                Own
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Box>
      <ParcelMap parcel={parcel} startingZoom={16} enableOverlays={false} />
      <Box
        position="absolute"
        bottom={40}
        zIndex={50}
        left={{xs: 0, md: 3}}
        paddingX={1}
        maxWidth={{xs: '100%', md: '375px'}}
        width="100%"
      >
        <ParcelCard parcel={parcel} disableActions />
      </Box>
    </>
  )
}
