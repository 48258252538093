import * as React from 'react'
import {GoogleLoginButton as GoogleLoginUIButton} from 'react-social-login-buttons'
import {IResolveParams, LoginSocialGoogle} from 'reactjs-social-login'

import {googleOauthApiCoPilotAccountPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {trackEvent} from 'src/util/analytics'

interface Props {
  onSuccess: () => void
}

export const GoogleLoginButton = ({onSuccess}: Props): JSX.Element => {
  const clientId = document.querySelector<HTMLMetaElement>(
    'meta[name="googleOauthClientId"]',
  )?.content as string

  const {request: postAccessToken} = useRequest<void, {accessToken: string}>(
    'POST',
    googleOauthApiCoPilotAccountPath(),
    {
      disableDefaultErrorHandling: true,
    },
  )

  const handleResponse = React.useCallback(
    ({data}: IResolveParams) => {
      if (data?.access_token) {
        trackEvent('Sign in with Google Success')
        postAccessToken({
          data: {accessToken: data.access_token},
        }).then(onSuccess)
      }
    },
    [onSuccess, postAccessToken],
  )

  return (
    <LoginSocialGoogle
      client_id={clientId}
      scope="email profile"
      onLoginStart={() => trackEvent('Sign in with Google')}
      onResolve={handleResponse}
      onReject={(err) => {
        trackEvent('Sign in with Google Failure', {error: err})
      }}
    >
      <GoogleLoginUIButton
        align="center"
        iconSize="18px"
        style={{
          border: '1px solid rgba(0, 0, 0, 0.12)',
          boxShadow: 'none',
          fontFamily: 'inherit',
          fontSize: '0.875rem',
          height: '40px',
          margin: 0,
          padding: '0 13px',
          width: '100%',
        }}
      >
        Continue with Google
      </GoogleLoginUIButton>
    </LoginSocialGoogle>
  )
}
