import * as React from 'react'

import {Button, Stack} from '@mui/material'
import {grey} from '@mui/material/colors'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {InputData} from 'src/components/copilot/UserInput'
import {FileInput} from 'src/framework/FileInput'
import {apiCoPilotUploadLeasePath} from 'src/generated/routes'
import {useFileUpload} from 'src/hooks/request/useFileUpload'

interface Props {
  onSubmit: (input?: InputData) => void
}

export const LeaseUploadButton = ({onSubmit}: Props): JSX.Element => {
  const {copilotEmailLeaseEnabled} = useFlags()
  const {uploadFile, loading} = useFileUpload(apiCoPilotUploadLeasePath())

  const handleFileSelect: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      async (e) => {
        const fileList: FileList | null = e.target?.files

        if (!fileList) return

        const file = fileList[0]

        await uploadFile({file})
        onSubmit()
      },
      [onSubmit, uploadFile],
    )

  return (
    <Stack direction="column" spacing={1}>
      <FileInput
        id={'lease-upload'}
        buttonText={'Upload lease'}
        fileExtensionRestrictions={['.pdf']}
        buttonProps={{
          variant: 'contained',
          fullWidth: true,
        }}
        onChange={handleFileSelect}
        disabled={loading}
      />
      {copilotEmailLeaseEnabled ? (
        <Button
          variant="outlined"
          fullWidth
          onClick={() =>
            onSubmit({
              value: 'DONT_HAVE_LEASE',
              text: 'Email my lease',
            })
          }
          sx={{
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: grey[300],
            },
          }}
          color="secondary"
        >
          {'Email my lease'}
        </Button>
      ) : null}
    </Stack>
  )
}
