import * as React from 'react'

import {Button, ButtonProps, SvgIcon} from '@mui/material'

import {usePlaid} from 'src/hooks/usePlaid'
import {ReactComponent as PlaidLogo} from 'svg/plaid_logo_only.svg'

type Props = {
  onSuccessCallback: () => void
  buttonText?: React.ReactNode | null
  buttonProps?: ButtonProps
  createLinkTokenPath?: string
  createItemPath?: string
}

export const PlaidButton = ({
  onSuccessCallback,
  buttonText = 'Connect business bank account',
  buttonProps,
  createLinkTokenPath,
  createItemPath,
}: Props): JSX.Element => {
  const {open, ready} = usePlaid({
    onSuccess: onSuccessCallback,
    createLinkTokenPath,
    createItemPath,
  })

  return (
    <Button
      variant="contained"
      color="primary"
      sx={{whiteSpace: 'nowrap'}}
      onClick={open}
      disabled={!ready}
      startIcon={<SvgIcon inheritViewBox component={PlaidLogo} />}
      {...buttonProps}
    >
      {buttonText}
    </Button>
  )
}
