import * as React from 'react'

// The React components are in public beta and mapbox has an issue
// where they are not registering their custom HTML components
// before letting React take over so we'll see a console error about this on first mount
import {AddressAutofillRetrieveResponse} from '@mapbox/search-js-core'
import {AddressAutofill} from '@mapbox/search-js-react'
import {InputAdornment, TextField} from '@mui/material'

import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {ProgressBox} from 'src/framework/ui/ProgressBox'
import {searchApiCoPilotParcelsPath} from 'src/generated/routes'
import {useShow} from 'src/hooks/request/useShow'
import {Parcel} from 'src/types/copilot'
import {trackEventDebounced} from 'src/util/analytics'

type AddressAutocompleteProps = {
  fetchNearbyBusinesses?: boolean
  input: string
  inputElementId?: string
  onParcelFound: (parcel: Parcel) => void
  setInput: (input: string) => void
}

export const AddressAutocomplete = ({
  fetchNearbyBusinesses = false,
  input,
  inputElementId = 'mapbox-autocomplete',
  onParcelFound,
  setInput,
}: AddressAutocompleteProps): JSX.Element | null => {
  const publicToken = React.useRef(
    document.querySelector<HTMLMetaElement>('meta[name="mapbox"]')?.content,
  )

  const {fetch: searchParcelByAddress, loading: searchInProgress} =
    useShow<Parcel>(searchApiCoPilotParcelsPath())

  const handleSearchSelection = React.useCallback(
    (res: AddressAutofillRetrieveResponse) => {
      const feature = res.features[0]

      if (feature) {
        const address = feature.properties.full_address
        if (!address) {
          return
        }
        setInput(address)
        setTimeout(
          () =>
            trackEventDebounced('Select Address Autocomplete Result', {
              address: address,
            }),
          0,
        )
        searchParcelByAddress({
          params: {
            address,
            ...(fetchNearbyBusinesses ? {fetch_businesses: 'true'} : {}),
          },
        }).then(({entity: parcel}) => {
          if (parcel) {
            onParcelFound(parcel)
          }
        })
      }
    },
    [fetchNearbyBusinesses, onParcelFound, searchParcelByAddress, setInput],
  )

  const setAndTrackInput = React.useCallback(
    (input: string) => {
      trackEventDebounced('Input Address Autocomplete', {address: input})
      setInput(input)
    },
    [setInput],
  )

  if (publicToken.current === undefined) {
    return null
  }

  return (
    <form style={{width: '100%'}}>
      <AddressAutofill
        accessToken={publicToken.current}
        onRetrieve={handleSearchSelection}
        options={{
          country: 'US',
          bbox: [
            [-78.995048, 35.519458],
            [-78.253711, 36.076443],
          ],
          streets: false,
        }}
      >
        <ProgressBox loading={searchInProgress}>
          <TextField
            id={inputElementId}
            autoComplete="address-line1"
            disabled={searchInProgress}
            value={input}
            onInput={(e) =>
              setAndTrackInput((e.target as HTMLInputElement).value)
            }
            fullWidth
            variant="outlined"
            sx={{
              backgroundColor: '#fff',
              borderRadius: '25px',
              '& .MuiOutlinedInput-root': {
                height: '46px',
                borderRadius: '25px',
                fontSize: 'inherit',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MaterialSymbolIcon color="secondary" sx={{mr: 0.5}}>
                    search
                  </MaterialSymbolIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Enter an address"
          />
        </ProgressBox>
      </AddressAutofill>
    </form>
  )
}
