export type MessageType =
  | 'CONFIRMATION_INPUT'
  | 'SELECT_INPUT'
  | 'DATE_INPUT'
  | 'INFO'
  | 'TEXT_INPUT'
  | 'NUMBER_INPUT'
  | 'PERCENT_INPUT'
  | 'AREA_INPUT'
  | 'CURRENCY_INPUT'
  | 'ADDRESS_INPUT'
  | 'CODE_INPUT'
  | 'POLL_LEASE_STATUS'
  | 'LEASE_FILE_UPLOAD'
  | 'USER_MESSAGE'
  | 'LEASE_FORM'
  | 'CONTINUE_TO_MAP'
  | 'CONTINUE_TO_INVENTORY'
  | 'RECOMMENDATION_CAROUSEL'

export type ChatSelectOption = {
  label: string
  value: string
  style?: string
}

export const enum OptionStyle {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type LearnMoreSection = {
  question?: string
  content: string
}

export type Message = {
  type: MessageType
  text: string | null
  value?: string | number | null
  options?: ChatSelectOption[]
  rows?: string[][]
  tableHeader?: 'column' | 'row' | 'bullet' | 'financials'
  learnMore?: LearnMoreSection[]
  skippable?: boolean
  linkText?: string
  linkUrl?: string
}

export const enum Confirm {
  YES = 'YES',
  NO = 'NO',
}

export enum RecommendationOption {
  OWN_CURRENT_BUILDING = 'own_current_building',
  STAY_PUT = 'stay_put',
  RENEGOTIATE_CURRENT_LEASE = 'renegotiate_current_lease',
  EXPLORE_INVENTORY = 'explore_inventory',
}
