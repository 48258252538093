import * as React from 'react'

import {ChevronLeft, ChevronRight} from '@mui/icons-material'
import {Box, IconButton, SxProps, Theme} from '@mui/material'

interface CarouselProps {
  children: React.ReactNode
  slidesToShow?: number
  onChange?: (index: number) => void
  sx?: SxProps<Theme>
}

export const Carousel = ({
  children,
  slidesToShow = 1,
  onChange,
  sx = {},
}: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = React.useState(0)

  const nextSlide = () => {
    const nextIndex =
      currentIndex === React.Children.count(children) - 1 ? 0 : currentIndex + 1

    setCurrentIndex(nextIndex)
    onChange && onChange(nextIndex)
  }

  const prevSlide = () => {
    const prevIndex =
      currentIndex === 0 ? React.Children.count(children) - 1 : currentIndex - 1

    setCurrentIndex(prevIndex)
    onChange && onChange(prevIndex)
  }

  return (
    <Box sx={{position: 'relative', width: '100%', overflow: 'hidden', ...sx}}>
      <Box
        sx={{
          display: 'flex',
          transition: 'transform 0.5s ease-in-out',
          transform: `translateX(-${currentIndex * (100 / slidesToShow)}%)`,
          alignItems: 'flex-end',
        }}
      >
        {React.Children.map(children, (child, index) => (
          <Box
            sx={{
              flex: `0 0 calc(${100 / slidesToShow}% - ${
                (slidesToShow - 1) / slidesToShow
              }px)`,
              maxWidth: `calc(${100 / slidesToShow}% - ${
                (slidesToShow - 1) / slidesToShow
              }px)`,
            }}
            key={index}
          >
            {child}
          </Box>
        ))}
      </Box>
      {React.Children.count(children) > 1 && (
        <>
          <IconButton
            onClick={prevSlide}
            sx={{
              position: 'absolute',
              top: '33%',
              left: 16,
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              '&:hover': {backgroundColor: 'rgba(255, 255, 255, 0.8)'},
            }}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={nextSlide}
            sx={{
              position: 'absolute',
              top: '33%',
              right: 16,
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              '&:hover': {backgroundColor: 'rgba(255, 255, 255, 0.8)'},
            }}
          >
            <ChevronRight />
          </IconButton>
        </>
      )}
    </Box>
  )
}

export default Carousel
