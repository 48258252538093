import * as React from 'react'
import {useLocation} from 'react-router-dom'

import {
  Box,
  Container,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  SvgIcon,
  Typography,
} from '@mui/material'

import {AdmittedUserContainer} from 'src/components/copilot/AdmittedUserContainer'
import {CopilotScreen} from 'src/components/copilot/CopilotScreen'
import {MaterialSymbolIcon} from 'src/framework/ui/MaterialSymbolIcon'
import {useAnalyticsUser} from 'src/hooks/analytics/useAnalyticsUser'
import {NavPage, useNavPage} from 'src/hooks/copilot/useNavPage'
import {useCopilotAccount} from 'src/hooks/request/useCopilotAccount'
import {CopilotFeedScreen} from 'src/screens/copilot/CopilotFeedScreen'
import {CopilotProfileScreen} from 'src/screens/copilot/CopilotProfileScreen'
import {CopilotSearchScreen} from 'src/screens/copilot/CopilotSearchScreen'
import {CopilotTargetsScreen} from 'src/screens/copilot/CopilotTargetsScreen'
import {User} from 'src/types'
import {trackPage} from 'src/util/analytics'
import {ReactComponent as TargetIcon} from 'svg/target.svg'
import {ReactComponent as WithcoCoIcon} from 'svg/withco_logo_co.svg'

const ANALYTICS_PAGE_EVENT_NAME: Partial<Record<NavPage, string>> = {
  Search: 'View Search',
  Targets: 'View Targets',
  Feed: 'View Feed',
  Profile: 'View Profile',
}

export const CopilotV2HomeScreen = (): JSX.Element => {
  const {user} = useCopilotAccount()
  useAnalyticsUser(user)

  const location = useLocation()

  const deepLinkedTaxAssessorId = React.useMemo(() => {
    const queryParams = new URLSearchParams(location.search)

    return queryParams.get('share_id')
  }, [location.search])

  const {navPage, setNavPage, isActivePage} = useNavPage()

  React.useEffect(() => {
    const pageEventName = ANALYTICS_PAGE_EVENT_NAME[navPage]
    if (pageEventName) trackPage(pageEventName)
  }, [navPage])

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: ['100dvh', '100vh'],
        width: ['100dvw', '100vw'],
      }}
    >
      <InternalUserOverlay user={user} />
      <Box sx={{height: '90%', width: '100%'}}>
        <CopilotScreen isActive={isActivePage('Search')}>
          <CopilotSearchScreen
            deepLinkedTaxAssessorId={deepLinkedTaxAssessorId}
            isVisible={isActivePage('Search')}
          />
        </CopilotScreen>
        <CopilotScreen isActive={isActivePage('Targets')}>
          <AdmittedUserContainer
            isActive={isActivePage('Targets')}
            renderInPlace
          >
            <CopilotTargetsScreen />
          </AdmittedUserContainer>
        </CopilotScreen>
        <CopilotScreen isActive={isActivePage('Feed')}>
          <AdmittedUserContainer isActive={isActivePage('Feed')} renderInPlace>
            <CopilotFeedScreen />
          </AdmittedUserContainer>
        </CopilotScreen>
        <CopilotScreen isActive={isActivePage('Profile')}>
          <AdmittedUserContainer
            isActive={isActivePage('Profile')}
            renderInPlace
          >
            <CopilotProfileScreen />
          </AdmittedUserContainer>
        </CopilotScreen>
      </Box>
      <Paper
        sx={{position: 'fixed', bottom: 0, left: 0, right: 0, height: '10%'}}
        elevation={3}
      >
        <BottomNavigation
          showLabels={true}
          value={navPage}
          onChange={(event, newValue) => {
            setNavPage(newValue)
          }}
          sx={{
            height: '100%',
          }}
        >
          <BottomNavigationAction
            label="Search"
            value="Search"
            icon={
              <MaterialSymbolIcon fontSize="large">search</MaterialSymbolIcon>
            }
          />
          <BottomNavigationAction
            label="Targets"
            value="Targets"
            icon={
              <SvgIcon inheritViewBox component={TargetIcon} fontSize="large" />
            }
          />
          <BottomNavigationAction
            label="Feed"
            value="Feed"
            icon={
              <SvgIcon
                inheritViewBox
                component={WithcoCoIcon}
                fontSize="large"
              />
            }
          />
          <BottomNavigationAction
            label="Profile"
            value="Profile"
            icon={
              <MaterialSymbolIcon fontSize="large">
                account_box
              </MaterialSymbolIcon>
            }
          />
        </BottomNavigation>
      </Paper>
    </Container>
  )
}

interface InternalUserOverlayProps {
  user: User | null
}

const InternalUserOverlay = ({
  user,
}: InternalUserOverlayProps): JSX.Element | null => {
  if (!user?.internalUser) {
    return null
  }

  return (
    <Box
      border="5px solid red"
      height="100%"
      position="absolute"
      width="100%"
      zIndex={2000}
      sx={{
        pointerEvents: 'none',
      }}
    >
      <Typography
        bgcolor="red"
        color="white"
        position="absolute"
        textAlign="center"
        width="100%"
        zIndex={2001}
      >
        {user.internalUser.name} is impersonating {user.email}
      </Typography>
    </Box>
  )
}
