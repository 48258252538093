import * as React from 'react'

import {IconButton, SvgIcon} from '@mui/material'

import {AdmittedUserContainer} from 'src/components/copilot/AdmittedUserContainer'
import {
  apiCoPilotDestroyWatchlistParcelPath,
  apiCoPilotParcelsWatchlistPath,
} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {useWatchlistParcels} from 'src/hooks/request/useWatchlistParcels'
import {Parcel} from 'src/types/copilot'
import {trackEvent} from 'src/util/analytics'
import {ReactComponent as TargetIcon} from 'svg/target.svg'

interface Props {
  parcel: Parcel
  isOnWatchlist: boolean
}

export const WatchlistButton = ({
  parcel,
  isOnWatchlist,
}: Props): JSX.Element => {
  const {request: saveToWatchlist, loading: saveInProgress} = useRequest<
    void,
    {taxAssessorId: string}
  >('POST', apiCoPilotParcelsWatchlistPath())
  const [buttonState, setButtonState] = React.useState<'add' | 'remove'>(
    isOnWatchlist ? 'remove' : 'add',
  )

  const {refetch: fetchWatchlist} = useWatchlistParcels()

  const {request: removeFromWatchlist, loading: removeInProgress} =
    useRequest('DELETE')

  const handleAddToWatchlist = () => {
    trackEvent('Add Property to Watchlist', {
      propertyId: parcel.taxAssessorId,
      address: parcel.address,
    })

    setButtonState('remove')
    saveToWatchlist({data: {taxAssessorId: parcel.taxAssessorId}})
      .then(() => {
        fetchWatchlist()
      })
      .catch(() => {
        setButtonState('add')
      })
  }

  const handleRemoveFromWatchlist = () => {
    trackEvent('Remove Property from Watchlist', {
      propertyId: parcel.taxAssessorId,
      address: parcel.address,
    })

    setButtonState('add')
    removeFromWatchlist({
      url: apiCoPilotDestroyWatchlistParcelPath(parcel.taxAssessorId),
    })
      .then(() => {
        fetchWatchlist()
      })
      .catch(() => {
        setButtonState('remove')
      })
  }

  return (
    <AdmittedUserContainer
      attemptedActionName="addPropertyToWatchlist"
      onCreateAccount={handleAddToWatchlist}
    >
      <IconButton
        color={buttonState === 'add' ? 'secondary' : 'primary'}
        sx={{
          backgroundColor: '#ffffff',
          padding: '6px',
          '&:hover': {
            backgroundColor: '#ffffff',
          },
        }}
        onClick={(e) => {
          e.stopPropagation()
          if (!saveInProgress && !removeInProgress) {
            buttonState === 'add'
              ? handleAddToWatchlist()
              : handleRemoveFromWatchlist()
          }
        }}
      >
        <SvgIcon
          inheritViewBox
          component={TargetIcon}
          sx={{fontSize: '24px'}}
        />
      </IconButton>
    </AdmittedUserContainer>
  )
}
