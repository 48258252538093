import React from 'react'
import {useCalendlyEventListener, PopupModal} from 'react-calendly'

import {User} from '@sentry/react'

import {trackEvent} from 'src/util/analytics'

interface CalendlyProps {
  analyticsPageLocation: string
  calendlyUrl: string
  disableTestCalendlyUrl?: boolean
  onClose: () => void
  onScheduledCall?: (data: {eventApiUrl: string}) => void
  open: boolean
  user?: User
}

export const CalendlyModal = ({
  analyticsPageLocation,
  calendlyUrl,
  disableTestCalendlyUrl = false,
  onClose,
  onScheduledCall,
  open,
  user,
}: CalendlyProps) => {
  const environment = document.querySelector<HTMLMetaElement>(
    'meta[name="environment"]',
  )?.content

  const isDemoEnvironment =
    environment === 'development' || environment === 'staging'

  const elementRef = React.useRef<HTMLInputElement>(null)

  useCalendlyEventListener({
    onDateAndTimeSelected: () => {
      trackEvent('Select Consultation Date/Time via Calendly', {
        location: analyticsPageLocation,
      })
    },
    onEventScheduled: async (event) => {
      trackEvent('Complete Scheduling via Calendly', {
        location: analyticsPageLocation,
      })
      onScheduledCall &&
        onScheduledCall({eventApiUrl: event.data.payload.event.uri})
    },
  })

  React.useEffect(() => {
    if (open) {
      trackEvent('Start Scheduling via Calendly', {
        location: analyticsPageLocation,
      })
    }
  }, [open, analyticsPageLocation])

  return (
    <>
      <div ref={elementRef} />

      {elementRef.current && (
        <PopupModal
          url={
            isDemoEnvironment && !disableTestCalendlyUrl
              ? 'https://calendly.com/withco-engineering/test'
              : calendlyUrl
          }
          onModalClose={onClose}
          open={open}
          rootElement={elementRef.current}
          prefill={
            user && {
              email: user.email,
              name: user.name || undefined,
            }
          }
        />
      )}
    </>
  )
}
