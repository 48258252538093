import * as React from 'react'

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  SxProps,
  Box,
} from '@mui/material'

interface SimpleTableProps {
  headers?: string[]
  values: (string | number | null | undefined)[][]
  sx?: SxProps
}

export const SimpleTable: React.FC<SimpleTableProps> = ({
  headers,
  values,
  sx,
}) => {
  const columnCount = headers?.length ?? values[0]?.length ?? 0

  if (values.some((row) => row.length !== columnCount)) {
    console.error('Each row must have the same number of columns')
    return null
  }

  return (
    <Table
      size="small"
      sx={{
        ...sx,
        tableLayout: 'fixed',
        width: '100%',
      }}
    >
      {headers && (
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                key={header}
                sx={{
                  px: 0,
                  py: 0.75,
                  fontWeight: 700,
                  fontSize: '0.75rem',
                  borderBottom: 'none',
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  height="100%"
                  sx={{
                    justifyContent:
                      index === 0
                        ? 'flex-start'
                        : index === headers.length - 1
                        ? 'flex-end'
                        : 'center',
                  }}
                >
                  <span>{header}</span>
                  {index < headers.length - 1 && (
                    <span style={{visibility: 'hidden'}}>|</span>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {values.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <TableCell
                key={`${rowIndex}-${cellIndex}`}
                sx={{
                  px: 0,
                  py: 0.5,
                  fontWeight: 500,
                  fontSize: '0.75rem',
                  textAlign: cellIndex === row.length - 1 ? 'right' : 'left',
                  borderBottomWidth:
                    rowIndex === values.length - 1 ? '0px' : '0.5px',
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  height="100%"
                  sx={{
                    justifyContent:
                      cellIndex === 0
                        ? 'flex-start'
                        : cellIndex === row.length - 1
                        ? 'flex-end'
                        : 'center',
                  }}
                >
                  <span>{cell ?? '--'}</span>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
