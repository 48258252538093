import * as React from 'react'

import {Radio, FormControl, FormControlLabel, RadioGroup} from '@mui/material'

interface ToggleFilterInputProps<T extends string> {
  value: T | null
  onChange: (value: T) => void
  options: {value: T; label: string}[]
}

export function RadioFilterInput<T extends string>({
  value,
  onChange,
  options,
}: ToggleFilterInputProps<T>) {
  return (
    <FormControl>
      <RadioGroup value={value} onChange={(e, value) => onChange(value as T)}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
