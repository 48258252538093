import * as React from 'react'
import {AppleLoginButton} from 'react-social-login-buttons'

import {ArrowBack, WarningAmberRounded} from '@mui/icons-material'
import {
  Stack,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  useTheme,
  Link,
  SvgIcon,
  IconButton,
} from '@mui/material'
import axios from 'axios'
import {debounce} from 'lodash'
import {MuiOtpInput} from 'mui-one-time-password-input'
import {useSnackbar} from 'notistack'

import {FacebookLoginButton} from 'src/components/copilot/oauth/FacebookLoginButton'
import {GoogleLoginButton} from 'src/components/copilot/oauth/GoogleLoginButton'
import {
  loginWithOTPApiCoPilotAccountPath,
  sendOTPApiCoPilotAccountPath,
} from 'src/generated/routes'
import {useForm} from 'src/hooks/form/useForm'
import {useRequest} from 'src/hooks/request/useRequest'
import {useToggle} from 'src/hooks/util/useToggle'
import {trackEvent} from 'src/util/analytics'
import {ReactComponent as WithcoLogo} from 'svg/withco_logo.svg'

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const matchIsNumeric = (character: string) => {
  return !Number.isNaN(Number(character))
}

const validateChar = (value: string) => {
  return matchIsNumeric(value)
}

type LoginFormProps = {
  onSuccess: () => void
}

export function LoginForm({onSuccess}: LoginFormProps) {
  const theme = useTheme()
  const {enqueueSnackbar} = useSnackbar()

  const environment = document.querySelector<HTMLMetaElement>(
    'meta[name="environment"]',
  )?.content

  const {request: sendOtp} = useRequest<void, {email: string}>(
    'POST',
    sendOTPApiCoPilotAccountPath(),
    {disableDefaultErrorHandling: true},
  )

  const {request: loginWithOtp} = useRequest<
    {id: string},
    {email: string; otp: string}
  >('POST', loginWithOTPApiCoPilotAccountPath(), {
    disableDefaultErrorHandling: true,
  })

  const [isEmailSubmitted, setEmailSubmitted, unsetEmailSubmitted] =
    useToggle(false)
  const [hasOtpLoginError, setHasOtpLoginError, unsetHasOtpLoginError] =
    useToggle(false)
  const [hasSentOTP, setHasSentOTP, unsetHasSentOTP] = useToggle(false)

  const {formState, handlers, setters} = useForm({
    email: '',
    otp: '',
  })

  const resetOtpForm = React.useCallback(() => {
    setters.otp('')
    unsetHasOtpLoginError()
    unsetEmailSubmitted()
    unsetHasSentOTP()
  }, [setters, unsetHasOtpLoginError, unsetEmailSubmitted, unsetHasSentOTP])

  const sendOTPEmail = React.useCallback(async () => {
    try {
      trackEvent('Sign in with email', {
        email: formState.email,
      })

      await sendOtp({data: {email: formState.email}})
      setEmailSubmitted()
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 422) {
          resetOtpForm()
        }
      }
    }
  }, [formState.email, resetOtpForm, sendOtp, setEmailSubmitted])

  const handleSubmitEmail = React.useCallback(
    (event) => {
      event.preventDefault()
      sendOTPEmail()
    },
    [sendOTPEmail],
  )

  const resendOtp = React.useMemo(
    () =>
      debounce(
        async () => {
          await sendOTPEmail()
          enqueueSnackbar(
            `An email with a new code has been sent to ${formState.email}`,
            {
              variant: 'success',
            },
          )
        },
        3000,
        {leading: true, trailing: false},
      ),
    [enqueueSnackbar, formState.email, sendOTPEmail],
  )

  const isEmailValid = React.useMemo(
    () => validateEmail(formState.email),
    [formState.email],
  )

  const handleCompleteOtp = React.useCallback(
    (otp: string) => {
      loginWithOtp({
        data: {email: formState.email, otp},
      })
        .then((data) => {
          const userId = data?.id
          trackEvent('Sign in with email Success', {
            userId,
          })
          setTimeout(onSuccess, 500)
        })
        .catch((err) => {
          // TODO: show some better error screen on 500
          trackEvent('Sign in with email Failure', {})
          if (err.response.status === 400) {
            setHasOtpLoginError()
          } else if (err.response.status === 422) {
            resetOtpForm()
          }
        })
    },
    [
      onSuccess,
      formState.email,
      loginWithOtp,
      resetOtpForm,
      setHasOtpLoginError,
    ],
  )

  React.useEffect(() => {
    if (isEmailSubmitted) {
      setHasSentOTP()
    }
  }, [isEmailSubmitted, setHasSentOTP])

  const currentStep = React.useMemo(() => {
    return hasSentOTP ? 'otp' : 'newSession'
  }, [hasSentOTP])

  switch (currentStep) {
    case 'newSession': {
      return (
        <Stack direction="column" alignItems="center" gap={2}>
          <SvgIcon
            component={WithcoLogo}
            inheritViewBox
            sx={{fontSize: 72}}
            color="primary"
          />
          <Typography variant="h4">Log in or Sign up</Typography>
          <Box
            width="90%"
            px={2}
            pb={2}
            textAlign="left"
            component="form"
            onSubmit={handleSubmitEmail}
          >
            <Stack direction="column" alignItems="stretch" gap={2}>
              <TextField
                fullWidth
                autoFocus
                variant="outlined"
                placeholder="Email"
                value={formState.email}
                onChange={handlers.email.change}
                inputProps={{inputMode: 'email'}}
              />
              <Button
                type="submit"
                variant="outlined"
                disabled={!isEmailValid}
                fullWidth
                sx={{
                  height: 40,
                  borderRadius: '3px',
                }}
              >
                Continue
              </Button>
            </Stack>
          </Box>
          <Divider
            sx={{
              width: '80%',
              borderWidth: '0.5px',
            }}
          >
            <Typography variant="body1" color="text.secondary">
              or
            </Typography>
          </Divider>
          <Stack direction="column" gap={2} width="90%" px={2} pt={2}>
            <GoogleLoginButton onSuccess={onSuccess} />
            <FacebookLoginButton onSuccess={onSuccess} />
            {environment == 'development' && (
              <AppleLoginButton
                align="center"
                iconSize="18px"
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  boxShadow: 'none',
                  fontFamily: 'inherit',
                  fontSize: '0.875rem',
                  height: '40px',
                  margin: 0,
                  padding: '0 13px',
                  width: '100%',
                }}
              >
                Continue with Apple
              </AppleLoginButton>
            )}
          </Stack>
          <Box></Box>
        </Stack>
      )
    }
    case 'otp': {
      return (
        <>
          <Box display="flex" position="absolute">
            <IconButton onClick={resetOtpForm}>
              <ArrowBack />
            </IconButton>
          </Box>
          <Stack spacing={2} alignItems="center" mb={4} mx={4}>
            <SvgIcon
              component={WithcoLogo}
              inheritViewBox
              sx={{fontSize: 72}}
              color="primary"
            />
            <Stack spacing={2}>
              <Typography textAlign="center" variant="h4">
                Check your email for a code
              </Typography>
              <Typography textAlign="center" variant="body1">
                We&apos;ve sent a 6-digit code to{' '}
                <strong>{formState.email}</strong>. The code expires shortly, so
                please enter it soon.
              </Typography>
              <MuiOtpInput
                length={6}
                TextFieldsProps={{
                  inputProps: {pattern: '[0-9]*', inputMode: 'numeric'},
                }}
                value={formState.otp}
                onChange={setters.otp}
                validateChar={validateChar}
                onComplete={handleCompleteOtp}
                my={6}
                sx={{gap: {xs: 1, sm: 2.5}}}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-around"
                color={theme.palette.error.main}
              >
                {hasOtpLoginError && <WarningAmberRounded fontSize="small" />}
                <Typography textAlign="center">
                  {hasOtpLoginError ? (
                    `That code wasn't valid. Please try again or request a new one.`
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </Typography>
              </Stack>

              <Typography variant="body1" textAlign="center">
                Can’t find your code?{' '}
                <Link onClick={resendOtp} sx={{cursor: 'pointer'}}>
                  Click here
                </Link>{' '}
                to resend it.
              </Typography>
            </Stack>
          </Stack>
        </>
      )
    }
    default: {
      return <></>
    }
  }
}
