import * as React from 'react'

import {Global} from '@emotion/react'
import {
  Box,
  SwipeableDrawer,
  SwipeableDrawerProps,
  SxProps,
  Theme,
} from '@mui/material'
import {grey} from '@mui/material/colors'

export const swipeAreaWidth = 75

interface Props
  extends Omit<
    SwipeableDrawerProps,
    'open' | 'onClose' | 'onOpen' | 'PaperProps'
  > {
  children: React.ReactNode
  exposedPuller?: boolean
  contentSx?: SxProps<Theme>
  open: boolean
  onClose: () => void
  onOpen: () => void
  drawerBleeding?: number
}
export const GrabbableMobileSwipeDrawer = ({
  children,
  exposedPuller = true,
  contentSx: sx,
  open: isOpen,
  onClose,
  onOpen,
  drawerBleeding = 40,
  ...drawerProps
}: Props): JSX.Element => {
  const pullerSx: SxProps<Theme> = React.useMemo(() => {
    const baseSx = {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      height: drawerBleeding,
      boxShadow: '0px -9px 8px 0px rgba(0, 0, 0, 0.25)',
      ...sx,
    }

    if (exposedPuller) {
      Object.assign(baseSx, {
        position: 'absolute',
        top: -drawerBleeding,
        visibility: 'visible',
        right: 0,
        left: 0,
      })
    }

    return baseSx
  }, [drawerBleeding, exposedPuller, sx])

  return (
    <>
      {exposedPuller && (
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              maxHeight: `calc(${90}% - ${drawerBleeding}px)`,
              overflow: 'visible',
            },
          }}
        />
      )}
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        swipeAreaWidth={swipeAreaWidth}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: exposedPuller,
        }}
        PaperProps={{
          sx: {
            border: 'none',
            backgroundColor: 'transparent',
          },
        }}
        {...drawerProps}
      >
        <Box sx={pullerSx}>
          <Box
            p={2}
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Puller />
          </Box>
        </Box>
        <Box
          sx={{
            height: '100%',
            overflow: 'auto',
            ...sx,
          }}
        >
          {children}
        </Box>
      </SwipeableDrawer>
    </>
  )
}

const Puller = () => (
  <Box
    sx={{
      width: '32px',
      height: '4px',
      backgroundColor: grey[700],
      borderRadius: '100px',
    }}
  />
)
