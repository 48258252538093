import * as React from 'react'

import {alpha, Box, BoxProps, useTheme} from '@mui/material'
import mapboxgl from 'mapbox-gl'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mapboxgl: any
  }
}

// https://studio.mapbox.com/
const STYLE_URL = 'mapbox://styles/withcompany/cm0e1k15601mh01qq650689jw'
const PARCEL_LAYER_ID = 'parcels'
const PARCEL_POINTS_LAYER_ID = 'parcels-centroids'

export type Parcel = {
  id: string
  geoJson: GeoJSON.GeoJSON
  centerPoint: GeoJSON.Point
}

type CameraPosition = {
  lat?: number
  lng?: number
  zoom: number
}

interface Props extends BoxProps {
  defaultCameraPosition?: CameraPosition
  cameraPosition?: CameraPosition
  highlightedParcelId: string | null | undefined
  highlightedInventoryMarkerId?: string | null
  showParcels?: boolean
  inventoryIds?: string[]
  onParcelsSelected: (
    nonInventoryParcelIds: string[],
    inventoryParcelids: string[],
  ) => void
}

export const MapBoxMap = ({
  defaultCameraPosition = {lat: 35.8992, lng: -78.8636, zoom: 10},
  cameraPosition,
  highlightedParcelId,
  highlightedInventoryMarkerId,
  showParcels = false,
  inventoryIds = [],
  onParcelsSelected,
  ...boxProps
}: Props): JSX.Element | null => {
  const mapboxgl = window.mapboxgl
  const mapContainer = React.useRef(null)
  const mapRef = React.useRef<mapboxgl.Map | null>(null)
  const [mapReady, setMapReady] = React.useState(false)

  const [selectedMapFeatures, setSelectedMapFeatures] = React.useState<
    mapboxgl.MapboxGeoJSONFeature[] | null
  >(null)

  const theme = useTheme()

  const selectFeatures = React.useCallback(
    (features: mapboxgl.MapboxGeoJSONFeature[] | undefined) => {
      if (!features) {
        onParcelsSelected([], [])
        setSelectedMapFeatures(null)
        return
      }

      const inventoryParcelIds = features
        .filter((feature) => feature.layer?.id === PARCEL_POINTS_LAYER_ID)
        .map((feature) => feature.properties?.cherre_parcel_id)

      const nonInventoryParcels = features.filter(
        (feature) => feature.layer?.id === PARCEL_LAYER_ID,
      )
      const nonInventoryParcelIds = nonInventoryParcels.map(
        (feature) => feature.properties?.cherre_parcel_id,
      )

      onParcelsSelected(nonInventoryParcelIds, inventoryParcelIds)
      setSelectedMapFeatures(nonInventoryParcels)
    },
    [onParcelsSelected],
  )

  React.useEffect(() => {
    if (cameraPosition && mapRef.current) {
      if (cameraPosition.lat && cameraPosition.lng) {
        mapRef.current.flyTo({
          center: [cameraPosition.lng, cameraPosition.lat],
          zoom: cameraPosition.zoom,
        })
      } else {
        mapRef.current.flyTo({
          zoom: cameraPosition.zoom,
        })
      }
    }
  }, [cameraPosition])

  const handleMapClick = React.useCallback(
    (e: mapboxgl.MapLayerMouseEvent) => {
      const features = mapRef.current?.queryRenderedFeatures(e.point, {
        layers: [PARCEL_LAYER_ID, PARCEL_POINTS_LAYER_ID],
      })
      selectFeatures(features)
    },
    [selectFeatures],
  )

  React.useEffect(() => {
    if (mapRef.current) {
      return
    }

    const publicToken = document.querySelector<HTMLMetaElement>(
      'meta[name="mapbox"]',
    )?.content

    if (!publicToken) {
      return
    }

    mapboxgl.accessToken = publicToken

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: STYLE_URL,
      center: [defaultCameraPosition.lng, defaultCameraPosition.lat],
      zoom: defaultCameraPosition.zoom,
    })

    map.on('load', () => {
      setMapReady(true)
    })

    mapRef.current = map
    ;[PARCEL_LAYER_ID, PARCEL_POINTS_LAYER_ID].forEach((layerId) => {
      mapRef.current?.on('mouseenter', layerId, () => {
        if (!mapRef.current) {
          return
        }

        mapRef.current.getCanvas().style.cursor = 'pointer'
      })

      mapRef.current?.on('mouseleave', layerId, () => {
        if (!mapRef.current) {
          return
        }

        mapRef.current.getCanvas().style.cursor = ''
      })
    })
  })

  React.useEffect(() => {
    mapRef.current?.on('click', handleMapClick)

    return () => {
      mapRef.current?.off('click', handleMapClick)
    }
  }, [handleMapClick])

  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }

    if (!mapReady) {
      return
    }

    if (!highlightedParcelId) {
      return
    }

    mapRef.current.setPaintProperty('parcels', 'fill-color', [
      'case',
      ['==', ['get', 'cherre_parcel_id'], highlightedParcelId],
      theme.palette.primary.main,
      [
        '==',
        ['id'],
        selectedMapFeatures?.length ? selectedMapFeatures[0].id : null,
      ],
      alpha(theme.palette.primary.main, 0.3),
      'transparent',
    ])
  }, [mapReady, selectedMapFeatures, highlightedParcelId, theme])

  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }

    if (!mapReady) {
      return
    }

    if (!highlightedInventoryMarkerId) {
      mapRef.current.setPaintProperty(
        PARCEL_POINTS_LAYER_ID,
        'circle-color',
        'white',
      )
      return
    }

    mapRef.current.setPaintProperty(PARCEL_POINTS_LAYER_ID, 'circle-color', [
      'case',
      ['==', ['get', 'cherre_parcel_id'], highlightedInventoryMarkerId],
      theme.palette.primary.main,
      'white',
    ])
  }, [mapReady, highlightedInventoryMarkerId, theme])

  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }

    if (!mapReady) {
      return
    }

    mapRef.current.setLayoutProperty(
      PARCEL_LAYER_ID,
      'visibility',
      showParcels ? 'visible' : 'none',
    )

    const showInventory = inventoryIds.length > 0
    mapRef.current.setLayoutProperty(
      PARCEL_POINTS_LAYER_ID,
      'visibility',
      showInventory ? 'visible' : 'none',
    )
  }, [inventoryIds.length, mapReady, showParcels])

  React.useEffect(() => {
    if (!mapRef.current) {
      return
    }

    if (!mapReady) {
      return
    }

    if (inventoryIds.length === 0) {
      mapRef.current.setFilter(PARCEL_POINTS_LAYER_ID, null)

      return
    }

    mapRef.current.setFilter(PARCEL_POINTS_LAYER_ID, [
      'in',
      ['get', 'cherre_parcel_id'],
      ['literal', inventoryIds],
    ])
  }, [inventoryIds, mapReady])

  if (!window.mapboxgl) {
    return null
  }

  return (
    <Box height="100%" width="100%">
      <Box ref={mapContainer} id="map" {...boxProps} />
    </Box>
  )
}
