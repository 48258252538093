import * as React from 'react'
import {FacebookLoginButton as FacebookLoginUIButton} from 'react-social-login-buttons'
import {IResolveParams, LoginSocialFacebook} from 'reactjs-social-login'

import {facebookOauthApiCoPilotAccountPath} from 'src/generated/routes'
import {useRequest} from 'src/hooks/request/useRequest'
import {trackEvent} from 'src/util/analytics'

interface Props {
  onSuccess: () => void
}

export const FacebookLoginButton = ({onSuccess}: Props): JSX.Element => {
  const appId = document.querySelector<HTMLMetaElement>(
    'meta[name="facebookOauthAppId"]',
  )?.content as string

  const {request: postAccessToken} = useRequest<void, {accessToken: string}>(
    'POST',
    facebookOauthApiCoPilotAccountPath(),
    {
      disableDefaultErrorHandling: true,
    },
  )

  const handleResponse = React.useCallback(
    ({data}: IResolveParams) => {
      if (data?.accessToken) {
        trackEvent('Sign in with Facebook Success')
        postAccessToken({
          data: {accessToken: data.accessToken},
        }).then(onSuccess)
      }
    },
    [onSuccess, postAccessToken],
  )

  return (
    <LoginSocialFacebook
      appId={appId}
      onLoginStart={() => trackEvent('Sign in with Facebook')}
      onResolve={handleResponse}
      onReject={(err) => {
        trackEvent('Sign in with Facebook Failure', {error: err})
      }}
    >
      <FacebookLoginUIButton
        align="center"
        iconSize="18px"
        style={{
          boxShadow: 'none',
          fontFamily: 'inherit',
          fontSize: '0.875rem',
          height: '40px',
          margin: 0,
          padding: '0 13px',
          width: '100%',
        }}
      >
        Continue with Facebook
      </FacebookLoginUIButton>
    </LoginSocialFacebook>
  )
}
