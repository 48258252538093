import * as React from 'react'

import {Checkbox, FormControlLabel, FormGroup} from '@mui/material'

interface Props<T extends string> {
  selections: Set<T> | null
  onChange: (value: Set<T> | null) => void
  options: {value: T; label: string}[]
}

export const SelectFilterInput = <T extends string>({
  selections,
  onChange,
  options,
}: Props<T>) => {
  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={`filter-${option.value}`}
          control={
            <Checkbox checked={!!selections && selections.has(option.value)} />
          }
          label={option.label}
          onChange={(e, checked) => {
            const newSelections = new Set(selections)
            if (checked) {
              newSelections.add(option.value)
            } else {
              newSelections.delete(option.value)
            }
            onChange(newSelections)
          }}
        />
      ))}
    </FormGroup>
  )
}
