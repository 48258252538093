type Options = {
  negate?: boolean
  precision?: number
  suffix?: string
}

export const formatDecimal = (
  value: string | number | null | undefined,
  options?: Options,
): string => {
  if (!value) return '-'

  const {negate = false, precision = 2, suffix = null} = options ?? {}

  const floatValue = parseFloat(String(value)) * (negate ? -1 : 1)
  if (floatValue === 0) return '0'
  if (isNaN(floatValue)) return '-'

  const formattedNumber = floatValue.toLocaleString('en-US', {
    maximumFractionDigits: precision,
  })

  return suffix ? `${formattedNumber} ${suffix}` : formattedNumber
}
