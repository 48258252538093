import * as React from 'react'

import {FileInput} from 'src/framework/FileInput'
import {uploadLeaseApiCoPilotAccountPath} from 'src/generated/routes'
import {FileUploadPayload, useFileUpload} from 'src/hooks/request/useFileUpload'
import {Parcel} from 'src/types/copilot'
import {CustomerLeaseStatus} from 'src/types/copilot/customerLeaseStatus'
import {trackEvent} from 'src/util/analytics'
import {ParsedResponse} from 'src/util/request/parseResponse'

interface Props {
  onSubmit: (leaseStatus: CustomerLeaseStatus) => void
  parcel: Parcel
}

export const LeaseUploadForParcelButton = ({
  onSubmit,
  parcel,
}: Props): JSX.Element => {
  const {uploadFile, loading} = useFileUpload<
    FileUploadPayload,
    void,
    CustomerLeaseStatus
  >(uploadLeaseApiCoPilotAccountPath())

  const handleFileSelect: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      async (e) => {
        const fileList: FileList | null = e.target?.files

        if (!fileList) return

        const file = fileList[0]

        trackEvent('Upload Lease for Property', {
          file: file.name,
          propertyId: parcel.taxAssessorId,
          address: parcel.address,
        })
        uploadFile({
          file,
          taxAssessorId: parcel.taxAssessorId,
        }).then((response: ParsedResponse<CustomerLeaseStatus> | void) => {
          if (!response || !response.entity) return

          onSubmit(response.entity)
        })
      },
      [onSubmit, parcel, uploadFile],
    )

  return (
    <FileInput
      id={'lease-upload'}
      buttonText={'Upload lease'}
      fileExtensionRestrictions={['.pdf']}
      buttonProps={{
        variant: 'contained',
        fullWidth: true,
      }}
      onChange={handleFileSelect}
      disabled={loading}
    />
  )
}
