import * as React from 'react'

import {Send} from '@mui/icons-material'
import {Button, TextField, InputAdornment, IconButton} from '@mui/material'
import {grey} from '@mui/material/colors'
import {makeStyles} from '@mui/styles'
import {Stack, Box} from '@mui/system'
import {MuiOtpInput} from 'mui-one-time-password-input'

import {LeaseForm} from 'src/components/copilot/LeaseForm'
import {LeaseUploadButton} from 'src/components/copilot/LeaseUploadButton'
import {GrabbableMobileSwipeDrawer} from 'src/framework/ui/GrabbableMobileSwipeDrawer'
import {apiCoPilotLeaseDetailsPath} from 'src/generated/routes'
import {useShow} from 'src/hooks/request/useShow'
import {ChatSelectOption, CustomerLease, MessageType} from 'src/types/copilot'
import {Confirm} from 'src/types/copilot/message'
import {formatDate} from 'src/util/format/formatDate'

const USER_INPUT_TYPES: MessageType[] = [
  'CONFIRMATION_INPUT',
  'LEASE_FILE_UPLOAD',
  'DATE_INPUT',
  'NUMBER_INPUT',
  'PERCENT_INPUT',
  'TEXT_INPUT',
  'AREA_INPUT',
  'CURRENCY_INPUT',
  'SELECT_INPUT',
  'ADDRESS_INPUT',
  'CODE_INPUT',
  'LEASE_FORM',
  'CONTINUE_TO_MAP',
]

const PLACEHOLDERS: Record<string, string> = {
  PERCENT_INPUT: '%',
  AREA_INPUT: 'SF',
  CURRENCY_INPUT: '$',
}

export type InputData = {
  value: string
  text: string
}

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
})

interface UserInputProps {
  type: MessageType
  options?: ChatSelectOption[]
  refetchLease: () => Promise<unknown>
  onSubmit: (input?: InputData) => void
  onCancel: () => void
  scrollToBottom: () => void
  skippable?: boolean
}

export const UserInput = ({
  type,
  options,
  refetchLease,
  onSubmit,
  onCancel,
  scrollToBottom,
  skippable,
}: UserInputProps): JSX.Element | null => {
  const [input, setInput] = React.useState<string>()

  // TODO: debounce
  const handleInputChange = React.useCallback((e) => {
    const value = e.target.value.trim()

    if (value === '') {
      return
    }

    setInput(value)
  }, [])

  const handleSubmit = React.useCallback(() => {
    if (input) {
      let text = input
      if (type === 'DATE_INPUT') {
        text = formatDate(input)
      }

      onSubmit({value: input, text: text})
    }
  }, [input, onSubmit, type])

  const handleOtpInputChanged = React.useCallback((otp: string) => {
    setInput(otp)
  }, [])

  const handleOtpComplete = React.useCallback(
    (otp: string) => {
      onSubmit({value: otp, text: otp})
    },
    [onSubmit],
  )

  const handleSkip = React.useCallback(() => {
    onSubmit({value: 'SKIPPED', text: 'Skip'})
  }, [onSubmit])

  const classes = useStyles()

  if (USER_INPUT_TYPES.indexOf(type) === -1) {
    return null
  }

  switch (type) {
    case 'CONFIRMATION_INPUT':
      return (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          {options?.map((option, index, array) => (
            <Button
              key={`input-option-${index}`}
              sx={{
                backgroundColor: '#fff',
                flexBasis: array.length == 2 ? '50%' : '100%',
                '&:hover': {
                  backgroundColor: grey[300],
                },
              }}
              variant="outlined"
              onClick={() =>
                onSubmit({value: option.value, text: option.label})
              }
              color={
                option.value === Confirm.YES ||
                option.value === 'CONTINUE_TO_INVENTORY'
                  ? 'primary'
                  : 'secondary'
              }
            >
              {option.label}
            </Button>
          ))}
        </Stack>
      )
    case 'DATE_INPUT':
      return (
        <Box>
          <TextField
            fullWidth
            autoFocus
            variant="outlined"
            type="date"
            sx={{backgroundColor: (theme) => theme.palette.background.paper}}
            inputProps={{
              inputMode: 'numeric',
            }}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSubmit}>
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )
    case 'NUMBER_INPUT':
    case 'PERCENT_INPUT':
    case 'AREA_INPUT':
    case 'CURRENCY_INPUT':
      return (
        <Box>
          <TextField
            fullWidth
            autoFocus
            variant="outlined"
            className={classes.input}
            sx={{backgroundColor: (theme) => theme.palette.background.paper}}
            type="number"
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
            inputProps={{
              inputMode: 'numeric',
            }}
            placeholder={PLACEHOLDERS[type]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSubmit}>
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )
    case 'SELECT_INPUT':
      return (
        <Stack direction="column" spacing={1} alignItems="center">
          {options?.map((option, index) => (
            <Button
              key={`input-option-${index}`}
              sx={{
                backgroundColor: '#fff',
                width: 'fit-content',
                '&:hover': {
                  backgroundColor: grey[300],
                },
              }}
              variant="outlined"
              onClick={() =>
                onSubmit({value: option.value, text: option.label})
              }
              color={option.style === 'PRIMARY' ? 'secondary' : 'info'}
            >
              {option.label}
            </Button>
          ))}
        </Stack>
      )
    case 'ADDRESS_INPUT':
    case 'TEXT_INPUT':
      return (
        <Box>
          <TextField
            fullWidth
            autoFocus
            multiline
            maxRows={5}
            variant="outlined"
            sx={{backgroundColor: (theme) => theme.palette.background.paper}}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              scrollToBottom()
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {skippable && (!input || input.length < 2) ? (
                    <Button
                      onClick={handleSkip}
                      sx={{
                        color: grey[700],
                        padding: 1,
                        fontWeight: 'normal',
                        fontSize: '0.8125rem',
                        minWidth: 'auto',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          fontWeight: 'bold',
                        },
                      }}
                    >
                      Skip
                    </Button>
                  ) : null}
                  <IconButton onClick={handleSubmit}>
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )
    case 'CODE_INPUT':
      return (
        <Box>
          <MuiOtpInput
            length={4}
            TextFieldsProps={{
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                style: {
                  backgroundColor: '#fff',
                  width: '2rem',
                },
              },
            }}
            value={input}
            onChange={handleOtpInputChanged}
            validateChar={(value: string) => {
              return !Number.isNaN(Number(value))
            }}
            onComplete={handleOtpComplete}
            gap={2}
            justifyContent="center"
          />
        </Box>
      )
    case 'LEASE_FILE_UPLOAD':
      return <LeaseUploadButton onSubmit={onSubmit} />
    case 'LEASE_FORM':
      return (
        <LeaseDrawer
          onSubmit={onSubmit}
          onCancel={onCancel}
          refetchLease={refetchLease}
        />
      )
    case 'CONTINUE_TO_MAP':
      return (
        <Button
          onClick={() =>
            onSubmit({
              value: 'CONTINUE_TO_MAP',
              text: 'Continue to Map',
            })
          }
          variant="contained"
        >
          Continue to map
        </Button>
      )
  }

  return null
}

interface LeaseDrawerProps {
  onSubmit: (input?: InputData) => void
  onCancel: () => void
  refetchLease: () => Promise<unknown>
}

const LeaseDrawer = ({
  onSubmit,
  onCancel,
  refetchLease,
}: LeaseDrawerProps): JSX.Element => {
  const [isFormOpen, setIsFormOpen] = React.useState<boolean>(true)

  const {entity: leaseDetails} = useShow<CustomerLease>(
    apiCoPilotLeaseDetailsPath(),
    {
      autoRequest: true,
    },
  )

  return (
    <GrabbableMobileSwipeDrawer
      open={!!(isFormOpen && leaseDetails)}
      onClose={() => {
        setIsFormOpen(false)
        onCancel()
      }}
      onOpen={() => null}
      exposedPuller={false}
      contentSx={{
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      {leaseDetails ? (
        <LeaseForm
          lease={leaseDetails}
          onSave={() => {
            setIsFormOpen(false)
            refetchLease().then(() => onSubmit())
          }}
        />
      ) : null}
    </GrabbableMobileSwipeDrawer>
  )
}
