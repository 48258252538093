import * as React from 'react'

import {useMutex} from 'src/contexts/mutex/useMutex'
import {apiCoPilotAccountPath} from 'src/generated/routes'
import {ShowFetch, useShow} from 'src/hooks/request/useShow'
import {
  setCurrentUser,
  removeCurrentUser,
} from 'src/store/internal/currentUserSlice'
import {useAppDispatch, useAppSelector} from 'src/store/internal/store'
import {User} from 'src/types/user'
import {ParsedResponse} from 'src/util/request/parseResponse'

export type UseCopilotAccountRefetch = ShowFetch<User>

type UseCopilotAccountResult = {
  user: User | null
  hasBeenFetched: boolean
  loading: boolean
  refetch: UseCopilotAccountRefetch
}

export const useCopilotAccount = (): UseCopilotAccountResult => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((state) => state.currentUser)
  const {getLock} = useMutex()

  const requestOptions = React.useMemo(
    () => ({
      suppressedErrorCodes: [401],
      onSuccess: ({entity: currentUser}: ParsedResponse<User>) => {
        dispatch(setCurrentUser(currentUser))
      },
      onError: () => {
        dispatch(removeCurrentUser())
      },
    }),
    [dispatch],
  )

  const {fetch, loading, error} = useShow<User>(
    apiCoPilotAccountPath(),
    requestOptions,
  )

  React.useEffect(() => {
    if (state.item) return
    if (!getLock('copilotAccount')) return

    fetch()
  }, [error, state.item, dispatch, getLock, fetch])

  return {
    user: state.item,
    hasBeenFetched: state.hasBeenFetched,
    loading,
    refetch: fetch,
  }
}
