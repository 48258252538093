import * as React from 'react'

import {Box, Slide, Stack} from '@mui/material'
import {useFlags} from 'launchdarkly-react-client-sdk'

import {AdvancedMapFilters} from 'src/components/copilot/AdvancedMapFilters'
import type {Filter} from 'src/components/copilot/AdvancedMapFilters'
import {LoadingCard, ParcelCard} from 'src/components/copilot/ParcelCard'
import {PropertyPhotoUploadButton} from 'src/components/copilot/PropertyPhotoUploadButton'
import {GeocoderSearch} from 'src/components/copilot/mapbox/GeocoderSearch'
import {
  AllOverlaysSettings,
  OverlayControlsButton,
} from 'src/components/copilot/mapbox/Overlays'
import {
  MapBoxMapV2,
  ClaimedLocationMapParcel,
  MapParcel,
  CameraSetting,
} from 'src/components/copilot/mapbox/SearchMapV2'
import {ParcelDetails} from 'src/components/copilot/parcelDetails/ParcelDetails'
import {
  apiCoPilotParcelsPath,
  searchApiCoPilotParcelsPath,
} from 'src/generated/routes'
import {useClaimedLocations} from 'src/hooks/request/useClaimedLocations'
import {useClaimedParcels} from 'src/hooks/request/useClaimedParcels'
import {useIndex} from 'src/hooks/request/useIndex'
import {useShow} from 'src/hooks/request/useShow'
import {useWatchlistParcels} from 'src/hooks/request/useWatchlistParcels'
import {Parcel} from 'src/types/copilot'
import {trackEvent} from 'src/util/analytics'

type CopilotSearchScreenProps = {
  deepLinkedTaxAssessorId: string | null
  isVisible: boolean
}

export const CopilotSearchScreen = ({
  deepLinkedTaxAssessorId,
  isVisible,
}: CopilotSearchScreenProps): JSX.Element => {
  const {copilotPhotoSearchEnabled} = useFlags()

  const [searchAddressInput, setSearchAddressInput] = React.useState<string>('')
  const [searchResultParcel, setSearchResultParcel] =
    React.useState<MapParcel | null>(null)

  const [parcelCardParcel, setParcelCardParcel] = React.useState<Parcel | null>(
    null,
  )
  const [parcelCardLoading, setParcelCardLoading] =
    React.useState<boolean>(false)
  const [advancedFilter, setAdvancedFilter] = React.useState<Filter>({})
  const [overlays, setOverlays] = React.useState<AllOverlaysSettings>({
    nearbyBusinesses: {active: false, categories: []},
    traffic: {active: false},
    geography: {active: false, overlay: null},
    satellite: {active: false},
  })
  const [cameraSetting, setCameraSetting] = React.useState<
    CameraSetting | undefined
  >(undefined)

  const {claimedLocations} = useClaimedLocations()
  const {claimedParcels} = useClaimedParcels()
  const {watchlistParcels} = useWatchlistParcels()

  const {fetch: fetchParcels} = useIndex<Parcel>(apiCoPilotParcelsPath())
  const {fetch: searchParcel} = useShow<MapParcel>(
    searchApiCoPilotParcelsPath(),
  )

  React.useEffect(() => {
    if (deepLinkedTaxAssessorId) {
      trackEvent('View Shared Property', {
        propertyId: deepLinkedTaxAssessorId,
      })
      searchParcel({params: {tax_assessor_id: deepLinkedTaxAssessorId}})
        .then(({entity: parcel}) => {
          if (parcel) {
            setSearchResultParcel(parcel)
          }
        })
        .then(() =>
          fetchParcels({
            params: {tax_assessor_ids: [deepLinkedTaxAssessorId]},
          }),
        )
        .then(({entities: parcels}) => {
          if (parcels.length) {
            setParcelCardParcel(parcels[0])
          }
        })
    }
  }, [deepLinkedTaxAssessorId, fetchParcels, searchParcel])

  const handleParcelAutocomplete = React.useCallback(
    (parcel: MapParcel) => {
      setSearchAddressInput('')
      // TODO: figure out nicer behavior for clearing filter on manual search
      // outside of existing filter params
      setAdvancedFilter({})
      setSearchResultParcel(parcel)

      setParcelCardLoading(true)
      fetchParcels({
        params: {parcel_ids: [parcel.cherreParcelId]},
      })
        .then(({entities: parcels}) => {
          if (parcels.length > 0) setParcelCardParcel(parcels[0])
        })
        .finally(() => {
          setParcelCardLoading(false)
        })
    },
    [fetchParcels],
  )

  const handleAdvancedFilterChanged = React.useCallback(
    (newFilter: Filter) => {
      setSearchResultParcel(null)
      setParcelCardParcel(null)
      setSearchAddressInput('')
      setAdvancedFilter(newFilter)
    },
    [
      setSearchResultParcel,
      setParcelCardParcel,
      setSearchAddressInput,
      setAdvancedFilter,
    ],
  )

  const claimedLocationMapParcels: ClaimedLocationMapParcel[] =
    React.useMemo(() => {
      if (!claimedParcels) {
        return []
      }

      if (!claimedLocations) {
        return []
      }

      return claimedLocations
        .map((cl) => {
          const parcel = claimedParcels.find(
            (p) => p.taxAssessorId === cl.taxAssessorId,
          )

          if (!parcel) {
            return null
          }

          return {
            claimedLocation: cl,
            parcel: parcel,
          }
        })
        .filter((p) => p !== null) as ClaimedLocationMapParcel[]
    }, [claimedLocations, claimedParcels])

  const handleParcelSelected = React.useCallback(
    (parcelId: string | null) => {
      setSearchAddressInput('')

      if (!parcelId) {
        setSearchResultParcel(null)
        setParcelCardParcel(null)
        return
      }
      setSearchResultParcel({cherreParcelId: parcelId})

      if (parcelCardParcel) setParcelCardLoading(true)

      fetchParcels({
        params: {parcel_ids: [parcelId]},
      })
        .then(({entities: parcels}) => {
          if (parcels.length > 0) {
            trackEvent('Click Property on Map', {
              propertyId: parcels[0].taxAssessorId,
              address: parcels[0].address,
            })
            setParcelCardParcel(parcels[0])
          } else {
            trackEvent('Click non-withco Property on Map', {
              propertyId: parcelId,
            })
            setParcelCardParcel(null)
          }
        })
        .finally(() => {
          setParcelCardLoading(false)
        })
    },
    [fetchParcels, parcelCardParcel],
  )

  React.useEffect(() => {
    if (!searchResultParcel || !searchResultParcel.parcelCentroid) {
      setCameraSetting(undefined)
      return
    }

    setCameraSetting({
      type: 'position' as const,
      position: {
        latitude: searchResultParcel.parcelCentroid.coordinates[1] - 0.0005,
        longitude: searchResultParcel.parcelCentroid.coordinates[0],
        zoom: 17,
      },
    })
  }, [searchResultParcel])

  return (
    <Box sx={{height: '100%', width: '100%'}}>
      <Box position="absolute" zIndex={50} width="100%" p={1.25}>
        <Stack direction="row" width="100%" spacing={1}>
          <GeocoderSearch
            onParcelFound={handleParcelAutocomplete}
            onNonParcelSelected={(bbox) => {
              if (bbox) setCameraSetting({type: 'bbox', bbox})
            }}
            input={searchAddressInput}
            setInput={setSearchAddressInput}
          />
          {copilotPhotoSearchEnabled ? (
            <PropertyPhotoUploadButton
              onParcelFound={handleParcelAutocomplete}
            />
          ) : null}
          <AdvancedMapFilters
            initialFilter={advancedFilter}
            onFilterChange={handleAdvancedFilterChanged}
          />
          <OverlayControlsButton
            initialOverlays={overlays}
            onChange={setOverlays}
          />
        </Stack>
      </Box>
      <MapBoxMapV2
        sx={{height: '100%', width: '100%'}}
        cameraPosition={cameraSetting}
        selectedParcelId={searchResultParcel?.cherreParcelId}
        filter={advancedFilter}
        onParcelSelected={handleParcelSelected}
        claimedParcels={claimedLocationMapParcels}
        isVisible={isVisible}
        overlays={overlays}
      />
      <Slide
        in={!!parcelCardParcel || parcelCardLoading}
        direction="up"
        mountOnEnter
        unmountOnExit
      >
        <Box
          position="absolute"
          bottom="10%"
          zIndex={50}
          left={{xs: 0, md: 3}}
          maxWidth={{xs: '100%', md: '375px'}}
          width="100%"
          sx={{
            pb: 1,
            px: 1,
            '& .MuiBox-root': {
              maxHeight: '100%',
              overflow: 'hidden',
            },
          }}
        >
          {parcelCardLoading ? (
            <LoadingCard />
          ) : (
            parcelCardParcel && (
              <ParcelCard
                key={`parcel-card-${parcelCardParcel.taxAssessorId}`}
                parcel={parcelCardParcel}
                isOnWatchlist={
                  watchlistParcels?.some(
                    (p) =>
                      p.taxAssessorId.toString() ===
                      parcelCardParcel.taxAssessorId.toString(),
                  ) ?? false
                }
                DrawerContent={ParcelDetails}
              />
            )
          )}
        </Box>
      </Slide>
    </Box>
  )
}
