export type QualityLevel = 'None' | 'Basic' | 'Mid-grade' | 'High-end'
export type BuildingType =
  | 'Office'
  | 'Commercial'
  | 'Industrial'
  | 'Restaurant'
  | 'Medical'
export type ImprovementCategory =
  | 'Demolition'
  | 'Framing and Drywall'
  | 'Flooring'
  | 'Ceiling'
  | 'Painting'
  | 'Electrical'
  | 'Plumbing'
  | 'HVAC'
  | 'Lighting'
  | 'Doors and Windows'
  | 'Millwork/Cabinetry'
  | 'Restrooms'
  | 'Specialty Items'

type CostEntry = {
  [key in BuildingType]: number
}

type QualityLevelCosts = {
  [key in Exclude<QualityLevel, 'None'>]: CostEntry
}

type ImprovementCosts = {
  [key in ImprovementCategory]: QualityLevelCosts
}

// Based on: https://docs.google.com/spreadsheets/d/1k6reuKnH5lH31A3udxBh3P2eUDz24FODA4eiMilDXiQ/edit?gid=897220588#gid=897220588
export const BUILDOUT_COST_TABLE: ImprovementCosts = {
  Demolition: {
    Basic: {Office: 3, Commercial: 3, Industrial: 2, Restaurant: 4, Medical: 4},
    'Mid-grade': {
      Office: 4,
      Commercial: 4,
      Industrial: 3,
      Restaurant: 5,
      Medical: 5,
    },
    'High-end': {
      Office: 5,
      Commercial: 5,
      Industrial: 4,
      Restaurant: 6,
      Medical: 6,
    },
  },
  'Framing and Drywall': {
    Basic: {
      Office: 10,
      Commercial: 8,
      Industrial: 6,
      Restaurant: 12,
      Medical: 12,
    },
    'Mid-grade': {
      Office: 15,
      Commercial: 12,
      Industrial: 8,
      Restaurant: 18,
      Medical: 18,
    },
    'High-end': {
      Office: 20,
      Commercial: 16,
      Industrial: 10,
      Restaurant: 24,
      Medical: 24,
    },
  },
  Flooring: {
    Basic: {Office: 5, Commercial: 6, Industrial: 3, Restaurant: 8, Medical: 7},
    'Mid-grade': {
      Office: 8,
      Commercial: 10,
      Industrial: 5,
      Restaurant: 12,
      Medical: 10,
    },
    'High-end': {
      Office: 12,
      Commercial: 15,
      Industrial: 7,
      Restaurant: 18,
      Medical: 15,
    },
  },
  Ceiling: {
    Basic: {Office: 4, Commercial: 3, Industrial: 2, Restaurant: 5, Medical: 5},
    'Mid-grade': {
      Office: 6,
      Commercial: 5,
      Industrial: 3,
      Restaurant: 7,
      Medical: 7,
    },
    'High-end': {
      Office: 9,
      Commercial: 7,
      Industrial: 4,
      Restaurant: 10,
      Medical: 10,
    },
  },
  Painting: {
    Basic: {Office: 2, Commercial: 2, Industrial: 1, Restaurant: 3, Medical: 3},
    'Mid-grade': {
      Office: 3,
      Commercial: 3,
      Industrial: 2,
      Restaurant: 4,
      Medical: 4,
    },
    'High-end': {
      Office: 4,
      Commercial: 4,
      Industrial: 3,
      Restaurant: 5,
      Medical: 5,
    },
  },
  Electrical: {
    Basic: {
      Office: 8,
      Commercial: 7,
      Industrial: 5,
      Restaurant: 10,
      Medical: 12,
    },
    'Mid-grade': {
      Office: 12,
      Commercial: 10,
      Industrial: 7,
      Restaurant: 15,
      Medical: 18,
    },
    'High-end': {
      Office: 18,
      Commercial: 15,
      Industrial: 10,
      Restaurant: 22,
      Medical: 25,
    },
  },
  Plumbing: {
    Basic: {
      Office: 6,
      Commercial: 5,
      Industrial: 3,
      Restaurant: 15,
      Medical: 10,
    },
    'Mid-grade': {
      Office: 9,
      Commercial: 8,
      Industrial: 5,
      Restaurant: 22,
      Medical: 15,
    },
    'High-end': {
      Office: 14,
      Commercial: 12,
      Industrial: 7,
      Restaurant: 30,
      Medical: 22,
    },
  },
  HVAC: {
    Basic: {
      Office: 10,
      Commercial: 8,
      Industrial: 6,
      Restaurant: 12,
      Medical: 12,
    },
    'Mid-grade': {
      Office: 15,
      Commercial: 12,
      Industrial: 8,
      Restaurant: 18,
      Medical: 18,
    },
    'High-end': {
      Office: 22,
      Commercial: 18,
      Industrial: 12,
      Restaurant: 25,
      Medical: 25,
    },
  },
  Lighting: {
    Basic: {Office: 5, Commercial: 6, Industrial: 4, Restaurant: 7, Medical: 7},
    'Mid-grade': {
      Office: 8,
      Commercial: 10,
      Industrial: 6,
      Restaurant: 12,
      Medical: 12,
    },
    'High-end': {
      Office: 12,
      Commercial: 15,
      Industrial: 9,
      Restaurant: 18,
      Medical: 18,
    },
  },
  'Doors and Windows': {
    Basic: {Office: 6, Commercial: 8, Industrial: 4, Restaurant: 7, Medical: 7},
    'Mid-grade': {
      Office: 9,
      Commercial: 12,
      Industrial: 6,
      Restaurant: 10,
      Medical: 10,
    },
    'High-end': {
      Office: 14,
      Commercial: 18,
      Industrial: 9,
      Restaurant: 15,
      Medical: 15,
    },
  },
  'Millwork/Cabinetry': {
    Basic: {
      Office: 5,
      Commercial: 6,
      Industrial: 2,
      Restaurant: 10,
      Medical: 8,
    },
    'Mid-grade': {
      Office: 8,
      Commercial: 10,
      Industrial: 4,
      Restaurant: 15,
      Medical: 12,
    },
    'High-end': {
      Office: 12,
      Commercial: 15,
      Industrial: 6,
      Restaurant: 22,
      Medical: 18,
    },
  },
  Restrooms: {
    Basic: {Office: 6, Commercial: 5, Industrial: 4, Restaurant: 8, Medical: 8},
    'Mid-grade': {
      Office: 9,
      Commercial: 8,
      Industrial: 6,
      Restaurant: 12,
      Medical: 12,
    },
    'High-end': {
      Office: 14,
      Commercial: 12,
      Industrial: 9,
      Restaurant: 18,
      Medical: 18,
    },
  },
  'Specialty Items': {
    Basic: {
      Office: 5,
      Commercial: 10,
      Industrial: 5,
      Restaurant: 30,
      Medical: 20,
    },
    'Mid-grade': {
      Office: 10,
      Commercial: 20,
      Industrial: 10,
      Restaurant: 50,
      Medical: 35,
    },
    'High-end': {
      Office: 20,
      Commercial: 35,
      Industrial: 20,
      Restaurant: 80,
      Medical: 60,
    },
  },
}
