import * as React from 'react'

import {setNavPage} from 'src/store/partners/navPageSlice'
import {useAppDispatch, useAppSelector} from 'src/store/partners/store'

export type NavPage = 'Search' | 'Targets' | 'Profile' | 'Feed'

type UseNavPageResults = {
  navPage: NavPage
  setNavPage: (page: NavPage) => void
  isActivePage: (page: NavPage) => boolean
}

export const useNavPage = (): UseNavPageResults => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((state) => state.navPage)

  const isActivePage = React.useCallback(
    (page: NavPage) => {
      return state.page === page
    },
    [state],
  )

  const updateNavPage = React.useCallback(
    (page: NavPage) => {
      dispatch(setNavPage(page))
    },
    [dispatch],
  )

  return {navPage: state.page, setNavPage: updateNavPage, isActivePage}
}
